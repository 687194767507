<template>
  <a-modal v-model:visible="_visible" :mask-closable="false" :width="width" :popup-container='popupContainer' class="public-modal direction">
    <template #title> 
       <span>{{title}}</span>
       <slot name="hearder"></slot>
    </template>
    <div>
      <slot></slot>
    </div>
    <template #footer>
      <a-button
        type="outline"
        class="handleClose"
        @click="handleClose"
        >取消</a-button
      >
      <a-button type="primary" :disabled="vMdisabled" class="handleOk" @click="handleOk">{{btnName}}</a-button>
    </template>
  </a-modal>
</template>

<script setup>
import { useVModel } from '@vueuse/core'
const props = defineProps({
  visible: Boolean,
  title:{ type: String, default: ()=> '标题' },
  width:{ type: String, default: ()=> '468px' },
  popupContainer:{type: String, default: ()=> 'body'},
  btnName:{type: String, default: ()=> '确定'},
  vMdisabled:{type: Boolean, default: ()=> false},
})
const emit = defineEmits(['update:visible','handleOk'])
const _visible = useVModel(props, 'visible', emit)
const _name = useVModel(props, 'name', emit)

const filedsOk = ()=>{
    // _visible.value = false
}
const handleClose = ()=>{
    _visible.value = false
}
const handleOk = ()=>{
    emit('handleOk')
}
// const handleCancel = ()=>{
//     _visible.value = false
// }
</script>

<style lang="scss" scoped>
.handleClose {
  width: 70px;
  height: 26px;
  line-height: 24px;
  text-align: center;
  background: #ffffff;
  border-radius: 3px;
  color: #333b44;
  border: 1px solid #e4e5eb;
}
.deleteOk {
  width: 72px;
  height: 28px;
  background: #f34b45 !important;
  border-radius: 4px;
  color: #ffffff !important;
  text-align: center;
  line-height: 26px;
}
.handleOk {
  width: 72px;
  height: 28px;
  background: #3272FE ;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  line-height: 26px;
  // &:hover{
  //   background: #3272FE ;
  //   color: #ffffff;
  // }
}
</style>
