<template>
  <div class="contact-cont">
    <div class="contact-cont-div">
      <p class="cont-title">{{$t('plat_c.app_c.dialog_staff.zuzhijiegou')}}</p>
      <div class="cont-search-cont">
        <a-input v-model.trim="keyword" class="search-cont-input" :placeholder="from ? $t('plat_c.app_c.dialog_staff.sousuorenyuan') : $t('plat_c.app_c.dialog_staff.sousuorenyuanbumen')" @input="searchHandle">
          <template #prefix>
            <icon-search />
          </template>
        </a-input>
        <a-breadcrumb class="cont-search-breadcrumb">
          <template v-for="(item, index) in breadcrumbList" :key="item">
            <a-breadcrumb-item :class="{'breadcrumb-item': true, 'active': index !== breadcrumbList.length - 1}" @click="back(index)">{{ item }}</a-breadcrumb-item>
          </template>
          <template #separator>
            <icon-right />
          </template>
        </a-breadcrumb>
        <ul class="cont-search-ul">
          <template v-for="item in organizationList" :key="item.id">
            <template v-if="item.child?.length">
              <li v-for="child in item.child" :key="child.id" class="search-ul-li" :class="{ disable: child.disabledSelect }">
                <div class="left">
                  <a-checkbox v-if="from !== 'approval'" class="show-checkbox" v-model="child.checked" :disabled="child.disabledSelect" @change="(e) => selectHandle(e, 2, child, child.id, item.parentId)"/>
                  <div class="img-cont">
                    <img v-if="child.avatar" :src="child.avatar" alt="">
                    <span v-else>{{ child.deptName?.length ? child.deptName[0] : '' }}</span>
                  </div>
                  <div class="name" :class="{ disable: child.disabledSelect }">
                    {{ child.deptName }}
                    <span v-if="child.childUser || child.child">({{ (child.childUser?.length || 0) + (child.totalMumbers || 0) }})</span>
                  </div>
                </div>
                <a-button
                  v-if="child.child?.length || child.childUser?.length"
                  :loading="nextLoading"
                  :disabled="child.disabled || child.disabledSelect"
                  size="mini"
                  type="text"
                  class="next-btn"
                  @click="nextChild(child, item.deptName, child.deptName, child.parentId, child.id)"
                >
                  {{$t('plat_c.app_c.dialog_staff.xiaji')}}
                  <icon-right />
                </a-button>
                <!-- <div v-if="child.child?.length || child.childUser?.length" class="child">
                  <a-spin :loading="nextLoading">
                    下级
                    <icon-right />
                  </a-spin>
                </div> -->
              </li>
            </template>
            <template v-if="item.childUser?.length">
              <template v-for="user in item.childUser" :key="user.id">
                <li v-if="user.type < 30" class="search-ul-li" :class="{ disable: user.disabledSelect }">
                  <div class="left">
                    <a-checkbox class="show-checkbox" v-model="user.checked" :disabled="user.disabledSelect" @change="(e) => selectHandle(e, 1, user, user.id, user.parentId)" />
                    <div class="img-cont">
                      <img v-if="user.avatar" :src="user.avatar">
                      <span v-else>{{ user.name?.length ? user.name[0] : ''}}</span>
                    </div>
                    <div class="name" :class="{ disable: user.disabledSelect }">
                      {{ user?.name || user.deptName }}
                    </div>
                  </div>
                </li>
              </template>
            </template>
          </template>
        </ul>
      </div>
    </div>
    <div class="contact-cont-div">
      <p class="cont-title">
        <span>{{$t('plat_c.app_c.dialog_staff.yixuangeshu', {length: selectContactList.length})}}</span>
        <a-button type="text" size="small" @click="emptyHandle">
          <template #icon>
            <icon-delete />
          </template>
          <template #default>{{$t('plat_c.app_c.general_btn.qingkong')}}</template>
        </a-button>
      </p>
      <ul class="cont-search-cont ul">
        <li v-for="item in selectContactList" :key="item.id" class="search-ul-li">
          <div class="left">
            <div class="img-cont">
              <img v-if="item.avatar" :src="item.avatar" alt="">
              <span v-else>
                <span v-if="item.name">{{ item.name?.length ? item.name[0] : ''}}</span>
                <span v-else>{{ item.deptName?.length ? item.deptName[0] : ''}}</span>
              </span>
            </div>
            <div class="name">
              {{ item.name || item.deptName }}
              <span v-if="item.childUser">({{ item.totalMumbers + item.childUser.length }})</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  findOrganization,
  findOrganizationByDeptId
} from '@/services/use.js'
import {
  findOrganizationByPinyin,
} from '@/services/investment/index.js'
import _ from 'lodash'
export default {
  name: "TeamGrounp",
  props: {
    from: {
      type: String,
      default: ''
    },
    teamPersons: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      keyword: '',
      selectContactList: [],
      organizationListDeep: [],
      organizationList: [],  // 组织
      breadcrumbList: [],
      breadcrumbIds: [],
      nextLoading: false,
      currOrgIds: [],
    }
  },
  watch: {
    selectContactList: {
      deep: true,
      handler(val) {
        if(val.length) {
          this.$emit('disableSubmit', false)
        } else {
          this.$emit('disableSubmit', true)
        }
      }
    },
    organizationList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length) {
          val.map(item => {
            if (item.child) {
              item.child.map(child => {
                const num = this.getMaxFloor(child.child)
                child.totalMumbers = num
              })
            }
          })
        }
      }
    }
  },
  computed: {
    currOrgId() {
      return this.currOrgIds.length ? this.currOrgIds.pop() : 0
    }
  },
  // created() {
  //   this.getOrg()
  // },
  methods: {
    getMaxFloor(treeData) {
      let max = 0
      function each (data) {
        if (data) {
          for(let i = 0; i<data.length; i++){
            max+= data[i].childUser.length
            if(data[i].child){
              each(data[i].child);
            }
          }
        }
      }
      each(treeData);
      return max
    },
    reset() {
      this.keyword = ''
      this.selectContactList = []
      this.organizationList = []
      this.breadcrumbList = []
      this.breadcrumbIds = []
      this.getOrg()
    },
    emptyHandle() {
      this.selectContactList = []
      this.recursionUnchecked(this.organizationList)
    },
    recursionUnchecked(arr) {
      arr.map(item => {
        item.checked = false
        item.disabled = false
        if (item.child) {
          return this.recursionUnchecked(item.child)
        }
      })
      arr.map(item => {
        item.checked = false
        item.disabled = false
        if (item.childUser) {
          return this.recursionUnchecked(item.childUser)
        }
      })
    },
    checkedSelected(sourceArr, originArr) {
      sourceArr.map(item => {
        originArr.map(child => {
          if (item.id === child.id) {
            child.checked = true
            child.disabled = true
          }
          if (child.childUser && child.childUser.length) {
            return this.checkedSelected(sourceArr, child.childUser)
          }
        })
      })
      sourceArr.map(item => {
        originArr.map(child => {
          if (item.id === child.id) {
            child.checked = true
            child.disabled = true
          }
          if (child.child && child.child.length) {
            return this.checkedSelected(sourceArr, child.child)
          }
        })
      })
    },
    selectHandle(e, type, val, id) {
      val.disabled = e
      val.type = type
      if (e) {
        this.selectContactList.push(val)
      } else {
        this.selectContactList = this.selectContactList.filter(item => item.id !== id)
      }
    },
    async back(index) {
      if (index === 0) {
        this.getOrg()
        this.breadcrumbList = []
        this.breadcrumbIds = []
        this.currOrgIds = []
      } else if (index === this.breadcrumbList.length -1) {
        return false
      } else {
        this.breadcrumbList.splice(index + 1, 1)
        this.breadcrumbIds.splice(index + 1, 1)
        this.currOrgIds.splice(index, 1)

        const group = await findOrganization(this.breadcrumbIds[index], 2)
        this.organizationList = group
        
      }
      this.checkedSelected(this.selectContactList, this.organizationList)
      this.disabledHandle(this.teamPersons, this.organizationList)
    },
    searchHandle(val) {
      val = val.replace(/ /g,'');
      if (val !== '') {
        findOrganizationByPinyin(this.keyword, this.currOrgId).then(res => {
          this.organizationList = [res]
          this.checkedSelected(this.selectContactList, this.organizationList)

          this.disabledHandle(this.teamPersons, this.organizationList)
        })
      } else {
        this.getOrg()
        this.currOrgIds = []
      }
      this.breadcrumbList = []
      this.breadcrumbIds = []
    },
    async getOrg() {
      const group = await findOrganization()
      this.organizationList = group
      this.organizationListDeep = _.cloneDeep(group)
      Object.freeze(this.organizationListDeep)
      this.checkedSelected(this.selectContactList, this.organizationList)

      this.disabledHandle(this.teamPersons, this.organizationList)
    },
    // 已选取后不可再次选取
    disabledHandle(selectedArr, sourceArr) {
      selectedArr.filter(item => {
        sourceArr && sourceArr.filter(child => {
          if (item.memberId === child.id || item.id === child.id) {
            child['disabledSelect'] = true
            child['checked'] = true
          }
          if (child.child && child.child.length) {
            return this.disabledHandle(selectedArr, child.child)
          }
        })
        sourceArr && sourceArr.filter(child => {
          if (item.memberId === child.id || item.id === child.id) {
            child['disabledSelect'] = true
            child['checked'] = true
          }
          if (child.childUser && child.childUser.length) {
            return this.disabledHandle(selectedArr, child.childUser)
          }
        })
      })
    },
    nextChild(child, Pname, Cname, Pid, Cid) {
      this.organizationList = [child]
      this.breadcrumbList.push(Pname || this.$t('plat_c.app_c.dialog_staff.quanbu'))
      this.breadcrumbList.push(Cname)
      this.breadcrumbList = [...new Set(this.breadcrumbList)]
      this.breadcrumbIds.push(Pid)
      this.breadcrumbIds = [...new Set(this.breadcrumbIds)]

      this.checkedSelected(this.selectContactList, this.organizationList)
      // this.nextLoading = true
      // const group = findOrganizationByDeptId(id)
      // this.organizationList = group
      // this.nextLoading = false

      this.currOrgIds.push(Cid)
    }
  },
}
</script>

<style lang="scss">
.show-checkbox {
  .arco-icon-hover {
    display: inline-block;
  }
}
</style>
<style lang="scss" scoped>
.contact-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .contact-cont-div {
    width: 278px;
    height: 336px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 4px;
    border: 1px solid #E4E5EB;
    overflow: hidden;
    .cont-title {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      font-size: 14px;
      color: #333B44;
      font-weight: bold;
      background: #F9FAFC;
    }
    .cont-search-cont {
      width: 100%;
      height: 300px;
      padding: 10px;
      :deep(.arco-input-prefix){
        // padding: 0 8px;
        color: #959aa2;
      }
      .search-ul-li {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.disable {
          cursor: not-allowed;
        }
        .name {
          color: #333B44;
          font-size: 14px;
          &.disable {
            color: #c9cdd4 !important;
            span {
              color: #c9cdd4 !important;
            }
          }
          span {
            color: #959AA2;
          }
        }
        .next-btn {
          font-size: 12px;
          display: flex;
          align-items: center;
        }
        .child {
          width: 52px;
          line-height: 24px;
          border-radius: 4px;
          transition: all .2s;
          cursor: pointer;
          font-size: 12px;
          color: #3272FE;
          text-align: center;
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
        }
        .left {
          display: flex;
          align-items: center;
        }
        .img-cont {
          width: 24px;
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 8px;
          background: linear-gradient(180deg, #4178FD 0%, #2B61ED 100%);
          &.group {
            background: linear-gradient(180deg, #9CA6D4 0%, #818BB8 100%);
          }
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
            display: block;
            object-fit: contain;
          }
        }
      }
      .cont-search-ul {
        width: 100%;
        height: 208px;
        overflow-y: auto;
      }
      .cont-search-breadcrumb {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        :deep(.breadcrumb-item) {
          font-weight: normal;
          &.active {
            cursor: pointer;
            color: #3272FE;
            &:hover {
              color: #3272FE;
            }
          }
        }
      }
      .search-cont-input {
        border: 1px solid #E4E5EB;
        background: none;
        .arco-input-prefix {
          padding: 0 6px;
        }
      }
      &.ul {
        overflow-y: auto;
      }
    }
  }
}
</style>