import { singleton } from "./singleton";
import {
    findAreaTree,
} from '@/services/investment/createProject.js'
// import { getAllUsers } from '@/services/investment/index.js'
import { fetchFindAllUser } from '@/services/use.js'

class Fileds {
    constructor() {
        console.log('单例模式created');
        this.getAllUsers = this.getAllUsersFunc()
        this.findCategoryList = this.findCategoryListFunc()
        this.findCountryList = this.findCountryListFunc()
    }
    getAllUsersFunc() {
        return new Promise(async (resolve, reject) => {
            let opt = await fetchFindAllUser({ data: { name: '' } })
            resolve(opt || [])
        })
        
    }
    async findCategoryListFunc() {
        return new Promise(async (resolve, reject) => {
            let result = await findAreaTree({ data: { type: 'category' } })
            resolve(result || [])
        })

    }
    async findCountryListFunc() {
        return new Promise(async (resolve, reject) => {
            let result = await findAreaTree({ data: { type: 'country' } })
            result.forEach(ele => {
                if (ele.value !== '150') {
                    ele.children && ele.children.length > 0 && ele.children.forEach(child => {
                        child.children = []
                    })
                }
            })
            resolve(result || [])
        })

    }
}
const NewFileds = singleton(Fileds)

// NewFileds.prototype.getAllUsers = getAllUsersFunc
// NewFileds.prototype.findCategoryList = findCategoryListFunc
// NewFileds.prototype.findCountryList = findCountryListFunc

export { NewFileds } 