<template>
  <Card class="corporate-image" :title="i18n_t('plat_c.allTabs.publicInfo.gongsihuaxiang')" :is-loading="isLoading">
    <template v-if="companyTags?.length || tags?.length">
      <a-space wrap :size="[6, 6]" v-if="companyTags?.length">
        <span class="companyTags" v-for="(tag, index) in companyTags" :key="index">
          <autoTooltip :content="tag" />
        </span>
      </a-space>

      <a-space wrap :size="[6, 6]" v-if="tags?.length">
        <span class="tag" v-for="(tag, index) in tags" :key="index">
          <autoTooltip :content="tag" />
        </span>
      </a-space>
    </template>

    <EmptyContent :message="i18n_t('plat_c.allTabs.com.zanwushuju')" v-else />
  </Card>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import EmptyContent from '@/components/emptyContent.vue'
import Card from './card.vue'
import autoTooltip from '@/components/AutoTooltip.vue'
import { useI18n } from 'vue-i18n'
const { t: i18n_t } = useI18n()

const props = defineProps({
  tags: Array as PropType<{ name: string; id: string }[]>,
  companyTags: Array as PropType<{ name: string; id: string }[]>,
  isLoading: Boolean as PropType<boolean>,
})
</script>

<style lang="scss" scoped>
.corporate-image {
  padding-bottom: 6px;
  :deep() {
    .arco-space-wrap {
      width: 100%;
    }
    .arco-space-item{
      max-width: 100%;
    }
  }
  .tag {
    display: inline-block;
    max-width: 100%;
    background: #f2f4f6;
    border-radius: 4px;
    height: 26px;
    padding: 0 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333b44;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .companyTags{
    display: inline-block;
    max-width: 100%;
    background: #E6EEFE;
    border-radius: 4px;
    height: 26px;
    padding: 0 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3272FE;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .empty-desc {
    color: #E4E5EC;
  }
}
</style>
