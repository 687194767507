<template>
  <Card class="company-news" :title="i18n_t('plat_c.allTabs.investmentPlan.gongsixinwen')" :is-loading="isLoading">
    <div class="wrap-container">
      <div
        class="news-item"
        v-if="newsList?.length"
        v-for="news in newsList.filter((n, idx) => idx < 5)"
        :key="news.id"
      >
        <div class="title">
          <a :href="news.url" target="_blank">{{ news.title }}</a>
        </div>
        <div class="date">
          <Iconfont name="icon_hour">{{ news.date }}</Iconfont>
        </div>
      </div>
      <EmptyContent :message="i18n_t('plat_c.allTabs.com.zanwushuju')" v-else />
    </div>
  </Card>
</template>

<script lang="ts" setup>

import { PropType } from 'vue'
import Iconfont from '@/components/Iconfont.vue'
import EmptyContent from '@/components/emptyContent.vue'
import Card from './card.vue'
import { useI18n } from 'vue-i18n'
const { t: i18n_t } = useI18n()

const props = defineProps({
  isLoading: Boolean as PropType<boolean>,
  newsList: Array as PropType<any[]>,
})
</script>

<style lang="scss" scoped>
.company-news {
  padding-bottom: 6px;
  .empty-desc {
    color: #e4e5ec;
    margin-bottom: 6px;
    display: block;
  }
  .wrap-container {
    .news-item {
      padding: 8px 0;
      border-bottom: 1px solid #edeff5;
      &:last-child {
        border: none;
      }
      .title {
        line-height: 20px;
        a {
          word-break: break-all;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3272FE;
        }
      }
      .date {
        &:deep(.iconfont) {
          font-size: 16px;
          color: #959aa2;
        }
        .text {
          margin-top: 6px;
          line-height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
