<template>
  <a-modal
    v-model:visible="currentVisible"
    class="change-project-main-body public-modal"
    unmount-on-close
    width="640px"
    top="100px"
    :align-center="false"
    :mask-closable="false"
  >
    <template #title>
      <div>
        {{
          bCode === 'lpInvestor'
            ? $t('plat_c.allTabs.publicInfo.xiugaitouzizhequan')
            : $t('plat_c.allTabs.publicInfo.xiugaizhuti')
        }}
      </div>
    </template>
    <div class="content">
      <div class="top">
        <a-input
          :style="{
            background: '#fff',
            border: '1px solid #E4E5EB',
            padding: '0 7px',
            borderRadius: '4px',
            flex: '1',
          }"
          :placeholder="
            bCode === 'lpInvestor'
              ? $t('plat_c.allTabs.publicInfo.qingshuru_T_Q_C')
              : $t('plat_c.allTabs.publicInfo.qing_T_Z_Q')
          "
          size="small"
          v-model.trim="searchValue"
          allow-clear
          @keyup.delete="keyDelete"
          @clear="clearInput"
          @input="seachChange"
        />
        <span v-if="clickshow" style="margin-left: 9px; color: #3272FE; font-size: 12px"
          >公开信息</span
        >
        <a-tooltip
          :content="
            bCode === 'lpInvestor'
              ? $t('plat_c.allTabs.publicInfo.tou_G_C')
              : $t('plat_c.allTabs.publicInfo.xiangmu_C_K')
          "
          position="bottom"
        >
          <i v-if="clickshow" class="iconfont icon-icon_warn icbc" />
        </a-tooltip>
      </div>
      <a-list
        v-if="listcontent.length > 0"
        hoverable="true"
        class="list"
        :loading="listLoading"
        :max-height="300"
        @reach-bottom="reachBottom"
      >
        <a-list-item
          v-for="(item, index) in listcontent"
          @click="listItemClick(item, index)"
          :class="['list-item', clickFlag === index ? 'actived' : '']"
          :key="index"
        >
          <!-- <div class="list-item-left">
            <img :src="item.logo ? item.logo : logo" alt="" />
          </div> -->
          <div class="list-item-right">
            <!-- <p>{{ item.name }}</p>
            <p>{{ item.full_name }}</p>
            <p>
              <span v-if="item.address1_cz2 !== item.address1_cz3"
                >{{ item.address1_cz2 }}-{{ item.address1_cz3 }}</span
              >
              <span v-else>{{ item.address1_cz2 }}</span>
            </p>
            <p>{{ item.short_description }}</p> -->
            <p style="margin-top: 0">{{ item.full_name }}</p>
            <p>法定代表人：{{ item.legal_represent_name }}</p>
            <p>注册资本：{{ item.tycRegCapital }}</p>
            <p>成立日期：{{ item.establish_date }}</p>
          </div>
          <div v-if="clickFlag === index" id="triangle-topright"></div>
          <i v-if="clickFlag === index" class="iconfont icon-close-med choos" />
        </a-list-item>
      </a-list>
      <template v-else>
        <template v-if="bCode === 'lpInvestor'">
          <div v-if="showDefaultText" class="creatnomore">
            <img src="@/assets/img/createNomore.png" alt="" />
            <p style="margin-top: 8px" :innerHTML="$t('plat_c.allTabs.publicInfo.qing_G_Q')">
              <!-- 请输入您想查找的投资者主体 <br />若投资者为个人，请直接点击确定 -->
            </p>
          </div>
          <div v-else class="creatnomore">
            <img src="@/assets/img/createNomore.png" alt="" />
            <p style="margin-top: 8px" :innerHTML="$t('plat_c.allTabs.publicInfo.meiyou_C_G')">
              <!-- 没有找到您查找的投资者主体 <br />您也可以根据输入的内容创建投资者主体全称 -->
            </p>
          </div>
        </template>
        <template v-else>
          <div v-if="showDefaultText" class="creatnomore">
            <img src="@/assets/img/createNomore.png" alt="" />
            <p style="margin-top: 8px" :innerHTML="$t('plat_c.allTabs.publicInfo.qingshuru_C_Z')">
              <!-- 请输入您想查找的公司 <br />或根据输入的内容创建主体公司 -->
            </p>
          </div>
          <div v-else class="creatnomore">
            <img src="@/assets/img/createNomore.png" alt="" />
            <p style="margin-top: 8px" :innerHTML="$t('plat_c.allTabs.publicInfo.meizhao_Z_S')">
              <!-- 没有找到您查找的公司 <br />您也可以根据输入的内容创建主体公司 -->
            </p>
          </div>
        </template>
      </template>
    </div>
    <template #footer>
      <div class="cus-contact-footer">
        <a-button size="small" @click="currentVisible = false">{{
          $t('plat_c.allTabs.com.quxiao')
        }}</a-button>
        <a-button type="primary" size="small" style="margin-left: 16px" @click="comChangeSubmit">{{
          $t('plat_c.allTabs.com.queding')
        }}</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import logo from '@/assets/img/jinghzun.png'
import { companySearch, updateProject, updatePart } from '@/services/investment/createProject.js'
import { lpInvestorUpdate } from '@/services/investor/index.js'

export default {
  name: 'ModifyMainBodyDialog',
  props: {
    detailsInfovalues: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    bCode: {
      type: String,
      default: '',
    },
    dataId: {
      type: String,
      default: '',
    },
    defaultName: {
      type: String,
      default: null,
    },
    assetTypeId: String,
  },
  data() {
    return {
      companyFullNameParams: {},
      searchValue: '',
      clickshow: false,
      listLoading: false,
      listcontent: [],
      logo,
    }
  },
  computed: {
    showDefaultText() {
      return this.searchValue === ''
    },
    currentVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    if (this.defaultName) {
      this.searchValue = this.defaultName
      this.seachChange(this.searchValue)
    }
  },
  methods: {
    async comChangeSubmit() {
      // if(!this.clickshow) return this.$message.warning("请选择主体公司")
      if (!this.clickshow) {
        this.companyFullNameParams = {}
        this.companyFullNameParams.companyFullName = this.searchValue
        this.companyFullNameParams.id = this.dataId
      }
      if (this.searchValue === '' && this.bCode !== 'lpInvestor')
        return this.$message.warning(this.$t('plat_c.allTabs.publicInfo.zhutibunengkong'))

      if (this.bCode === 'lpInvestor') {
        this.companyFullNameParams.fullName = this.companyFullNameParams.companyFullName
        this.companyFullNameParams.investorProfile = this.companyFullNameParams.projectIntroduce
        delete this.companyFullNameParams.companyFullName
        delete this.companyFullNameParams.projectIntroduce
        this.companyFullNameParams.updateFlag = 3
      }
      let res =
        this.bCode === 'lpInvestor'
          ? await lpInvestorUpdate({ data: this.companyFullNameParams })
          : await updateProject(
              {
                data: {
                  ...this.companyFullNameParams,
                  assetTypeId: this.assetTypeId,
                },
              },
              this.bCode,
            )
      if (!res) this.$message.error(this.$t('plat_c.allTabs.com.xiugaishibai'))
      this.clickshow = false
      this.keyDelete()
      this.$emit('queryInfo')
      this.$emit('refreshTable')
      this.currentVisible = false
      this.searchValue = ''
      this.listcontent = []
    },
    listItemClick(item, index) {
      if (this.clickFlag === index) {
        this.clickFlag = -1
        this.clickshow = false
        // this.searchValue = item.full_name
        this.companyFullNameParams = {}
        this.areasParams = []
      } else {
        this.clickFlag = index
        this.clickshow = true
        this.searchValue = item.full_name
        this.companyFullNameParams = {
          insightId: item.id,
          companyFullName: item.full_name,
          id: this.dataId,
          logo: item.logo,
          projectIntroduce: item.short_description,
        }
        // this.areasParams = [item.address1, item.address2, item.address3]
        this.areasParams = [item.address1, item.address2]
      }
    },
    getTreeName(list = [], id) {
      let _this = this
      for (let i = 0; i < list.length; i++) {
        let a = list[i]
        if (a.value === id) {
          return a.label
        } else {
          if (a.children && a.children.length > 0) {
            let res = _this.getTreeName(a.children, id)
            if (res) {
              return res
            }
          }
        }
      }
    },
    async seachChange(val) {
      this.listLoading = true
      this.clickFlag = -1
      this.clickshow = false
      let res = await companySearch({ params: { keyword: this.searchValue } })
      this.listLoading = false
      if (!res) return
      this.listcontent = [...res]
      this.listcontent.forEach((ele, index) => {
        ele.address1_cz1 = this.getTreeName(this.areas, ele?.address1)
        ele.address1_cz2 = this.getTreeName(this.areas, ele?.address2)
        ele.address1_cz3 = this.getTreeName(this.areas, ele?.address3)
        if (ele.full_name === this.searchValue && this.detailsInfovalues?.insightId) {
          this.clickFlag = index
          this.clickshow = true
          this.companyFullNameParams = {
            insightId: ele.id,
            companyFullName: ele.full_name,
            id: this.dataId,
            logo: ele.logo,
            projectIntroduce: ele.short_description,
          }
          // this.areasParams = [ele.address1, ele.address2, ele.address3]
          this.areasParams = [ele.address1, ele.address2]
        }
      })
    },
    clearInput() {
      this.clickFlag = -1
      this.clickshow = false
      this.listcontent = []
    },
    keyDelete(e) {
      this.clickFlag = -1
      this.clickshow = false
      this.currName = ''
      this.companyFullNameParams = {}
      this.areasParams = []
    },
  },
}
</script>

<style lang="scss" scoped>
.change-project-main-body {
  .scale {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  .scale-cont {
    width: 113px;
    height: 36px;
    border-radius: 18px;
    border: 1px solid #e4e5eb;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .cus-upload-content {
    width: 428px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .arco-upload-list-picture-mask {
      background: rgba(45, 59, 69, 0.4);
      border-radius: 8px;
      .iconfont {
        font-size: 16px;
      }
    }
  }
  .cus-upload-icon-content,
  .custom-upload-img {
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4e5eb;
  }
  .cus-reupalod {
    width: 180px;
    height: 180px;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  // margin-top: 19px;
  height: 327px;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    align-items: center;
  }
  .icbc {
    color: #e4e5eb;
    font-size: 12px;
    margin-left: 4px;
  }
  .list {
    height: 300px;
    padding-top: 13px;
    display: flex;
    justify-content: space-between;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
    .actived {
      border: 1px solid #3272FE !important ;
    }
    :deep(.arco-list-bordered) {
      border: 1px solid transparent;
    }
    :deep(.arco-list-content) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      &::after {
        content: '';
        width: 192px;
      }
    }
    :deep(.arco-list-split .arco-list-item:not(:last-child)) {
      border: none;
    }
    .list-item {
      position: relative;
      padding: 12px;
      box-sizing: border-box;
      height: 116px;
      width: 192px;
      background: #f9fafc;
      border-radius: 4px;
      display: flex;
      margin-bottom: 12px;
      cursor: pointer;
      #triangle-topright {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 21px solid #3272FE;
        border-left: 21px solid transparent;
      }
      .choos {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        font-size: 10px;
      }
      :deep(.arco-list-item-content) {
        display: flex;
      }
      .list-item-left {
        width: 42px;
        margin-right: 8px;
        img {
          width: 42px;
          height: 42px;
          border: 1px solid #ccc;
        }
      }
      .list-item-right {
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666b71;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 1; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
          margin: 4px 0;
        }
        p:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333b44;
        }
        // p:nth-child(2) {
        //   font-size: 12px;
        //   font-family: PingFangSC-Regular, PingFang SC;
        //   font-weight: 400;
        //   color: #666b71;
        //   display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        //   -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        //   -webkit-line-clamp: 1; /**显示的行数**/
        //   overflow: hidden; /**隐藏超出的内容**/
        //   margin: 4px 0;
        // }
        // p:nth-child(3) {
        //   font-size: 12px;
        //   font-family: PingFangSC-Regular, PingFang SC;
        //   font-weight: 400;
        //   color: #333b44;
        //   span {
        //     border: 1px solid #f2f6f5;
        //     padding: 2px 4px;
        //     border-radius: 3px;
        //     margin-right: 4px;
        //   }
        // }
        // p:nth-child(4) {
        //   font-size: 12px;
        //   margin-top: 9px;
        //   font-family: PingFangSC-Regular, PingFang SC;
        //   font-weight: 400;
        //   color: #959aa2;
        //   display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        //   -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        //   -webkit-line-clamp: 1; /**显示的行数**/
        //   overflow: hidden; /**隐藏超出的内容**/
        // }
      }
    }
  }
}

.creatnomore {
  display: flex;
  // align-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
  p {
    font-size: 12px;
    width: 180px;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c1c4cb;
  }
}
</style>
