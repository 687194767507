<template>
  <div
    class="detail-info"
    :class="{ search: source === 'search' }"
    :style="{ padding: insightId ? 0 : '8px 0' }"
    @scroll="onScroll"
  >
    <div
      class="info-header"
      :class="{ 'info-header--complex': insightId, 'box-shadow': !isScrollTop }"
    >
      <span class="title">
        <template v-if="insightId">
          <div class="left">
            <div class="logo">
              <img v-if="publicInfo?.logo" :src="publicInfo?.logo" alt="" />
              <span v-else>{{ publicInfo?.name.slice(0, 1) }}</span>
            </div>

            <div class="info">
              <div class="name">{{ publicInfo?.name }}<span class="tag">公开信息</span></div>
              <div class="description">{{ publicInfo?.fullName }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="orgId">
          <p>投资机构公开信息</p>
        </template>
        <template v-else>
          {{ $t('plat_c.allTabs.publicInfo.gongkaixinxi') }}
          <a-tooltip
            v-if="showDanger && bCode !== 'lpInvestor'"
            content-class="yqjc-cont"
            content="使用舆情机器人监测：工商变更、法律诉讼、行政处罚、经营异常、新闻舆情"
          >
            <span class="danger-tips" @click="openDangerNotification">
              <i class="iconfont icon-icon_mon danger-icon" />
              {{ !fxjkObj?.isEnable ? '启用舆情监测' : '监控中' }}
              <span class="zengzhi">增值</span>
            </span>
          </a-tooltip>
        </template>
      </span>
      <div class="hearder-right">
        <div v-if="insightId" class="close-wrapper" @click="handleClose">
          <div class="close">
            <a-tooltip :content="i18n_t('plat_c.allTabs.com.guanbi')" background-color="#546880" position="bottom">
              <Iconfont name="icon_close_med" style="cursor: pointer" />
            </a-tooltip>
          </div>
          <!-- <Iconfont name="icon_close_med" /> -->
        </div>
        <div class="action">
          <a-button
            v-if="orgId && !isLock"
            size="small" 
            type="outline"
            class="guanlianjigou"
            @click="orgIdCreated"
            >关联机构</a-button
          >
          <!-- <a-button size="small" type="outline" @click="handleGetCloud">{{i18n_t('plat_c.allTabs.publicInfo.chakaigengduo')}}</a-button> -->
          <a-button
            v-if="insightId && assetTypeList.length === 1 && showCreate"
            type="primary"
            size="small"
            class="create-btn"
            @click="newAddProject"
            ><Iconfont name="icon_add">{{
              bCode === 'fund' ? '创建基金' : bCode === 'lpInvestor' ? '添加投资者' : '创建项目'
            }}</Iconfont></a-button
          >
          
          <!-- 多资产创建项目 -->
          <a-dropdown 
            v-else-if="insightId && isMultipleAsset && showCreate"
            class="detail-info_action-dropdown"
            position="br"
            @select="handleCreateAction"
          >
            <a-button
              type="primary"
              size="small"
              class="create-btn"
              ><Iconfont name="icon_add">{{i18n_t('plat_c.allTabs.com.chuangjianxiangmu')}}</Iconfont></a-button
            >
            <template #content>
              <a-doption v-for="item in assetTypeList" :key="item" :value="item.id">{{item.name}}</a-doption>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>

    <a-divider
      v-if="insightId"
      style="height: 10px; background-color: #f1f2f3;border: none;"
    />

    <div class="info-content" v-if="showType =='guquan'">
      <div class="content__left">
        <CompanyProfile :company-info="companyInfo" :is-loading="isLoading" />
        <FinancingHistory
          :list="transactionList"
          :company-info="companyInfo"
          :is-loading="isLoading"
        />
      </div>
      <div class="content__right">
        <CorporateImage :tags="companyInfo.tycTags" :companyTags="companyInfo.companyTags" :is-loading="isLoading" />
        <CompanyNews :news-list="newsList" :is-loading="isLoading" />
        <Similarity :list="similarityList" :is-loading="isLoading" />
      </div>
    </div>
    <div class="info-content" v-else>
      <div class="content__left">
        <OrganizationOverview :dataInfo="subPublicInfo.companyInfo"></OrganizationOverview>
        <NewInvestment :dataInfo="subPublicInfo.investmentCaseList"></NewInvestment>
        <OrganizationNews :dataInfo="subPublicInfo.dynamicList"></OrganizationNews>
      </div>
      <div class="content__right">
        <OrganizationImage :dataInfo="subPublicInfo.investInfo?.industry"></OrganizationImage>
        <OldInvestment :dataInfo="subPublicInfo.investInfo?.month_money" :monthCount="subPublicInfo.investInfo?.month_count"></OldInvestment>
        <FundPers :dataInfo="subPublicInfo.fundManagerList"></FundPers>
      </div>
    </div>

    <!-- 打开风险提示 -->
    <a-modal
      class="public-modal"
      v-model:visible="showDangerPop"
      :okText="i18n_t('plat_c.allTabs.publicInfo.okText')"
      :cancelText="i18n_t('plat_c.allTabs.publicInfo.cancelText')"
      :maskClosable="false"
      @ok="closeDangerNotification"
      width="468px"
    >
      <template #title>
        <div>{{ !fxjkObj?.isEnable ? '启用舆情监测' : '取消舆情监测' }}</div>
      </template>
      <div class="set-owner-tip">
        <icon-exclamation-circle-fill :style="{ fontSize: '22px', color: '#ffa55c' }" />
        <span style="margin-left: 4px"
          >{{ !fxjkObj?.isEnable ? '开启后可监测工商变更、法律诉讼、行政处罚、经营异常、新闻舆情，确认开启？' : '取消舆情监测将无法接收公司舆情信息，是否确认取消？' }}</span
        >
      </div>
    </a-modal>
    <!-- 关联投资机构 -->
    <AddSubFunds v-if="subfunVis" width="640px" title="关联投资机构" v-model:visible="subfunVis" @handleOk="handleOk">
      <AddOrg
        :bCode="bCode"
        :dataId="dataId"
        :orgInfo="{
          id: subPublicInfo.companyInfo?.id,
          name: subPublicInfo.companyInfo?.name,
        }"
        ref="addValue"
        v-if="subfunVis"
      />
    </AddSubFunds>
    <!-- 非项目成员提示 -->
    <a-modal
      v-model:visible="notProjectTeam"
      class="public-modal"
      okText="使用舆情监测机器人"
      title="舆情监测"
      hide-cancel
      @ok="handlerCallRabbit"
    >
      <p style="font-size: 14px; line-height: 20px; color: #1F2329;">您未在项目成员中，无法启动群聊的舆情监测机器人。如需使用此功能，请在「舆情监测」应用中单独使用。</p>
      <img class="yq-gif" src="@/assets/img/gif/yqNotInProject.gif" alt="非项目成员gif">
    </a-modal>
    <!-- 舆情监测开启成功 -->
    <a-modal
      v-model:visible="openYqjcSuccess"
      :maskClosable="false"
      class="public-modal"
      okText="知道了"
      title="监控成功"
      hide-cancel
      @ok="openYqjcSuccess = false"
    >
      <p style="font-size: 14px; line-height: 20px; color: #1F2329;">您可在此项目群聊中及时获取公司的舆情信息，也可在群聊中@舆情监测进行规则设置。</p>
      <img class="yq-gif" src="@/assets/img/gif/yqOpen.gif" alt="成功gif">
    </a-modal>
    <!-- 主体重复弹窗 -->
    <showeQuallyDialog
      :currName="publicInfo?.fullName"
      :quallyList="quallyList"
      v-if="showequally"
      v-model:showequally="showequally"
      @beginTo="beginTo"
    />
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment'
import { computed, onMounted, PropType, reactive, ref, inject } from 'vue'
import Iconfont from '@/components/Iconfont.vue'
import CreateDialog from '@/views/beforeInv/createDialog.vue'
import useScroll from '@/composables/useScroll'
import CompanyProfile from './cards/companyProfile.vue'
import CorporateImage from './cards/corporateImage.vue'
import CompanyNews from './cards/companyNews.vue'
import FinancingHistory from './cards/financingHistory.vue'
import Similarity from './cards/similarity.vue'
// 币权组件
import FundPers from './subFunds/fundPers.vue'
import NewInvestment from './subFunds/newInvestment.vue'
import OldInvestment from './subFunds/oldInvestment.vue'
import OrganizationImage from './subFunds/organizationImage.vue'
import OrganizationNews from './subFunds/organizationNews.vue'
import OrganizationOverview from './subFunds/organizationOverview.vue'
import { updateProject } from '@/services/investment/createProject.js'
import {
  getPublicInfoByDataId,
  getPublicInfoByCompanyFullName,
  getLpPublicInfoByDataId,
  getFofPublicInfo,
  getCloudUrl,
  getCloudUrlByInsightId,
  getCloudUrlByOrgId,
  getPublicInfoByInsightId
} from './services'
import { getAppInfo } from '@/services/other/index.js';
import { fetchViewTree } from '@/views/beforeInv/listFilter/services'
import {
  chatInfo,
  chatDataRights,
  chatCreate,
  chatMembersAdd,
} from '@/services/investment/chat.js'
import { fetchRolePermission } from '@/services/sysconfig/admin.js'
import { findMyselfByDataId, getInvestedCompByName } from '@/services/investment/index.js'
import * as InterfaceOther from '@/services/other/index.js';
import { AmountOfMoney,AssetTypeItem } from './types'
import { Message } from '@arco-design/web-vue'
import { useI18n } from 'vue-i18n'
import { getDataTypeByBusinessCode } from '@/utils/enums.js'
import AddSubFunds from '@/views/test/modelMemo.vue'
import AddOrg from './addOrg.vue'
import showeQuallyDialog from '@/views/beforeInv/edit/components/showequallyDialog.vue'

import { useStore } from 'vuex'
import { string } from 'mathjs'
const { t: i18n_t } = useI18n()

const store = useStore()

const props = defineProps({
  from: String, //detailTab详情页， drawer列表页公开信息抽屉，holdInfo 持有情况分析台账-详情页
  projectName: String,
  bCode: String as PropType<string>,
  appCode: {
    type: String as PropType<string>,
    default: '',
  },
  dataId: {
    type: String as PropType<string>,
    required: true,
    default: '',
  },
  assetTypeId: String,
  insightId: String as PropType<string>,
  orgId: String as PropType<string>,
  showCreate: {
    type: Boolean as PropType<boolean>,
    default: true,
  }
})
const emit = defineEmits(['close', 'getChatInfo','queryInfo', 'refreshTable'])
const refreshTable = inject('refreshTable')
const showDetails = inject('showDetails')
const closedPublicDetail = inject('closedPublicDetail')

const subfunVis = ref(false)
const isLoading = ref(false)
const showDialog = ref(false)
const showType = ref('guquan')
const companyInfo = reactive<
  Record<string, unknown> & {
    tags: { name: string; id: string }[]
    financeAmount?: AmountOfMoney
    marketValue?: AmountOfMoney
    tycMarketValue?: string
    tycFinanceAmount?: string
    logo?: string
    name?: string
    fullName?: string
  }
>({
  tags: [],
})
const newsList = ref<any[]>([])
const transactionList = ref<any[]>([])
const similarityList = ref<any[]>([])
const addValue = ref(null)

const showDangerPop = ref(false)
const canEdit = ref(false)
const creating = ref(false)
const chatId = ref('')
let fxjkObj = ref()
const permissions = ref([]) //权限集合
const getChatInfo = async () => {
  await chatInfo({ params: { 
    dataId: props.dataId,
    dataType: getDataTypeByBusinessCode(props.bCode)
  } }).then((res) => {
    chatId.value = res?.chatId || ''
    chatId.value !== '' && getNotifyList()
  })
}
const orgIdCreated = ()=>{
  console.log(1111);
  
  subfunVis.value = true
}
const handleOk = async()=>{
  if(!addValue.value.allValue.fundFullName){
    return Message.warning('投资机构名称不能为空')
  }
  if(!addValue.value.allValue.orgId){
    return Message.warning('请选择投资机构')
  }
  delete addValue.value.allValue.fundFullName // 关联机构时不改变基金全称
  let res =await updateProject({data:addValue.value.allValue},props.bCode,)
  subfunVis.value = false
  emit('queryInfo')
  emit('refreshTable')
}

// 主体重复提示
const quallyList = ref([])
const showequally = ref(false)
const curAssetInfo = ref()
const getInvestedCompList = async () => {
  let params
  if (curAssetInfo.value.businessCode === 'lpInvestor') {
    params = {
      fullName: publicInfo.value.fullName
    }
  } else if (['beforeFof', 'afterFof'].includes(curAssetInfo.value.businessCode)) {
    params = {
      fundFullName: publicInfo.value.fullName,
      assetTypeId: curAssetInfo.value.id
    }
  } else {
    params = {
      companyFullName: publicInfo.value.fullName,
      assetTypeId: curAssetInfo.value.id,
    }
  }
  let res = await getInvestedCompByName(
    { data: params },
    curAssetInfo.value.businessCode,
  )
  quallyList.value = res ?? []
  if (res?.length > 0) {
    showequally.value = true
  } else {
    showequally.value = false
  }
}

defineExpose({
  getChatInfo
})
!props.insightId && props.from !=='holdInfo' && getChatInfo()
const isAdmin = computed(()=> store.state.isAdmin)
const isEdit = computed(()=> store.state.isEdit)
const isLock = computed(()=> store.state.isLock)
const userRoleId = computed(()=> store.state.userSelfInfo?.roleId)

const showDanger = ref(false)
const getMyRights = () => {
  findMyselfByDataId({
    params:{
      dataId: props.dataId,
      businessCode: props.bCode
    }
  }).then(res => {
    if (res && res.length) {
      showDanger.value = res[0].permission != 1 
    }
    showDanger.value = !showDanger.value ? isEdit.value : showDanger.value
  })
}
props.dataId && getMyRights()
const closeDangerNotification = () => {
  chageNotify(fxjkObj)
}
const notProjectTeam = ref(false) // 非项目成员
const openDangerNotification = () => {
  // 只要有编辑权限就可以操作开启和关闭，所以注释下面判断
  // if (isAdmin.value) {
  //   return Message.warning('无权限操作')
  // }
  if (fxjkObj.value && fxjkObj.value.isEnable) {
    return showDangerPop.value = true
  }
  if (creating.value) return
  chatDataRights(props.dataId, props.bCode).then((res) => {
    // const resType = typeof(res)
    // if (resType === 'boolean') {
    if (res) {
      if (!chatId.value) {
        Message.loading('正在创建群聊中...')
        chatCreate({
          params:{
            dataId: props.dataId,
            type: getDataTypeByBusinessCode(props.bCode)
          }
        }).then((res) => {
          addChatMembers(res.chatId)
        })
      } else {
        chageNotify(fxjkObj)
      }
    } else {
      // Message.warning('您未在该项目成员中，无法发起群聊')
      notProjectTeam.value = true
      creating.value = false
    }
  })
  creating.value = true
}

// 唤醒舆情机器人
let robbitAppId = ''
const getRobbitAppId = async () => {
  const res = await getAppInfo({
    data: {
      name: '舆情监测'
    }
  });
  robbitAppId = res[0]['larkAppId']
}
props.from !== 'holdInfo' && getRobbitAppId()
const handlerCallRabbit = () => {
  window.open(`https://applink.feishu.cn/client/bot/open?appId=${robbitAppId}`)
}

// 增加群聊人员
const addChatMembers = async (chatId: String) => {
  await chatMembersAdd({
    params:{
      dataId: props.dataId,
      dataType: getDataTypeByBusinessCode(props.bCode)
    }
  })
  // emit('getChatInfo')
  await getChatInfo()
  // await getNotifyList()
  await chageNotify(fxjkObj)
}
const getNotifyList = async () => {
  const res = await InterfaceOther.publicInformationQueryIsOpen({
    params: {
      dataId: props.dataId,
    }
  })
  fxjkObj.value = {
    isEnable: res
  }
}
props.dataId && getNotifyList()

const openYqjcSuccess = ref(false) // 开启舆情监测成功
const chageNotify = async (fxjkObj: any) => {
  InterfaceOther.publicInformationSave({
    data :{
      dataId: props.dataId,
      chatId: chatId.value,
      isOpen: !fxjkObj.value.isEnable,
      businessCode: props.bCode,
    }
  }).then((res: any) => {
    fxjkObj.value.isEnable && Message.success(`${i18n_t('plat_c.allTabs.publicInfo.dangerClose')}`)
    openYqjcSuccess.value = !fxjkObj.value.isEnable
    getChatInfo()
  }).finally(() => {
    creating.value = false
  })
}

const { isScrollTop, onScroll } = useScroll()

const publicInfo = computed(() => {
  return {
    logo: companyInfo.logo,
    name: companyInfo.name || '',
    fullName: companyInfo.fullName || '',
  }
})
const handleClose = () => emit('close')
const handleGetCloud = () => {
  let getUrl
  if (props.orgId) {
    getUrl = getCloudUrlByOrgId(props.orgId)
  } else if (props.insightId) {
    getUrl = getCloudUrlByInsightId(props.insightId)
  } else {
    getUrl = getCloudUrl(props.bCode, props.dataId, props.assetTypeId)
  }
  // const getUrl = props.insightId
  //   ? getCloudUrlByInsightId(props.insightId)
  //   : getCloudUrl(props.bCode, props.dataId, props.assetTypeId)
  getUrl.then((url) => window.open(url))
}
const newAdd = inject('newAddProject')
const newAddProject = async () => {
  curAssetInfo.value = assetTypeList.value[0]
  if (!['beforeCoin', 'afterCoin', 'fund'].includes(curAssetInfo.value.businessCode)) { // 子基金和基金不需要重复主体提示
    await getInvestedCompList()
  }
  if (showequally.value) return
  newAdd(project, assetTypeList.value[0])
  closedPublicDetail()
}
const handleCreateAction = async (val: string) => {
  const targetObj = (assetTypeList.value || []).find((item: any) => {
    return item.id === val
  })
  curAssetInfo.value = targetObj
  if (!['beforeCoin', 'afterCoin', 'fund'].includes(curAssetInfo.value.businessCode)) { // 子基金和基金不需要重复主体提示
    await getInvestedCompList()
  }
  if (showequally.value) return
  newAdd(project, targetObj)
  closedPublicDetail()
}
const beginTo = () => {
  newAdd(project, curAssetInfo.value)
  closedPublicDetail()
}
const closeDialog = () => {
  showDialog.value = false
}

const source = computed(() => {
  return props.insightId ? 'search' : 'details'
})
const project = computed(() => {
  return {
    fundFullName: companyInfo.fullName,
    companyFullName: companyInfo.fullName,
    logo: companyInfo.logo,
    insightId: companyInfo.id,
    projectIntroduce: companyInfo.shortDescription,
    // area: [companyInfo.address1, companyInfo.address2, companyInfo.address3],
    area: [companyInfo.address1, companyInfo.address2],
    projectName: companyInfo.name,
  }
})
const isMultipleAsset = ref(false)
const assetTypeList = ref([])
const init = async () => {
  isLoading.value = true
  // const getPublic = props.insightId
  //   ? () => getPublicInfoByInsightId(props.insightId as string)
  //   : () => getPublicInfoByDataId(props.bCode === 'investedCompany' ? 1 : 0, props.dataId, props.assetTypeId!)
  let getPublic
  getPublic = props.insightId
    ? () => getPublicInfoByInsightId(props.insightId as string)
    : props.bCode !== 'lpInvestor'
    ? () => getPublicInfoByDataId(props.bCode === 'investedCompany' ? 1 : 0, props.dataId, props.assetTypeId!)
    : () => getLpPublicInfoByDataId(0, props.dataId)

  if (props.from === 'holdInfo') getPublic = getPublicInfoByCompanyFullName
  getPublic({
    companyFullName: props.projectName
  })
    .then((res) => {
      Object.assign(companyInfo, res?.companyInfo ?? {})
      newsList.value = (res?.dynamicList ?? []).map((news: any) => {
        return {
          ...news,
          date: moment(new Date(news.publishedTime)).format('YYYY-MM-DD'),
        }
      })
      transactionList.value = res?.transactionList ?? []
      similarityList.value = res?.similarityList ?? []
    })
    .finally(() => (isLoading.value = false))
    if(!isAdmin.value) {
      await getAssetPermission()
    }
    getAssetType()
}
const getAssetType = () => {
  // 搜索中公开信息新建的时候 需要判断是否 是多币种 
  props.insightId && fetchViewTree({code: props.appCode})
    .then((res) => {
      res = res?.filter((item:any) => item.name !== '相关业务')
      if(isAdmin.value) {
        isMultipleAsset.value = true
        assetTypeList.value = res
        return
      }
      // 获取有创建权限的资产类型
      assetTypeList.value = res.reduce((result:any, item:any) => {
        const obj:any = permissions.value.filter((per:any) => {
          return per.assetTypeId && per.businessCode === item.businessCode
        })[0]
        if(obj) {
          if(['fund', 'lpInvestor'].includes(obj.businessCode) ) {
            obj?.bookPermission[obj.businessCode].create && result.push(item)
          } else {
            const {assetTypeId='', bookPermission={}} = obj;
            if(bookPermission[assetTypeId] && bookPermission[assetTypeId].create){
              result.push(item)
            }
          }
        }

        return [...result]
      }, [])
      if (assetTypeList.value.length > 1) {
        isMultipleAsset.value = true
      } else {
        isMultipleAsset.value = false
      }
    })
}
const subPublicInfo = ref({})
const subInfor = async()=>{
  let res = await getFofPublicInfo(getDataTypeByBusinessCode(props.bCode), props.dataId, props.assetTypeId, moment().format('YYYY'))
  subPublicInfo.value = res
}
const getAssetPermission = async () => {
  const res = await fetchRolePermission(userRoleId.value)
  permissions.value = res.permissions || []
}
onMounted(async () => {
  if(props.from == 'drawer'){
    showType.value='guquan'
    init()
  }else if(props.from == 'detailTab'){
    if(['beforeFof', 'afterFof'].includes(props.bCode)){
      if (props.orgId) {
        // 子基金
        showType.value = 'zifund'
        subInfor()
      }
    }else{
      showType.value='guquan'
      init()
    }
  }
  else if (props.from == 'holdInfo') {
    init()
  }
  if(!isAdmin.value) {
    await getAssetPermission()
  }
  await getAssetType()

})
</script>

<style lang="scss" scoped>
.danger-tips {
  height: 28px;
  display: flex;
  align-items: center;
  padding: 6px 4px 6px 8px;
  margin-left: 8px;
  font-family: PingFangSC-Medium;
  font-size: 12px;
  // font-weight: bold;
  color: #ED8A00;
  border-radius: 6px;
  background: #FFF8EB;
  border: 1px solid #FFE1B8;
  cursor: pointer;

  &:hover {
    background: #F4EBD8;
  }
  .danger-icon {
    font-size: 14px;
    margin-right: 4px;
  }
  .zengzhi {
    width: 36px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 6px;
    background: #ED8A00;
    margin-left: 4px;
    color: #fff;
  }
}
.detail-info {
  // padding: 8px 0;
  height: 100%;
  // overflow: auto;
  &.search {
    // background: #f1f2f5;
    display: flex;
    flex-direction: column;
    .info-content {
      background: white;
      // margin-top: 10px;
      height: calc(100% - 120px);
      overflow: auto;
      margin-top: 0;
    }
  }
  .info-header {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    transition: box-shadow 0.3s ease;
    &.info-header--complex {
      padding-bottom: 16px;
      height: 94px;
      align-items: flex-end;
      width: 100%;
      background: white;
      // position: sticky;
      // top: 0;
      position: relative;
      z-index: 999;
      &.box-shadow {
        box-shadow: 0 0 10px #b2b2b2;
      }
      .title {
        padding-bottom: 8px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #282d33;
      // padding-bottom: 8px;
      .left {
        display: flex;
        flex: 1;
        overflow: hidden;
        .logo {
          flex-shrink: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          flex-wrap: nowrap;
          img {
            width: 100%;
            height: auto;
            max-height: 40px;
          }
          span {
            display: inline-block;
            width: 100%;
            height: 100%;
            font-size: 16px;
            line-height: 38px;
            color: #fff;
            background: #99adff;
          }
        }
        .info {
          padding-left: 8px;
          overflow: hidden;
          .name,
          .description {
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
            .tag {
              margin-left: 4px;
              border-radius: 4px;
              // border: 1px solid #b8ddff;
              font-family: PingFangSC;
              font-size: 12px;
              line-height: 18px;
              padding: 0px 6px;
              background-color: rgba(255, 162, 0, 0.08);
              color: #ed8a00;
              font-weight: 500;
              display: inline-block;
            }
          }
          .name {
            font-family: PingFangSC;
            color: #333b44;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
          }
          .description {
            margin-top: 2px;
            font-family: PingFangSC;
            color: #959aa2;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
    .hearder-right {
      display: flex;
      flex-direction: column;
      .close-wrapper {
        display: flex;
        justify-content: flex-end;
      }
      .close {
        margin-bottom: 10px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        color: #8e909f;
        &:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
        :deep() {
          .iconfont {
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
      .action {
        display: flex;
      }
    }
  }
  .info-content {
    min-width: 710px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px;
    .content__left {
      width: 50%;
      .history {
        margin-bottom: 0;
      }
    }
    .content__right {
      width: 0;
      flex: 1;
      padding-left: 12px;
      .similarity {
        margin-bottom: 0;
      }
    }
  }
  .create-btn {
    margin-left: 10px;
  }
  .guanlianjigou{
    margin-right: 10px;
  }
}
</style>

<style lang="scss">
  @import '@/assets/styles/var.scss';

  .yq-gif {
    width: 100%;
    display: block;
    margin-top: 12px;
    border-radius: 6px;
    border: 1px solid #DBE0E3;
  }

  .arco-tooltip-content {
    &.yqjc-cont {
      max-width: 450px;
    }
  }
  .detail-info_action-dropdown {
    transform: translateY(7px);
    width: 144px;
    .arco-dropdown-list {
      margin: 4px;
    }
    // &::after {
    //   content: '';
    //   border: 7px solid;
    //   z-index: 999;
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   margin-top: -14px;
    //   top: 0;
    //   border-color: transparent transparent white transparent;
    // }
  
    // &[trigger-placement='br'] {
    //   &::after {
    //     // right: 50%;
    //     transform: translateX(28px);
    //   }
    // }
    // &[trigger-placement='top'] {
    //   transform: translateY(-7px);
    //   &::after {
    //     border-color: white transparent transparent transparent;
    //     bottom: 0;
    //     top: initial;
    //     margin-bottom: -14px;
    //   }
    // }
    .arco-dropdown {
      box-shadow: 0px 0px 14px 3px rgb(0 0 0 / 16%);
    }
    .arco-dropdown-list-wrapper {
      max-height: 350px !important;
    }
    .arco-dropdown-option {
      // width: 142px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1f2329;
      height: 34px;
      line-height: 34px;
      border-radius: 4px;
      &:hover {
        background-color: #eceeee;
      }
      .iconfont {
        &::before {
          color: $icon-color-black;
          // color: #9599ab;
        }
        .text {
          margin-left: 8px;
        }
      }
    }
  }
</style>
