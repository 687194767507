<template>
    <div class="content-warp">
        <div class="full-screen-icon" @click="toggleFullScreen"><i
                :class="['iconfont', isFullScreen ? 'icon-icon_chart_a' : 'icon-icon_chart_b']" /></div>
        <div class="graph-wrap">
            <RelationGraph ref="graphRef" :options="graphOptions" :on-node-click="onNodeClick"
                :on-line-click="onLineClick" :on-canvas-click="onCanvasClick">
                <template #node="{ node }">
                    <div :class="['c-my-rg-node', 
                        hoverToLins.length > 0 ? (hoverToLins.indexOf(node.id) == -1 ? 'undertintNode' : ''):'default'
                    ]"
                        @mouseover="nodeSlotOver(node, $event)" @mouseout="nodeSlotOut(node, $event)">
                        <!-- node.id -->
                        {{textFilter(node.id ) }}
                    </div>
                </template>
                <!-- <template #line="{ line, link, relationGraph }">
                    <MyLine
                    v-if="line?.isHide === false"
                    slot="line"
                    :slot-scope="{line, link, relationGraph}"
                    :line="line"
                    :link="link"
                    :relation-graph="relationGraph"
                />
                </template>  -->
            </RelationGraph>
            <div v-if="checkNodeData.name" class="tipsCard">
                <img class="bg" src="@/assets/img/relation/card-bg.png" alt="">
                <div class="content">
                    <div class="name-wrap">
                        <a-tooltip content="进入公司关系网" :disabled="props.name==checkNodeData.name">
                            <span @click="handleToRelation(checkNodeData.name)"  :class="['name',props.name==checkNodeData.name?'disabled':'']">{{ checkNodeData.name }}</span>
                        </a-tooltip>
                    </div>
                    <div v-if="checkNodeData.relatedList?.length" class='tagList'>
                        <identityTags :identityPortList="checkNodeData.relatedList" :needIcon="true" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import RelationGraph from 'relation-graph-vue3';
import { computed, ref, onMounted, nextTick, watchEffect } from 'vue';
 

/**
 * APIs
 */
// import { queryByCompanyList } from '@/services/investment/index.js'
/**
 * Components
 */

/**
* Types
*/
import type { RGOptions, RGNode, RGLine, RGLink, RGUserEvent, RGJsonData, RelationGraphComponent } from 'relation-graph-vue3';

/**
 * Constants
 */
import identityTags from '@/components/form/fields/searchInsightCompany/identityTags.vue'

/**
 * Props
 */
const props = defineProps({
    isFullScreen: Boolean,
    name: {
        type: String,
        default: '',
    },
    disableZoom: {
        type: Boolean,
        default: false,
    },
    graphLines: {
        type: Object,
        default: {},
    },
    graphNodes: {
        type: Object,
        default: {},
    },
    rootId: {
        type: String,
        default: '',
    },
    // relatedList: {
    //     type: Array,
    //     default: [],
    // },
    getDataFun: {
        type: Function,
        default: () => { },
    },
    getCompanyListFun: {
        type: Function,
        default: () => { },
    },
    checkData:{
        type: Object,
        default: {
            name: '',
            relatedList: []
        },
    }
})

/**
 * Hooks
 */
const emit = defineEmits(['toggleFullScreen', 'newData','checkNodeData','reset'])
defineExpose({
    refresh: () => {
    showGraph()
  },
})


/**
 * States
 */
const isOnload=ref(false)
const graphRef = ref<RelationGraphComponent | null>(null);
const graphOptions: RGOptions = {
    defaultFocusRootNode: true,
    // disableDragNode: true, // 是否禁用图谱中节点的拖动
    allowSwitchLineShape: false,
    // disableNodeClickEffect: true, // 是否禁用节点默认的点击效果（选中、闪烁）
    disableLineClickEffect: true, // 是否禁用线条默认的点击效果（选中、闪烁）
    allowShowRefreshButton: false, // 刷新按钮
    allowShowDownloadButton: false, // 显示【下载图片】按钮
    disableZoom: props.disableZoom, // 是否禁用图谱的缩放功能
    defaultNodeShape: 1, // 1 矩形 2 椭圆
    //     defaultNodeWidth: 220,
    defaultNodeHeight: 36,
    //   defaultNodeColor: '#fff', // 节点颜色
    //   defaultNodeBorderWidth: 0, // 节点 border宽度
    layout: {
        // layoutName: 'center',
        // layoutClassName: 'seeks-layout-center',
        // distance_coefficient:props.disableZoom?(props.graphNodes.length>2?1: 1.2):1,
        label: '自动布局',
        layoutName: 'force',
        layoutClassName: 'seeks-layout-force',
        maxLayoutTimes:150,
        // force_node_repulsion:1,
        force_line_elastic:0.4,

        // "layoutName": "force",
        // "centerOffset_x": 0,
        // "centerOffset_y": 0,
        // "distance_coefficient": 1,
        // "maxLayoutTimes": 300,
        // "force_node_repulsion": 1,
        // "force_line_elastic": 1
    },
    // defaultLineMarker: {
    //     "markerWidth": 20,
    //     "markerHeight": 20,
    //     "refX": 3,
    //     "refY": 3,
    //     "data": "M 0 0, V 6, L 4 3, Z"
    // },
    defaultNodeColor: '#E6EDFC',
    defaultLineColor: '#D3D6E8',
    // 这里可以参考"Graph 图谱"中的参数进行设置

};
const checkNodeData: any = ref({})
const hoverToLins: any = ref([])
const __graph_json_data: any = ref({
    rootId: '',
    nodes: [
        //   // 注意：在节点配置信息中，你的自定义属性需要像下面这样放到data标签中，否则数据会丢失
        //   { id: '1', text: '节点-1',},
        //   { id: '4', text: '节点-4', },
        //   { id: '2', text: '节点-2', },
        //   { id: '6', text: '节点-6', },
        //   { id: '7', text: '节点-7', },
        //   { id: '8', text: '节点-8', },
        //   { id: '9', text: '节点-9'}
    ],
    lines: [
        //   { from: '1', to: '2', text: '投资' ,fontColor: '#4E5969'},
        //   { from: '4', to: '2', text: '高管' ,fontColor: '#4E5969'},
        //   { from: '6', to: '2', text: '高管',fontColor: '#4E5969'},
        //   { from: '7', to: '2', text: '高管' ,fontColor: '#4E5969'},
        //   { from: '8', to: '2', text: '高管' ,fontColor: '#4E5969'},
        //   { from: '9', to: '2', text: '高管' ,fontColor: '#4E5969'},
    ]
});

const textFilter = computed(() => {
  return (str:string) =>{
    if(str.length > 15){
        return str.slice(0,15)+'...'
    }
    return str
  }
})

const handleAddData = async (nodeObject: RGNode) => {
    let res = await props.getDataFun(nodeObject.id)
    const jsonData: RGJsonData = {
        nodes: [],
        lines: []
    };

    (res?.child || []).map((item: any) => {
        let findData = props.graphNodes.find((ff: any) => ff.id == item.name);
        if (!findData) {
            jsonData.nodes.push({ id: item.name, text: textFilter.value(item.name) });
        }

        (item?.line || []).map((ff: any) => {
            if (ff.direction == 2) {
                let findData = props.graphLines.find((ff: any) => ff.from == item.name && ff.to == nodeObject.id);
                if (!findData) {
                    jsonData.lines.push({ from: item.name, to: nodeObject.id, text: textFilter.value(ff.name), fontColor: '#4E5969' })
                }
            } else {
                let findData = props.graphLines.find((ff: any) => ff.from == nodeObject.id && ff.to == item.name);
                if (!findData) {
                    jsonData.lines.push({ from: nodeObject.id, to: item.name, text: textFilter.value(ff.name), fontColor: '#4E5969' })
                }
            }
        })
    })

    const graphInstance = graphRef.value?.getInstance();
    if (graphInstance) {
        if(jsonData.lines.length > 0||jsonData.nodes.length > 0){
            emit('newData', jsonData)
                // graphInstance.addNodes(jsonData.nodes);
                // graphInstance.addLines(jsonData.lines);
                // await graphInstance.doLayout();
                // graphInstance.doLayout();
                await graphInstance?.appendJsonData(jsonData);
                await graphInstance.focusNodeById(nodeObject.id)
                await graphInstance.moveToCenter();
                await graphInstance.zoomToFit();
        
                // await graphInstance.refresh()
        }else{
            await graphInstance.focusNodeById(nodeObject.id)
        }
    }
}

/**
 * Events
 */

const showGraph = async (isFocusNodeById=false) => {
    // console.log(props,'props')
    __graph_json_data.value.lines = props.graphLines
    __graph_json_data.value.nodes = props.graphNodes
    __graph_json_data.value.rootId = props.rootId

    const graphInstance = graphRef.value?.getInstance();
    if (graphInstance) {
        await graphInstance.setJsonData(__graph_json_data.value);
        if(props.rootId!==checkNodeData.value.name ){
            await graphInstance.focusNodeById(checkNodeData.value.name)
        }
        await graphInstance.moveToCenter();
        setTimeout(() => {
            graphInstance.zoomToFit();
        }, 600);
        // await graphInstance.refresh();
        
        // graphInstance.focusRootNode()

    }
};
 // 进入公司关系网
 const   handleToRelation=(companyFullName) =>{
    if(props.name==companyFullName){
        return
    }
    emit('reset',companyFullName)
 }
const nodeSlotOver = (nodeObject: RGNode) => {
    // console.log('nodeSlotOver:', nodeObject);
    nextTick(() => {
        // 以下是高亮代码
        const allChildIds = deepGetAllChildIds(nodeObject, [], nodeObject);
        console.log('allChildIds:', allChildIds);
        hoverToLins.value=allChildIds
        const graphInstance = graphRef.value?.getInstance();
        if (graphInstance) {
            // for (const node of graphInstance.getNodes()) {
            //     if (allChildIds.includes(node.id)) {
            //         node.opacity = 1;
            //         node.color = '#E6EDFC;';
            //     } else {
            //         node.opacity = 0.2;
            //         node.color = undefined;
            //     }
            // }
            for (const link of graphInstance.getLinks()) {
                if (allChildIds.includes(link.fromNode.id) && allChildIds.includes(link.toNode.id) &&(nodeObject.id==link.toNode.id||nodeObject.id==link.fromNode.id)) {
                // if (allChildIds.includes(link.fromNode.id) && allChildIds.includes(link.toNode.id)) {
                    link.relations.forEach(line => {
                        line.color = '#3272FE';
                        line.opacity = 1;
                    });
                } else {
                    link.relations.forEach(line => {
                        line.color = '';
                        line.opacity = 0.2;
                    });
                }
            }
        }
    })
};

const nodeSlotOut = (nodeObject: RGNode) => {
    // console.log('nodeSlotOut:', nodeObject);
    const graphInstance = graphRef.value?.getInstance();
    if (graphInstance) {
        for (const node of graphInstance.getNodes()) {
            node.opacity = 1;
            node.color = undefined;
        }
        for (const link of graphInstance.getLinks()) {
            link.relations.forEach(line => {
                line.opacity = 1;
                line.color = undefined;
            });
        }
    }
    hoverToLins.value=[]
};
const onNodeClick = async (nodeObject: RGNode, $event: RGUserEvent) => {
    console.log('onNodeClick:', nodeObject, $event);
    checkNodeData.value = {
        name: nodeObject.id
    }
    //    getCompanyList({ data: { companyList: [nodeObject.id] } })
    let res = await props.getCompanyListFun({ data: { companyList: [nodeObject.id] } })
    if (res.length) {
        checkNodeData.value.relatedList = (res[0]?.identityPortList) || []

        // console.log('checkNodeData', checkNodeData, res)
    }
    if (!nodeObject.lot?.childs?.length) {
        await handleAddData(nodeObject);
    }
    emit('checkNodeData',checkNodeData.value)

};

const deepGetAllChildIds = (node: RGNode, ids: string[] = [], rootNode: RGNode | null): string[] => {
    if (ids.includes(node.id)) return ids;
    ids.push(node.id);
    if (rootNode && rootNode.id && graphRef.value?.getInstance()) {
        // for (const cNode of node.lot.childs) {
        //     deepGetAllChildIds(cNode, ids, null);
        // }
        const graphInstance = graphRef.value?.getInstance();
        // let nodes=graphInstance.getNodes()
        // 指向我的节点
        node.targetFrom.map((line: any) => {
            ids.push(line.id);
        })
        // 我指向节点
        node.targetTo.map((line: any) => {
            ids.push(line.id);
        })
       
    }

    return ids;
};

const onCanvasClick = ($event: RGUserEvent) => {
    // console.log('onCanvasClick:');
};

const onLineClick = (lineObject: RGLine, linkObject: RGLink, $event: RGUserEvent) => {
    // console.log('onLineClick:', lineObject);
};

const toggleFullScreen = () => {
    emit('toggleFullScreen')
}
/**
 * Effects
 */
//  watch(
//     () => checkNodeData.value,
//     (v) => {
//         emit('checkNodeData',v)
//     }
// )

watchEffect(() => {
    checkNodeData.value=props.checkData
    // if (!isOnload.value&&props.rootId) {
        
    //     if (props.relatedList.length > 0) {
    //         isOnload.value=true
    //         checkNodeData.value = {
    //             name: (props.graphNodes.find((item: any) => item.id == props.rootId))?.id,
    //             relatedList: props.relatedList || []
    //         }
    //     } else {
    //         checkNodeData.value = {
    //             name: (props.graphNodes.find((item: any) => item.id == props.rootId))?.id,
    //             relatedList: []
    //         }
    //     }
    // }
})
watchEffect(() => {
    if (props.checkData.name && __graph_json_data.value.nodes == 0) {
        nextTick(() => showGraph())
    }
})
onMounted(() => {
    // showGraph();
});
</script>
<style lang="scss" scoped>
.content-warp {
    height: 100%;
    position: relative;
    flex: 1;

    .full-screen-icon {
        position: absolute;
        top: 16px;
        right: 15px;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        padding: 3px;
        box-sizing: border-box;
        border-radius: 10px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #DEE0E3;
        cursor: pointer;

        .iconfont {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: #646A73;
            border-radius: 4px;
            transition: .2s;

            &:hover {
                background: #dcdcdc80;
            }
        }
    }

    .graph-wrap {
        height: 100%;
        position: relative;

        .relation-graph {
            .undertintNode{
                background: #FAFBFE;
                 color: #D6E3FF!important;
            }
            .c-my-rg-node {
                width: 100%;
                height: 100%;
                // border-radius: 50%;
                white-space: nowrap;
                font-family: PingFang SC;
                font-size: 14px;
                // font-weight: 600;
                line-height: 36px;
                padding: 0 16px;
                letter-spacing: 0px;
                /* 常用色/白 */
                color: #3272FE;
                border-radius: 99px !important;
            }




            :deep() {
                .c-rg-line-text {
                    dominant-baseline: middle !important;
                }

                .rel-node-peel {
                    cursor: pointer;
                    margin: 0;

                    .rel-node-checked {
                        .c-my-rg-node {
                            font-weight: 600;
                            color: #fff;
                            box-shadow: 0px 4px 14px 2px rgba(129, 147, 255, 0.3);
                            background: linear-gradient(99deg, #5C85FF 2%, #4059FE 95%);
                        }
                    }

                    .rel-node {
                        border-radius: 99px !important;
                        box-shadow: none !important;
                    }

                }

                .rel-map {
                    background: url('@/views/components/graph/bg.svg');
                }

                .graph-title {
                    position: absolute;
                    top: 16px;
                    left: 16px;
                    font-family: 苹方-简;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 20px;
                    color: #1F2329;
                    z-index: 1;
                }

                .rel-toolbar {
                    width: 44px !important;
                    background: #fff !important;
                    border: 1px solid #DEE0E3 !important;
                    border-radius: 10px !important;
                    box-shadow: none !important;

                    &.rel-toolbar-h-right {
                        top: 72px;
                    }

                    .c-mb-button {
                        border-radius: 4px;

                        .rg-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 18px;
                            height: 18px;
                            font-size: 18px;
                            color: #646A73;
                        }
                    }

                    div[title='全屏/退出全屏'] {
                        display: none;
                    }
                }
            }
        }

        .tipsCard {
            position: absolute;
            background-color: transparent;
            border: 1px solid #dee0e3;
            border-radius: 8px;
            box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
            right: 16px;
            bottom: 16px;
            width: 300px;
            // min-height: 180px;
            z-index: 100;
            background: #FFFFFF;
            padding: 20px;
            max-height: 50%;
            display: flex;
            overflow: hidden;

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            .content {
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: column;
                width: 100%;
                .name.disabled{
                    cursor: auto;  
                    &:hover{
                        color: #0B2053;
                    }
                }
                .name-wrap{
                    display: inline-flex;
                    .name {
                        cursor: pointer;
                        display: inline-block;
                        font-family: PingFang SC;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 0px;
                        color: #0B2053;
                        
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        // width: 100%;
                        // flex-shrink: 0;
                        &:hover{
                            color: #3272FE;
                        }
                    }
                }

                .tagList {
                    margin-top: 6px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar-track {
                        background: transparent;
                    }
                    :deep() {
                        .identity-tag-lists-wrapper {
                            margin-top: 0;
                            .list-item{
                                margin-top: 6px;
                                margin-bottom: 0px;
                                margin-right: 6px;
                                .item-wrapper{
                                    height: 24px;
                                }
                                .iconfont{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

            }
        }

    }
}
</style>