<template>
    <div class="investGraph-index">
        <InvestGraph v-if="!state.isFullScreen && !graphLoading" :isFullScreen="state.isFullScreen"
            :graphNodes="state.graphNodes" :graphLines="state.graphLines" :rootId="state.rootId"
            :tooltipInfo="state.tooltipInfo" :floatNum="state.floatNum" :disableZoom="true"
            @toggleFullScreen="toggleFullScreen" />
        <!-- 投资脉络全屏 -->
        <a-modal v-model:visible="state.isFullScreen" fullscreen hide-cancel hide-title :closable="false"
            :footer="false" class="full-screen-graph-modal">
            <div v-if="state.isFullScreen" class="full-screen-graph">
                <InvestGraph :isFullScreen="state.isFullScreen" :graphNodes="state.graphNodes"
                    :graphLines="state.graphLines" :rootId="state.rootId" :tooltipInfo="state.tooltipInfo"
                    :floatNum="state.floatNum" @toggleFullScreen="toggleFullScreen" />
            </div>
        </a-modal>
    </div>
</template>

<script lang="ts" setup>
import { reactive, watch } from 'vue';
import { useRequest } from 'vue-request';
import uuid from '@/utils/uuid'
import { flatTreeOptions } from '@/views/afterInv/holdInfo/fields.js'

/**
 * APIs
 */
import { holdInfoVeinsByName } from "@/services/holdInfo/index.js"

/**
 * Components
 */
import InvestGraph from '@/views/components/graph/index.vue'

/**
 * Resources
 */

/**
 * Types
 */
type State = {
    isFullScreen: boolean,
    rootId: string,
    graphNodes: [],
    graphLines: [],
    chartData: [],
    tooltipInfo: null,
    floatNum: number,
};

/**
 * Constants
 */

/**
 * Props
 */
const props = defineProps({
    projectName: {
        type: String,
        default: '',
    },
})

/**
 * Hooks
 */
 const emit = defineEmits(['isShowView'])

/**
 * States
 */
const state: State = reactive({
    isFullScreen: false,
    rootId: '0',
    graphNodes: [],
    graphLines: [],
    chartData: [],
    tooltipInfo: null,
    floatNum: 2,
});
const formatInfoData = (array, parentId) => {
    array?.forEach((item) => {
        item.holdRatio = (item.holdRatio * 100)?.toFixed(state.floatNum) ?? item.holdRatio
        item.lineHoldRatio = (item.lineHoldRatio * 100)?.toFixed(state.floatNum) ?? item.lineHoldRatio
        item.text = item.name
        item.data = item
        item.originId = item.id
        item.id = item.id != 0 ? uuid() : item.id // 保证 id 唯一性
        if (item.parentId != -1) {
            state.graphLines.push({
                from: item.id,
                to: parentId,
                // text: item.holdRatio,
                // useTextPath: false,
            })
        }
        if (item.child?.length) {
            formatInfoData(item.child, item.id)
        }
    });
}

/**
 * Requests
 */
const { loading: graphLoading, run: getGraphData } = useRequest(holdInfoVeinsByName, {
    manual: true,
    //     data:{ companyList: [state.companyInfo.fullName]} ,
    onSuccess: (res) => {
        console.log(res)
        let isShowView=(res.veins?.child?.length>0)? true:false
        emit('isShowView', isShowView)
        state.rootId = res.veins?.id
        state.tooltipInfo = res.window
        formatInfoData([res.veins])
        state.graphNodes = flatTreeOptions([res.veins])
        state.graphLoading = false
       
    }
})

/**
 * Events
 */
const toggleFullScreen = () => {
    state.isFullScreen = !state.isFullScreen
}
/**
 * Effects
 */
watch(
    () => props.projectName,
    () => {
        getGraphData({ name: props.projectName })
    },
    {
        immediate: true,
    }
)

/**
 * Lifecycles
 */
</script>

<style scoped lang="scss">
.investGraph-index {
    /* ... */
    height: 100%;
}
</style>