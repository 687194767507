<template>
  <Card class="profile" :title="i18n_t('plat_c.allTabs.publicInfo.gongsigaikuang')" :is-loading="isLoading">
    <div class="profile-description" v-if="description">
      {{ description }}
    </div>

    <div class="profile-container">
      <template v-if="items.length">
        <template v-for="item in items" :key="item.key">
          <div class="item" v-if="item.visible ? item.visible(companyInfo) : true">
            <label class="item__label">{{ item.label }}</label>
            <div
              class="item__content"
              :class="{ 'item__content--link': item.isLink && companyInfo[item.key] }"
              @click="() => item.isLink && handleOpenLink(companyInfo[item.key])"
            >
              <span v-if="item.isHtml && item.value" :innerHTML="item.value(companyInfo)"></span>
              <span v-else>
                {{ (item.value ? item.value(companyInfo) : companyInfo?.[item.key]) || '-' }}
              </span>
            </div>
          </div>
        </template>
      </template>
      <EmptyContent :message="i18n_t('plat_c.allTabs.com.zanwushuju')" v-else />
    </div>
  </Card>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
const { t: i18n_t } = useI18n()
import { formatCurrency, mapCurrency } from '@/utils/currency'
import { computed, PropType } from 'vue'
import EmptyContent from '@/components/emptyContent.vue'
import { formatNumber } from '../../transaction/utils'
import Card from './card.vue'

type Field = {
  label: string
  key: string
  isHtml?: boolean
  value?: (v: any) => string
  isLink?: boolean
  visible?: (v: any) => boolean
}

const props = defineProps({
  companyInfo: {
    type: Object as PropType<Record<string, unknown>>,
    default: () => ({}),
  },
  isLoading: Boolean as PropType<boolean>,
  fields: {
    type: Array as PropType<Array<Field>>,
    default: () => [],
  },
})

const description = computed(() => {
  return props.companyInfo?.description || props.companyInfo.businessScope || ''
})

const defaultItems: Field[] = [
  {
    label: i18n_t('plat_c.allTabs.publicInfo.faren'),
    key: 'legalRepresentName',
  },
  {
    label: i18n_t('plat_c.allTabs.publicInfo.chenglishijian'),
    key: 'establishDate',
  },
  {
    label: i18n_t('plat_c.allTabs.investmentPlan.zuceziben'),
    key: 'tycRegCapital',
    isHtml: true,
    // value: (v) => {
    //   const value = v?.regCapital?.value ? formatCurrency(v?.regCapital?.value, true) : '-'
    //   return `${v?.regCapital?.currency || ''}<span style="padding-left: 4px">${value}</span>`
    // },
  },
  {
    label: '实缴注册资本',
    key: 'actualCapital',
    isHtml: true,
  },
  {
    label: '统一社会信用代码',
    key: 'creditCode',
  },
  {
    label: i18n_t('plat_c.allTabs.publicInfo.gongsi_G_W'),
    key: 'website',
    isLink: true,
  },
  {
    label: i18n_t('plat_c.allTabs.publicInfo.zuce_D_Z'),
    key: 'regLocation',
  },
  
]

const items = computed(() => {
  return props.fields.length ? props.fields : defaultItems
})

const handleOpenLink = (url?: string) => {
  if (url) {
    window.open(url)
  }
}
</script>

<style lang="scss">
.profile {
  .profile-description {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #282d33;
    +.profile-container {
      padding-top: 12px;
    }
  }
  .profile-container {
    // padding: 12px 0;
    padding-bottom: 12px;
    .item {
      margin-top: 6px;
      display: flex;
      flex-direction: row;
      &:first-child {
        margin-top: 0;
      }
      &__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666b71;
        flex-shrink: 0;
        width: 114px;
      }
      &__content {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313b45;
        padding-left: 24px;
        word-break: break-all;
        &--link {
          color: #3272FE;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
