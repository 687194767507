import { nextTick, unref } from 'vue'
import * as echarts from 'echarts'
import moment from 'moment'

export function useEcharts(elRef) {
  let chartInstance = null
  function initCharts() {
    const el = unref(elRef)
    chartInstance = echarts.init(el)
    if (!el || !unref(el)) {
      return
    }
  }
  function setOptions(option) {
    nextTick(() => {
      chartInstance.setOption(option)
    })
  }
  initCharts()
  return {
    echarts,
    chartInstance,
    setOptions,
  }
}

export function dateTools(flag, time) {
  if (flag == '1') {
    return moment(time).format('YYYY/MM')
  } else if (flag == '2') {
    let mon = new Date(time).getMonth() + 1
    let year = new Date(time).getFullYear()
    if (mon < 4) {
      return year + 'Q1'
    } else if (mon >= 4 && mon < 7) {
      return year + 'Q2'
    } else if (mon >= 7 && mon < 10) {
      return year + 'Q3'
    } else if (mon >= 10 && mon <= 12) {
      return year + 'Q4'
    }
  } else {
    return moment(time).format('YYYY')
  }
}
