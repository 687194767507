import {ajax} from '@/utils/request';

// 批量删除投资者
export const batchDeleteLpInvestor = async (code, ids) => {
  return await ajax({
    url: `/${code}/batchDelete`,
    method: 'post',
    data: ids,
  })
}

// 删除投资者
export async function lpInvestorDelete(extra) {
  const result = await ajax({
    method: 'get',
    url: `/lpInvestor/delete?id=${extra}`,
    ...extra,
  });
  return result;
}

// 更新投资者
export async function lpInvestorUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/lpInvestor/update',
    ...extra,
  });
  return result;
}

// 获取投资者投资基金列表
export async function getLpInvestorFundList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/lpInvestor/queryFundList',
    ...extra,
  });
  return result;
}

// 获取投资者基金项目table信息
export async function getLpInvestorFundTable(extra) {
  const result = await ajax({
    method: 'post',
    url: '/project/invest/query',
    ...extra,
  });
  return result;
}

// 导出投资者基金项目table信息
export async function exportLpInvestorFundTable(extra) {
  const result = await ajax({
    method: 'post',
    url: '/project/invest/export',
    ...extra,
  });
  return result;
}

// 删除投资者
export async function delLpInvestor(extra) {
  const result = await ajax({
    method: 'get',
    url: '/lpInvestor/delete',
    ...extra,
  });
  return result;
}
// 获取对象列表分页数据
export async function queryObjectPubPage(extra, signal) {
  const result = await ajax({
    method: 'post',
    url: '/objectPubData/queryObjectPubPage',
    ...extra,
    signal: signal,
  });
  return result;
}
// 新增记录
export async function createObjectPubData(extra) {
  const result = await ajax({
    method: 'post',
    url: '/objectPubData/createObjectPubData',
    ...extra,
  });
  return result;
}
// 删除记录
export async function deleteObjectPubData(extra) {
  const result = await ajax({
    method: 'post',
    url: '/objectPubData/deleteObjectPubData',
    ...extra,
  });
  return result;
}
// 批量删除记录
export async function batchDeleteObjectPubData(extra) {
  const result = await ajax({
    method: 'post',
    url: '/objectPubData/batchDeleteObjectPubData ',
    ...extra,
  });
  return result;
}
// 基本信息导出
export async function objectPubDataexport(extra) {
  const result = await ajax({
    method: 'post',
    url: '/objectPubData/pubInfo/export',
    ...extra,
  });
  return result;
}
