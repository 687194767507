import * as FormItems from './formComponents'

export type Filters = typeof FormItems
type FieldTypeFormMap = typeof FIELD_TYPE_FORM_MAP
export type IField = {
  type: keyof FieldTypeFormMap
  label: string
  columnName: string
  fieldMetas?: Record<string, unknown>
  visible?: boolean
  fixed?: boolean
  businessCode: string
  componentType?: string
  isPreset?: boolean
  config?: unknown
  fieldName: string
  isNeedScreen?: Boolean
  id?: String,
  fieldsNames?: Record<string, unknown>
}

export const typeOperatorMap: Record<keyof typeof FILTER_TYPE_MAP, 'IN' | 'EQ' | 'BETWEEN' | 'CONTAINS' | 'LIKE' | 'CONTAIN_ANY'> = {
  varchar: 'LIKE',
  text: 'LIKE',
  date: 'BETWEEN',
  linkageArea: 'CONTAINS',
  linkageCategory: 'CONTAINS',
  select: 'IN',
  multiSelect: 'CONTAIN_ANY',
  employee: 'CONTAINS',
  currency: 'BETWEEN',
  percentage: 'BETWEEN',
  number: 'BETWEEN',
  // Tag: 'IN',
  // Text: 'LIKE',
  // SingleSelect: 'EQ',
  // MultiSelect: 'EQ',
  // NumberRange: 'BETWEEN',
  // DateRange: 'BETWEEN',
  // Staff: 'CONTAINS',
  // MultipleCascader: 'CONTAINS',
  // SingleCascader: 'CONTAINS',
  // Cascader: 'CONTAINS',
}

export const FIELD_TYPE_FORM_MAP: Record<string, keyof Filters> = {
  Tag: 'Tag',
  MultiSelect: 'Select',
  SingleSelect: 'Select',
  Text: 'Text',
  NumberRange: 'NumberRange',
  DateRange: 'DateRange',
  Staff: 'Staff',
  SingleCascader: 'Cascader',
  MultipleCascader: 'Cascader',
  YearDate: 'YearDate',
  ProjectSelect: 'ProjectSelect',
}

export const FILTER_TYPE_MAP: Record<string, keyof typeof FIELD_TYPE_FORM_MAP> = {
  varchar: 'Text',
  text: 'Text',
  date: 'DateRange',
  linkageArea: 'MultipleCascader',
  linkageCategory: 'MultipleCascader',
  select: 'MultiSelect',
  multiSelect: 'MultiSelect',
  employee: 'Staff',
  currency: 'NumberRange',
  percentage: 'NumberRange',
  number: 'NumberRange',
  YearDate: 'YearDate',
}
