import { NewFileds } from '@/views/beforeInv/edit/basicInfo/fieldInterface.js'

export const transformType = async(data = [], code, libraryParams) => {
    if(!data) return []
    let findInterface = new NewFileds()
    let allUser =await findInterface.getAllUsers
    let categoryList = libraryParams?.categoryList ? libraryParams?.categoryList : await findInterface.findCategoryList
    let countryList = libraryParams?.countryList ? libraryParams?.countryList : await findInterface.findCountryList
    // let newArr = data.filter(ele=> ele?.fieldName !== "companyFullName" || ele?.fieldName !== "fullName")
    let newArr=[...data]
    newArr =newArr.map((ele, index) => {
        ele.required = ele.isRequired
        ele.key = ele.fieldName
        // 判断字段类型
        switch (ele.type) {
            case 'multiSelect':
                ele.fieldType = 'tagselect'
                selectOption(ele)
                break;
            case 'varchar':
                ele.fieldType = 'text'
                break;
            case 'text':
                ele.fieldType = 'textarea'
                break;
            case 'select':
                ele.fieldType = 'tagselect'
                selectOption(ele)
                break;
            case 'number':
                ele.fieldType = 'number'
                break;
            case 'employee':
                ele.fieldType = 'employee'
                ele.options = allUser
                break;
            case 'date':
                ele.fieldType = 'date'
                break;
            case 'linkageCategory':
                ele.fieldType = 'industryCascade'
                ele.options = categoryList
                break;
            case 'linkageArea':
                ele.fieldType = 'area'
                ele.options = countryList
                break;
            case 'currency':
                ele.fieldType = 'number'
                break;
            case 'boolean':
                ele.fieldType = 'select'
                break;
            case 'percentage':
                ele.fieldType = 'number'
                break;
            case 'switch':
                ele.fieldType = 'switch'
                break;
            case 'combinationDate':
                ele.fieldType = 'combinationDate'
                break;
            case 'searchMultiselect':
                ele.fieldType = 'searchMultiselect'
                break;
            case 'file':
                ele.fieldType = 'file'
                break;
            case 'externalData':
                ele.fieldType = 'externalData'
                break;
            
            default:
                ele.fieldType = ''
        }
        ele.validate = [
            {
                required: ele.isRequired,
                message: '必填',
            },
        ]
        return {
            ...ele
        }
    })
    return addClassPosition(newArr)
}

//添加类名，区分左右，整行等
export function addClassPosition(newArr){
    let positionIndex = 0;
    newArr.forEach((ele, index)=>{
        ele.class = ''
        //增加左右区分
        if('textarea' == ele.fieldType){    //整行则置左，且重置计数
            ele.class = 'currline left';
            positionIndex = 0;
        } else if (ele.label === '管理规模' || ele.label === '投资方向' || ele.label === '退出周期') {
            // 投资者基本信息处理
            ele.class = 'currline left';
            positionIndex = 0;
        }else{
            ele.class = ((positionIndex++) % 2) == 0 ? 'left' : 'right';
        }
        //最后2个特殊处理
        if(index == newArr.length - 1){
            if('textarea' == ele.fieldType){   //如果最后一个是整行
                ele.class += ' lastline ';
            }else{
                if(ele.class == 'right'){    //如果最后一个居右侧，则前一个也需要加
                    newArr[index -1] && (newArr[index -1].class += ' lastline ');
                }
                ele.class += ' lastline '  
            }
        }
    });
    return newArr;
}

const selectOption = (ele) => {
    if (ele.config) {
        ele.options = []
        ele.options = ele.config ? JSON.parse(ele.config)?.selectOptions : ele.config
    }
    return ele
}
