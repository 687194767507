import {
  FILTER_TYPE_MAP,
  FIELD_TYPE_FORM_MAP
} from '@/components/Filters/filters'
import {
  TreeOptions,
} from '@/composables/useTable'
import { fetchTotalTags } from '@/views/beforeInv/listFilter/services.ts'

import _ from 'lodash'

const IPOStateOptions = [
  {
    label: '未上市',
    value: '1',
    colour: '#EFF0F1',
  },
  {
    label: '已上市',
    value: '2',
    colour: '#DDEAFF',
  },
  {
    label: '已退市',
    value: '3',
    colour: '#FFD2D3',
  }
]
const holdTypeOptions = [
  {
    label: '直投',
    value: 1,
    colour: '#E0E9FF',
  },
  {
    label: '间接持有',
    value: 2,
    colour: '#FAEDC2',
  }
]
export const spanHeaders = {
  // 这一列是根据公司名称合并
  fistLevel : ['companyFullName', 'ipoState', 'totalTag', 'industry', 'area', 'totalHoldRatio', 'holdType', 'totalFirstInvestDate'],
  secondLevel: ['projectName', 'companyValuation'],
  thirdLevel: ['investPath', 'firstInvestDate', 'investAmount', 'shareholdingRatio','holdValue','firstExitDate','realizedValue','irr', 'moc', 'dpi']
}

const headers = [
  {
    label: '公司名称',
    columnName: 'companyFullName',
    isFilter: true,
    columnKey: 'companyFullName',
    type: 'text',
    fixed: 'left'
  },
  {
    label: '上市状态',
    columnName: 'ipoState',
    isFilter: true,
    type: 'select',
    isTag: true,
  },
  {
    label: '标签',
    columnName: 'totalTag',
    isFilter: true,
    type: 'multiSelect',
    isTag: true,
  },
  {
    label: '行业',
    columnName: 'industry',
    isFilter: true,
    type: 'linkageCategory',
    isTag: false,
  },
  {
    label: '地区',
    columnName: 'area',
    isFilter: true,
    type: 'linkageArea',
    isTag: false,
  },
  {
    label: '总持股',
    columnName: 'totalHoldRatio',
    filterKey: 'totalHoldRatio',
    isFilter: true,
    type: "percentage",
  },
  {
    label: '持有类型',
    columnName: 'holdType',
    valueKey: 'holdType',  
    isFilter: true,
    type: 'multiSelect',
    isTag: true,
  },
  {
    label: '首次投资日期(总)',
    columnName: 'totalFirstInvestDate',
    isFilter: true,
    type: "date",
  },
  {
    label: '项目名称',
    columnName: 'projectName',
    type: 'text',
    columnKey: 'projectName',
    merge: true,
  },
  {
    label: '公司估值',
    columnName: 'companyValuation',
    type: 'currency',
    merge: true,
  },
  {
    label: '投资路径',
    columnName: 'investPath',
    type: 'text',
    subMerge: true,
    merge: true,
  },
  {
    label: '首次投资日期',
    columnName: 'firstInvestDate',
    type: "date",
    subMerge: true,
    merge: true,
  },
  {
    label: '投资金额',
    columnName: 'investAmount',
    type: 'currency',
    subMerge: true,
    merge: true,
  },
  {
    label: '持股比例',
    columnName: 'shareholdingRatio',
    isRatio: true,
    type: "percentage",
    subMerge: true,
    merge: true,
  },
  {
    label: '持有价值',
    columnName: 'holdValue',
    type: "currency",
    subMerge: true,
    merge: true,
  },
  {
    label: '首次退出日期',
    columnName: 'firstExitDate',
    type: "date",
    subMerge: true,
    merge: true,
  },
  {
    label: '已实现价值',
    columnName: 'realizedValue',
    type: "currency",
    subMerge: true,
    merge: true,
  },
  {
    label: 'Gross IRR',
    columnName: 'irr',
    isRatio: true,
    type: "percentage",
    subMerge: true,
    merge: true,
  },
  {
    label: 'MOC',
    columnName: 'moc',
    type: "number",
    subMerge: true,
    merge: true,
  },
  {
    label: 'DPI',
    columnName: 'dpi',
    type: "number",
    subMerge: true,
    merge: true,
  },
]

const toLine = (name) => {
  return name.replace(/([A-Z])/g,"_$1").toLowerCase();
}

export const handleHeardArr = async() => {
  let promises = []
  let treeOptionsInstance;
  let totalTagRes;
  promises.push(
    new Promise(async (resolve, reject) => {
      treeOptionsInstance = await TreeOptions.prototype.getInstance()
      resolve(true)
    }),
    new Promise(async (resolve, reject) => {
      totalTagRes = await fetchTotalTags()
      resolve(true)
    }),

  )
  return Promise.all(promises).then((res) => {
    let totalTagOptions = []
    if(totalTagRes) {
      const  { config } = totalTagRes;
      if(config && typeof config === 'string' && config.startsWith('{')) {
        let jsonValue = JSON.parse(config)
        totalTagOptions = (jsonValue?.selectOptions ?? []).map((item) => {
          item.subValue = item.value
          item.value = item.label
          return item;
        })
      }
    }
    let allFields = headers.map((ele, index) => {
      ele.id = index + 1
      ele.showHideen = true
      ele.fieldName = ele.columnName
      // ele.columnName = toLine(ele.columnName)

      ele.operator = 'EQ'
      ele.fieldValueType = ele.type
      ele['componentType'] = ele.fieldName == 'projectId' ? 'ProjectSelect' : FILTER_TYPE_MAP[ele.type]
      if (ele.type === 'currency' || ele.type === 'number') {
        ele.fieldMetas = {
            multiple: true,
            operator: 'BETWEEN',
        }
      } else if (ele.type === 'select') {
        if(ele.columnName == 'ipoState') {
          
          ele.fieldMetas = {
            options: IPOStateOptions,
            multiple: false,
            operator: 'BETWEEN'
          }
        } 
      } else if(ele.type === 'multiSelect') {
        if(ele.columnName == 'totalTag') {
          ele.fieldMetas = {
            options: totalTagOptions,
            multiple: true,
            operator: 'BETWEEN'
          }
        }else if(ele.columnName == 'holdType') {
          ele.fieldMetas = {
            options: holdTypeOptions,
            multiple: true,
            operator: 'BETWEEN'
          }
        } 
      } else if(ele.columnName == 'industry') {
        ele.fieldMetas = {
          options: treeOptionsInstance.categoryOptions.value,
          multiple: true,
          operator: 'BETWEEN'
        }
      } else if(ele.columnName == 'area') {
        ele.fieldMetas = {
          options: treeOptionsInstance.areaOptions.value,
          multiple: true,
          operator: 'BETWEEN'
        }
      }
      return {
        ...ele
      }
    })
    return allFields
  })

  
}


export const flatTreeOptions = (opts) => {
  return opts.reduce((rs, opt) => {
    const children = opt.child || opt.children
    const childrenOpts = children?.length ? flatTreeOptions(children) : []

    opt.child = []
    opt.children = []
    return [...rs, opt, ...childrenOpts]
  }, [])
}