<template>
  <div class="empty-container">
    <a-empty :description="infotip">
      <template #image>
        <img :src="EmptyPng" alt="" />
      </template>
    </a-empty>

    <a-button v-if="!isReadonly" type="primary" size="small" @click="handleOpenDialog">{{btnText}}</a-button>

    <ModifyMainBodyDialog
      v-model:visible="visible"
      :bCode="bCode"
      :data-id="dataId"
      :assetTypeId="assetTypeId"
      @queryInfo="handleQueryInfo"
      @refreshTable="handleRefreshTable"
    />
    <AddSubFunds v-if="subfunVis" width="640px" title="关联投资机构" v-model:visible="subfunVis" @handleOk="handleOk">
      <AddOrg :bCode="bCode" :dataId="dataId" ref="addValue" v-if="subfunVis"></AddOrg>
    </AddSubFunds>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { updateProject } from '@/services/investment/createProject.js'
import { computed, PropType, ref } from 'vue'
import { Message } from '@arco-design/web-vue'
import { useStore } from 'vuex'
import ModifyMainBodyDialog from './modifyMainBodyDialog.vue'
import EmptyPng from '@/assets/img/emptyCom.png'
import AddSubFunds from '@/views/test/modelMemo.vue'
import AddOrg from './addOrg.vue'

const { t: i18n_t } = useI18n()
const store = useStore()
const emit = defineEmits(['queryInfo', 'refreshTable'])
const props = defineProps({
  bCode: String as PropType<string>,
  dataId: String as PropType<string>,
  mySelfPermisson: {
    type: Array as PropType<{ haveEdit: boolean }[]>,
    default: () => [],
  },
  assetTypeId: String as PropType<string>,
})
const isReadonly = computed(() => !store.state.isEdit && !props.mySelfPermisson[0]?.haveEdit)
const addValue = ref(null)
const visible = ref(false)
const subfunVis = ref(false)
const infotip = computed(()=>{
  if(['beforeProject','investedCompany', 'lpInvestor'].includes(props.bCode)){
    return '关联主体公司，获取新闻、融资历史及竞品等公开信息'
  }else if(['afterFof','beforeFof'].includes(props.bCode)){
    return '关联投资机构,获取投资案例等公开信息'
  }
})

const btnText = computed(()=>{
  if(['beforeProject','investedCompany'].includes(props.bCode)){
    return '关联主体公司'
  } else if (props.bCode === 'lpInvestor') {
    return '关联主体全称'
  }else{
    return '关联投资机构'
  }
})

const handleOk = async()=>{
  if(!addValue.value.allValue.fundFullName){
    return Message.warning('投资机构名称不能为空')
  }
  if(!addValue.value.allValue.orgId){
    return Message.warning('请选择投资机构')
  }
  let res =await updateProject({data:addValue.value.allValue},props.bCode,)
  subfunVis.value = false
  emit('queryInfo')
  emit('refreshTable')
}

const handleOpenDialog = () => {
  // subfunVis.value = true
  if(['beforeProject','investedCompany', 'lpInvestor'].includes(props.bCode)){
    visible.value = true
  }else{
    subfunVis.value = true
  }
}
const handleQueryInfo = () => {
  emit('queryInfo')
}
const handleRefreshTable = () => {
  emit('refreshTable')
}
</script>

<style lang="scss" scoped>
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  :deep(.arco-empty) {
    margin-bottom: 12px;
    .arco-empty-image {
      height: 60px;
      margin-bottom: 4px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .arco-empty-description {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666b71;
    }
  }
}
</style>
