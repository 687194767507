<template>
    <a-drawer v-model:visible="drawerRelationVisible" :header="false" :footer="false" :mask-closable=false
        :esc-to-close="false" unmount-on-close width="100%" class="relation-drawer" placement="bottom"
        @close=" emit('close')">
        <a-spin :loading="loading"  style="height: 100%;">
            <relation  
            v-if="!loading"
             :info="propsInfo"
             :bCode="bCode"
             :dataId="dataId"
             @close="emit('close')" 
             @reset="hanldeReset"/>
        </a-spin>
    </a-drawer>
    
</template>

<script lang="ts" setup>
 import {ref ,watchEffect} from 'vue';
/**
 * APIs
 */
 
/**
 * Components
 */

import relation from './components/relation.vue'

/**
 * Resources
 */

/**
 * Types
 */
 

/**
 * Constants
 */
 const drawerRelationVisible = ref(true)
 const propsInfo=ref({
    name:''
 })
 const loading = ref(false)
/**
 * Props
 */
const props = defineProps({
    info: {
        type: Object,
        default: {},
    },
    bCode: {
        type: String,
        default: '',
    },
    dataId: {
        type: String,
        default: '',
    }
})

/**
 * Hooks
 */
 const emit = defineEmits(['close'])
/**
 * States
 */

/**
 * Requests
 */

 

/**
 * Events
 */
 const hanldeReset = (name:string) => {
    loading.value=true
    propsInfo.value.name = name
    setTimeout(()=>{
        loading.value=false
    },200)
}

/**
 * Effects
 */
 watchEffect(() => {
    propsInfo.value = props.info
})

</script>

 