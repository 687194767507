import { ajax } from '@/utils/request'

// 投资情况
export async function getHoldInfonList(data) {
  const result = await ajax({
    method: 'post',
    url: `/holdInfo/list`,
    data,
  })
  return result
}
// 脉络
export async function holdInfoVeinsByName(data) {
  const result = await ajax({
    method: 'post',
    url: `/holdInfo/veins`,
    data,
  })
  return result
}
// 详情
export async function holdInfoQueryByName(data) {
  const result = await ajax({
    method: 'post',
    url: `/holdInfo/query`,
    data,
  })
  return result
}
// 估值chat
export async function holdInfoChartQuery(extra) {
  const result = await ajax({
    method: 'post',
    url: `/holdInfo/valuationChart`,
    ...extra,
  })
  return result
}
// 台账列表
// holdValue/queryList
export async function fetchHoldInfoList(extra, signal) {
  const result = await ajax({
    method: 'post',
    url: `/holdValue/queryList`,
    ...extra,
    signal: signal
  },[],[],)
  return result
}
// 查询台账列表的视图信息
export async function fetchHoldInfoViewInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/holdValue/queryViewByUserId`,
    ...extra,
  })
  return result
}

// 更新台账列表的视图信息
export async function updateHoldInfoViewInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/holdValue/updateView`,
    ...extra,
  })
  return result
}
// 导出台账列表的视图信息
export async function exportHoldInfoViewInfo(extra) {
  const result = await ajax({
    method: 'post',
    url: `/holdValue/export?functionViewId=${extra.id}`,
  })
  return result
}
// 关系图
export async function queryRelationship(name) {
  const result = await ajax({
    method: 'get',
    url: `/relationship/query?name=${name}`,
  }, [], []);
  return result;
}