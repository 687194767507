<template>
  <div class="batch-add-content">
    <div class="batch-top-team-content">
      <div class="batch-top-team-add-content">
        <div class="batch-top-team-add-art" :style="{ height: teamAddHeight + 'px' }">
          <div style="display:flex">
            <div ref="searchInput" class="batch-team-add-t">
              <icon-search class="search-select-icon" />
              <a-select
                v-model="tmpPersonList"
                v-if="inited"
                multiple
                :placeholder="$t('plat_c.app_c.dialog_staff.sousuorenyuanbumen')"
                content-class="team-add-select"
                @change="handleSelectPerson"
                :loading="selectLoading"
                :bordered="false"
                :popup-visible="popupVisible"
                :popup-container="'#' + searchSelectBatch"
                :filter-option="false"
                @search="remoteMethod"
                @popup-visible-change="handleSelectPerson"
              >
                <a-option
                  v-for="(item, ii) in allUserList"
                  :key="ii"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.disabled"
                >
                  <div class="top-team-search-item">
                    <div class="top-team-search-img">
                      <img v-if="item.avatar" :src="item.avatar" :alt="item.name" />
                      <span v-else>
                        <span v-if="item.name">{{ item.name[0] }}</span>
                        <span v-else>{{ item.deptName[0] }}</span>
                      </span>
                    </div>
                    <span style="margin-right: 4px; flex-shrink: 0;">{{ item.name || item.deptName }}</span>
                    <span class="top-team-search-email">{{ item.email }}</span>
                  </div>
                </a-option>
                <template #empty>
                  <div class="top-team-search-empty">
                    <img class="blank-img" :src="svgEmpty" />
                    <small>{{$t('plat_c.app_c.empty_text.weizhaodaoneirong')}}</small>
                  </div>
                </template>
              </a-select>
              <div class="right-option">
                <div v-if="showFooter" class="team-search-permission">
                  <a-dropdown @select="triggerPermisson">
                    <span class="el-dropdown-link">
                      {{ permission[commandPermi] }}<i class="iconfont icon-icon_d_arrow" />
                    </span>
                    <template #content>
                      <a-doption value="2"> {{$t('plat_c.app_c.dialog_staff.kebianji')}} </a-doption>
                      <a-doption value="1"> {{$t('plat_c.app_c.dialog_staff.kechakan')}} </a-doption>
                    </template>
                  </a-dropdown>
                </div>
                <a-tooltip :content="$t('plat_c.app_c.dialog_staff.xuanzerenyuan')" position="bottom">
                  <div class="search-plus-icon" @click="addConTact"><i class="iconfont icon-icon_add icon-cus-color" /></div>
                </a-tooltip>
              </div>
            </div>
            <!-- <a-button v-if="!showFooter" :disabled="addDisabled" @click="creatAddPerson" class="addPerson">添加成员</a-button> -->
          </div>
          <small v-show="tipFlag" class="tips"
            >{{$t('plat_c.app_c.dialog_staff.tianjiachengyuan_tip')}}</small
          >
        </div>
        <div v-if="showFooter" class="top-team-add-foot">
          <a-button type="outline" size="small" @click="cancleAdd"> {{$t('plat_c.app_c.general_btn.quxiao')}} </a-button>
          <a-button
            type="primary"
            size="small"
            style="margin-left: 16px"
            :disabled="!addPersonList.length"
            @click="submitMember"
          >
          {{$t('plat_c.app_c.general_btn.queding')}}
          </a-button>
        </div>
      </div>
      <div
        :id="searchSelectBatch"
        class="batch-search-popo-cont"
        :style="{ top: searchSelectTop + 'px' }"
      ></div>
    </div>
  </div>
  <!-- 从组织架构添加成员 -->
  <a-modal v-model:visible="contactVisible" width="640px" class="add-contact-modal">
    <template #title>
      <div>{{$t('plat_c.app_c.dialog_staff.xuanzerenyuan')}}</div>
    </template>
    <group ref="group" :teamPersons="selectTeamList" @disableSubmit="disableSubmit" />
    <template #footer>
      <div class="cus-contact-footer">
        <a-button type="outline" size="small" @click="addContactCansle">{{$t('plat_c.app_c.general_btn.quxiao')}}</a-button>
        <a-button type="primary" size="small" style="margin-left: 16px" :disabled="disabledAdd" @click="addContactSubmit"
          >{{$t('plat_c.app_c.general_btn.queding')}}</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<script>
import svgEmpty from '@/assets/img/zanwushuju.svg'
import group from './addFromGroup.vue'
import unique from '@/utils/unique.js';
import {
  findOrganizationByPinyin,
} from '@/services/investment/index.js'
import {findUsers} from '@/services/use.js'
import _ from 'lodash'

export default {
  components: {
    group,
  },
  props: {
    showFooter: {
      type: Boolean,
      default: true,
    },
    searchSelectBatch: {
      type: String,
      default: 'searchSelectBatch',
    },
    selectTeamList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      popupVisible: false,
      disabledAdd: true,
      addDisabled:true,
      inited: false,
      duty: {
        2: this.$t('plat_c.app_c.dialog_staff.kebianji'),
        1: this.$t('plat_c.app_c.dialog_staff.kechakan'),
      },
      permission: {
        2: this.$t('plat_c.app_c.dialog_staff.kebianji'),
        1: this.$t('plat_c.app_c.dialog_staff.kechakan'),
      },
      allUserList: [],
      teamAddHeight: 250,
      searchSelectTop: 48,
      commandPermi: 1,
      tmpPersonList: [],
      addPersonList: [],  //存储要加的人的对象
      selectLoading: false,
      svgEmpty: svgEmpty,

      // 从组织架构添加成员
      contactVisible: false,
    }
  },
  watch:{
    tmpPersonList:{
      deep: true,
      handler(nv, ov){
        if(_.isEqual(nv, ov)) return;

        this.updateAddPerson();
      }
    },
  },
  mounted() {
    this.inited = true
    // this.getUserAll()
  },
  computed: {
    tipFlag(){
      if(!this.showFooter){
        return false
      }else{
        return !this.allUserList.length
      }
    },
  },
  methods: {
    updateAddPerson(){
      this.addPersonList = this.allUserList.filter((ii)=>{
        return this.tmpPersonList.includes(ii.id)
      })
      
      // 【web】创建项目添加人员交互优化
      if (!this.showFooter) {
        this.creatAddPerson()
      }
    },
    disableSubmit(val) {
      this.disabledAdd = val
    },
    creatAddPerson(){
      this.addPersonList.filter(item => {
        item.permission = this.commandPermi
        item.memberType = 2
      })
      this.$emit("choosePerId",this.addPersonList)
      this.allUserList = []
      this.addPersonList = []
      this.tmpPersonList = []
    },
    async getUserAll(params){
      let res = await findUsers({data:{name:''}})
      if(!res) return
      this.allUserList = res
    },
    cancleAdd() {
      this.$emit('cancleBatchAdd')
    },
    handleSelectPerson(val) {
      this.popupVisible = false
      this.$nextTick(() => {
        const height = this.$refs.searchInput.clientHeight - 30
        this.teamAddHeight = 250 + height
        this.searchSelectTop = 48 + height
        this.addDisabled = this.addPersonList.length < 1;
      })
    },
    addContactCansle() {
      this.contactVisible = false
    },
    addContactSubmit() {
      const { group } = this.$refs
      group.selectContactList.filter(item => {
        if (!item.name) {
          item.name = item.deptName
        }
      })
      let selectContactList = group.selectContactList

      if (!selectContactList.length) {
        this.$message.warning(this.$t('plat_c.app_c.dialog_staff.xuanzerenyuan_tip'))
        return false
      }

      this.allUserList = [...this.allUserList, ...selectContactList]
      this.allUserList = unique(this.allUserList, 'id')
      
      selectContactList.forEach(item => {
        // 解决从组织选过来的人员没有删除icon
        item.disabled = false
        if(!this.tmpPersonList.includes(item.id)){
          this.tmpPersonList.push(item.id);
          // this.updateAddPerson();   //奇葩，手动修改tmpPersonList，没有触发watch
        }
      })
      this.updateAddPerson();   //奇葩，手动修改tmpPersonList，没有触发watch
      
      this.contactVisible = false

      // 添加成员按钮可用
      this.addDisabled = false
    },
    addConTact() {
      this.contactVisible = true
      const { group } = this.$refs
      group.reset()
    },
    triggerPermisson(val) {
      this.commandPermi = val
    },
    remoteMethod(query) {
      if (query !== '') {
        this.popupVisible = true
        this.selectLoading = true
        findOrganizationByPinyin(query, 0).then(res => {
          res.childUser?.filter(item => {
            item['type'] = 1
          })
          res.child?.filter(item => {
            item['type'] = 2
            item['name'] = item.deptName
          })
          const tempChild = res?.child || []
          const tempChildUser = res?.childUser || []
          this.allUserList = [...tempChildUser, ...tempChild]
          this.selectLoading = false

          // 解决搜索成员时会把已选择的成员隐藏的问题（本质是options选择项为空时，之前选择过得选项虽然回写了但是隐藏了，所以需要手动记录已选择项然后回写到options选项中）
          this.allUserList = [...this.addPersonList, ...this.allUserList]
          this.allUserList = unique(this.allUserList, 'id')

          // 已选取后不可再次选取
          this.selectTeamList.filter(item => {
            this.allUserList.filter(child => {
              if (item.id === child.id) {
                child['disabled'] = true
              }
            })
          })
        })
      } else {
        // 解决删除搜索文字时会把已选择的成员隐藏的问题（本质是options选择项为空时，之前选择过得选项虽然回写了但是隐藏了，所以需要手动记录已选择项然后回写到options选项中）
        this.allUserList = this.addPersonList
      }
    },
    submitMember() {
      this.$emit('addTeamPerson', this.addPersonList, this.commandPermi)
      // this.teamShowHandle()
    },
    teamShowHandle() {
      this.allUserList = []
      this.addPersonList = []
      this.tmpPersonList = []
      this.commandPermi = 2
    },
  },
}
</script>

<style lang="scss">
.batch-search-popo-cont {
  position: absolute;
  top: 48px;
  padding: 0 20px !important;
  box-sizing: border-box !important;
  width: 100%;
  .arco-spin {
    display: flex !important;
  }
  // 不显示复选框
  .arco-icon-hover {
    display: none; 
  }
  .arco-tag {
    .arco-icon-hover {
      display: inline-block; 
    }
  }
  .arco-checkbox {
    padding-left: 0;
    .arco-checkbox-label {
      margin-left: 0;
    }
  }
  // 不显示复选框 end
  .arco-dropdown {
    box-shadow: none;
  }
  .arco-trigger-popup {
    width: 100% !important;
    position: relative;
    left: 0 !important;
    top: 0 !important;
    z-index: 9 !important;
  }
}
.add-contact-modal {
  .arco-modal-body {
    padding: 20px 30px;
  }
  .cus-contact-footer {
    .arco-btn {
      min-width: 72px;
    }
  }
}
.top-team-search-empty {
  display: flex;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  small {
    font-size: 12px !important;
    color: #c1c4cb;
    margin-top: 6px !important;
    display: block;
  }
}
.top-team-search-email {
  display: block;
  width: 210px;
  font-size: 12px;
  color: #959aa2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 4px;
}
.arco-dropdown {
  // min-height: 206px;
  border: 0;
  padding: 0;
}
.batch-add-content {
  .arco-popover-popup-content {
    border: 0;
    line-height: 0;
    padding: 0;
    box-shadow: none;
  }
  .el-dropdown-link {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #666b71;
    .iconfont {
      color: #a7a8a8;
      margin-left: 2px;
      font-size: 12px;
    }
  }
}
.per-select {
  .arco-dropdown-list-wrapper {
    max-height: 216px;
  }
  .arco-dropdown-option {
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 18px;
    &:last-child {
      height: 38px;
      border-top: 1px solid #e4e5eb;
    }
    .text-t {
      color: #333b44;
      font-size: 14px;
      // font-weight: bold;
    }
    .text-s {
      color: #959aa2;
      font-size: 12px;
    }
  }
}
.batch-team-add-t {
  .arco-select-view-multiple {
    width: 308px !important;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0;
    .arco-select-view-suffix {
      display: none;
    }
  }
}
</style>
<style lang="scss" scoped>
.blank-img {
  width: 57px;
  height: 57px;
}
:deep(.arco-btn-secondary.arco-btn-disabled){
  background: #E4E5EC;
  color:#C0C4CC
}
.addPerson {
  width: 80px;
  height: 32px;
  background: #2676d0;
  line-height: 32px;
  margin-left: 9px;
  color: #fff;
  text-align: center;
  padding: 0;
}
.top-team-search-item {
  display: flex;
  align-items: center;
  .top-team-search-img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 100%;
    background: #99adff;
    color: #fff;
    font-size: 12px;
    margin-right: 8px;
    img {
      width: 100%;
      display: block;
      object-fit: contain;
    }
  }
}
.batch-top-team-add-art {
  // min-height: 250px;
  padding: 12px 20px;
  .tips {
    color: #959aa2;
    display: inline-block;
    margin-top: 16px;
  }
  .batch-team-add-t {
    display: flex;
    flex:1;
    align-items: center;
    border: 1px solid #e4e5eb;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background: #fff;
    .search-select-icon {
      width: 16px;
      color: #959aa2;
      margin: 0 6px;
    }
    .right-option {
      display: flex;
      align-items: center;
      .search-plus-icon {
        width: 30px;
        min-height: 30px;
        flex-shrink: 0;
        cursor: pointer;
        transition: all .2s;
        display: flex;
        justify-content: center;
        align-items: center;
        
        margin-left: 6px;
        position: relative;
        &::before {
          content: '';
          width: 30px;
          height: 300px;
          position: absolute;
          top: -150px;
          left: 0;
          border-left: 1px solid #E4E5EB;
        }
        &:hover {
          &::before {
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      .team-search-permission {
        flex-shrink: 0;
        cursor: pointer;
      }
    }
  }
}
.batch-add-content {
  padding: 0 !important;
  .top-team-add-foot {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e4e5eb;
    border-radius: 0 0 3px 3px;
    .arco-btn {
      min-width: 72px;
    }
  }

  .batch-top-team-content {
    width: 100%;
    // overflow: hidden;

    .top-team-list {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    }

    .batch-top-team-add-content {
      width: 100%;
      background: #fff;
      border-radius: 5px;
      // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    }

    .top-team-title {
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e4e5eb;
      padding: 0 16px 0 20px;

      .title-text {
        font-size: 16px;
        font-weight: bold;
        color: #333b44;
      }

      .add-person-icon {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 16px;
        color: #9699ac;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .top-team-ul {
      width: 100%;
      padding: 4px 0;
      max-height: 234px;
      overflow-y: auto;

      .top-team-li {
        padding: 0 20px;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #f9fafc;
        }

        .top-team-li-l {
          flex-shrink: 0;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 100%;
          background: #99adff;
          color: #fff;
          font-size: 14px;
          margin-right: 12px;

          img {
            width: 100%;
            display: block;
            object-fit: contain;
          }
        }

        .top-team-li-m {
          flex-grow: 1;

          .top-team-li-name {
            display: block;
            font-size: 14px;
            color: #333b44;
            font-weight: bold;
          }

          .top-team-li-email {
            display: block;
            width: 185px;
            font-size: 12px;
            color: #959aa2;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .top-team-li-r {
          flex-shrink: 0;
          padding-right: 18px;
          font-size: 12px;
          color: #666b71;
        }

        .top-team-li-rOption {
          width: 68px;
          height: 24px;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }

          .el-dropdown-selfdefine {
            font-size: 12px;
            color: #666b71;

            .el-icon--right {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
.top-team-icon-ul {
  display: flex;
  // max-width: 68px;
  // min-width: 28px;
  // height: 28px;
  margin-right: 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  .total-tip {
    line-height: 24px;
    font-weight: normal;
    display: inline-block;
    margin-right: 8px;
    color: #959aa2;
  }

  .top-team-icon-li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #99adff;
    overflow: hidden;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    margin-right: 4px;
    // position: absolute;
    &.more-person-icon {
      font-size: 12px;
      background: #f0f4fb;
      color: #333b44;
    }

    // &:nth-child(1) {
    //   left: 0;
    // }

    // &:nth-child(2) {
    //   left: 20px;
    // }

    // &:nth-child(3) {
    //   left: 40px;
    // }

    // &:nth-child(4) {
    //   left: 40px;
    // }

    img {
      width: 100%;
      display: block;
      object-fit: contain;
    }
  }
}
</style>
