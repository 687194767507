<template>
  <div class="FundPers">
    <header>相关机构管理人</header>
    <div class="content">
      <div class="cont-item" v-for="(item,index) in lists" :key="index">
        {{item.managerName}}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
const props = defineProps({
  dataInfo:{type:Object,default:()=>{ return [] }}
})
const lists = computed(()=>{
  return props.dataInfo
})

</script>

<style lang="scss" scoped>
.FundPers {
  padding: 12px 24px;
  border: 1px solid #edeff5;
  font-size: 14px;
  margin-bottom: 12px;
  header{
    font-weight: 500;
  }
  .cont-item{
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid #edeff5;
    color: #1F2329;
    &:last-child{
       border:none 
    }
  }
}
</style>
