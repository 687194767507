import { ajax } from '@/utils/request'

export const getPublicInfoByDataId = (type: number, dataId: string, assetTypeId: string) => {
  const url = `/insight/publicInfo/${type}/${dataId}/${assetTypeId}`

  return ajax({
    url,
    method: 'get',
  })
}
export const getPublicInfoByCompanyFullName = (data: any) => {
  const url = `/insight/searchByCompanyFullName`

  return ajax({
    url,
    method: 'post',
    data
  })
}

// 投资者公开信息
export const getLpPublicInfoByDataId = (type: number, dataId: string) => {
  const url = `/insight/lpInvestor/publicInfo/${type}/${dataId}`

  return ajax({
    url,
    method: 'get',
  })
}

export const getFofPublicInfo = (type: number, dataId: string, assetTypeId: string, year: string = '') => {
  const url = `/insight/fof/publicInfo/${type}/${dataId}/${assetTypeId}?year=${year}`

  return ajax({
    url,
    method: 'get',
  })
}

export const getPublicInfoByInsightId = (insightId: string) => {
  const url = `/insight/queryPublicByInsightId/${insightId}`

  return ajax({
    url,
    method: 'get',
  })
}
// 免登录
export const getPublicInfoByInsightIdLoginSkip = (insightId: string) => {
  const url = `/insight/queryPublicByInsightIdLoginSkip/${insightId}`
  return ajax({
    url,
    method: 'get',
  })
}

export const getCloudUrl = (type: string, dataId: string, assetTypeId: string) => {
  const url = `/insight/getCloudUrl/${type}/${dataId}/${assetTypeId}`

  return ajax({
    url,
    method: 'get',
  })
}

export const getCloudUrlByInsightId = (insightId: string) => {
  const url = `/insight/getCloudUrl/${insightId}`

  return ajax({
    url,
    method: 'get',
  })
}

export const getCloudUrlByOrgId = (orgId: string) => {
  const url = `/insight/getOrgCloudUrl/${orgId}`

  return ajax({
    url,
    method: 'get',
  })
}

export const fetchFundList = (data: {
  investId: string
  pageSize: number
  currentPage: number
  judgeFund?: number // 0: 最新投资, 1: 基金
}) => {
  const url = '/publicInfo/company/invest'

  return ajax({
    url,
    method: 'post',
    data,
  })
}

export const fetchInvestorPublicInfo = (insightId: string) => {
  const url = '/publicInfo/company/summary'

  return ajax({
    url,
    method: 'post',
    data: {
      investId: insightId,
    },
  })
}

