<template>
  <a-modal
    :width="`${modalWidth}px`"
    v-if="modalVisible"
    v-model:visible="modalVisible"
    class="show-qually-dialog"
    unmount-on-close
    :mask-closable="false"
    modal-class="show-qually-dialog"
    :footer="false"
  >
    <template #title>存在相同主体的项目</template>
    <div class="show-qually-content">
      <div class="top">"{{currName}}"主体存在以下项目,是否继续创建?</div>
      <ul class="content-list">
        <li class="item" v-for="(item,index) in quallyList" :key="index">
          <div :class="['left',item.logo? '':'substr-bg']">
           <img v-if="item.logo" class="showimg" :src="item.logo" alt="" />
           <span class="substr-name" v-else>{{ item.companyFullName?.substr(0, 1) || item.fundFullName?.substr(0, 1) || item.name?.substr(0, 1) }}</span>
          </div>
          <div class="right">
            <div class="tipgongsi">
              <autoTooltip className="projectName" :content="item.projectName || item.name"></autoTooltip>
              <template v-if="item.companyFullName || item.fundFullName || item.fullName">
                (<autoTooltip className="companyFullName" :content="item.companyFullName || item.fundFullName || item.fullName"></autoTooltip>)
              </template>
              <!-- （<span class="companyFullName">{{item.companyFullName}}</span> ） -->
            </div>
            <p class="person">负责人:{{item.projectLeaderName}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer-btns">
      <a-button type="outline" @click="() => (modalVisible = false)">{{
        $t('plat_c.allTabs.com.quxiao')
      }}</a-button>
      <a-button type="primary" @click="begin">继续</a-button>
    </div>
  </a-modal>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import autoTooltip from '@/components/AutoTooltip.vue'
const emit = defineEmits(['update:showequally','beginTo'])
const props = defineProps({
  showequally: Boolean,
  currName: String,
  quallyList: Array,
  modalWidth: {
    type: Number,
    default: 468,
  }
})


const modalVisible = ref(true)
const begin = ()=>{
  emit('beginTo')
}
onMounted(() => {})
watch(
  () => modalVisible.value,
  (v) => {
    if (!v) emit('update:showequally', v)
  },
)
</script>

<style lang="scss">
.show-qually-dialog {
  .arco-modal {
    max-height: 80vh;
  }
  .arco-modal-header{
    border-bottom: none;
  }
  .arco-modal-body {
    padding: 12px 24px 0 24px;
    overflow: hidden;
    height: 100%;
    max-height: calc(80vh - 70px);
  }
  .footer-btns {
    // position: absolute;
    // bottom: 0;
    display: flex;
    justify-content: end;
    padding: 12px 0;
    .arco-btn {
      width: 80px;
      margin-left: 12px;
      padding: 0 !important;
    }
    .disabled {
      background-color: #bbc0c3;
      cursor: not-allowed;
      &:hover {
        background-color: #bbc0c3 !important;
      }
      &:active {
        background-color: #bbc0c3;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.show-qually-content {
  overflow: auto;
  height: 100%;
  max-height: calc(80vh - 153px);
  .top{
    margin-bottom: 17px;
    color: #1F2329;
    font-size: 14px;
  }
  .content-list {
    .item {
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-bottom: 8px;
      .left {
        &.substr-bg{
          background: #96ACFF;
        }
        width: 32px;
        height: 32px;
        overflow: hidden;
        margin-right: 8px;
        border-radius: 8px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        .showimg {
          width: 100%;
          height: 100%;
        }
        .substr-name{
          font-size: 14px;
          font-weight: 400;
          color:#fff
        }
      }
      .right{
        flex: 1;
        .tipgongsi{
          display: flex;
          font-size: 14px;
          color: #1F2329;
          overflow: hidden; /**隐藏超出的内容**/
          word-break: break-all;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          max-width: calc(100% - 32px);
          :deep(.projectName){
            // flex: 1;
            max-width: 150px;
            margin-right: 6px;
          }
          :deep(.companyFullName){
            // flex: 1;
            max-width: 170px;
            margin-right: 6px;
          }
          .companyFullName{
            // flex: 1; 
            // max-width: calc(100% - 240px);
            // overflow: hidden; /**隐藏超出的内容**/
            // word-break: break-all;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
        }
        .person{
          font-size: 12px;
          color: #86909C;
        }
      }
    }
  }
}
</style>
