<template>
  <div class="OrganizationImage" ref="OrganizationImage">
    <header>主要投资领域</header>
    <div class="chart-view" ref="chartView" style="height: 256px"></div>
  </div>
</template>

<script setup>
import { useEcharts } from '@/hooks/useEcharts.js'
import { useResizeObserver } from '@vueuse/core'
import { ref, onMounted, computed, watch } from 'vue'
const props = defineProps({
  dataInfo: {
    type: Object,
    default: () => {
      return []
    },
  },
})
const chartView = ref(null)
const echarts= ref(null)
const OrganizationImage = ref(null)

const filterType = (lists, type) => {
  const resType = [] //获取所有类型 并做去重处理
  if (lists.length) {
    lists.map((item) => {
      resType.push(item[type])
    })
  }
  return resType
}
const filterList = (lists, type) => {
  let Type = filterType(lists, type)
  return Type.reduce(function (prev, next) {
    prev[next] = prev[next] + 1 || 1
    return prev
  }, {})
}
const setList = (lists, type, st, rt) => {
  let Obj = filterList(lists, type)
  let current = []
  for (let k in Obj) {
    let obj = {
      [st]: k,
      [rt]: Obj[k],
    }
    current.push(obj) //结果组装
  }
  return current
}

const filedsList = computed(() => {
  return setList(props.dataInfo, 'name', 'name', 'value')
})

const allFilterMap = computed(() => {
  let allLsit = []
  if (filedsList.value.length > 5) {
    allLsit = filedsList.value.slice(0, 5)
    let afterArr = filedsList.value.slice(4)
    let sum = afterArr.reduce((pre, cur) => {
      return pre + cur.value
    }, 0)
    allLsit.sort((a, b) => {
      return b.value - a.value
    })
    allLsit.push({
      name: '其他',
      value: sum,
    })
  } else {
    allLsit = filedsList.value
  }
  return allLsit
})
const init = () => {
  let { setOptions, chartInstance } = useEcharts(chartView)
  echarts.value = chartInstance
  setOptions({
    color: ['#F27629', '#0098FA', '#0CD9B5', '#3B72AD', '#FDCC00', '#D95041'],
    tooltip: {
      trigger: 'item',
      borderColor: 'rgba(84, 104, 128, 0.97)',
      backgroundColor: 'rgba(84, 104, 128, 0.97)',
      textStyle: {
        color: '#fff',
      },
      // formatter: this.dataList.showTitle
      //   ? '{b}:<br/>  金额:{c}<br/> 占比:{d}%'
      //   : '{b}:<br/> 数量:{c}<br/> 占比:{d}%',
    },
    series: [
      {
        name: '',
        label: {
          show: true,
          fontSize: 12,
          formatter: '{b}:{c}' + '起',
          color: '#333B44',
        },
        // hoverAnimation:false,
        labelLine: {
          show: true,
          smooth: true,
          length: 8,
          length2: 8,
          minTurnAngle: 160,
        },
        // emphasis: {
        //   itemStyle: {
        //     shadowBlur: 10,
        //     shadowOffsetX: 0,
        //     shadowColor: 'rgba(0, 0, 0, 0.5)'
        //   }
        // },
        // top: 28,
        type: 'pie',
        radius: [54, 90],
        // data: allFilterMap.value,
        data: props.dataInfo,
      },
    ],
  })
}
watch(
  () => allFilterMap.value,
  () => {
    init()
  },
  {
    deep: true,
  },
)
onMounted(() => {
  init()
  useResizeObserver(OrganizationImage.value, (entries) => {
    if(echarts.value){
      echarts.value.resize()
    }
  })
})
</script>

<style lang="scss" scoped>
.OrganizationImage {
  padding: 12px 24px;
  margin-bottom: 12px;
  border: 1px solid #edeff5;
  font-size: 14px;
  header {
    font-weight: 500;
    margin-bottom: 11px;
  }
}
</style>
