<template>
  <div class="top-team-list">
    <div class="top-team-title">
      <span class="title-text">
        {{$t('plat_c.app_c.dialog_add.xiangmuchengyuan')}} {{newList.length}}
      </span>
      <div class="right-flex">
        <a-switch @change="chartChange" v-model:model-value="checkValue" size="mini" style="margin-right: 6px;" />
        <span>{{$t('plat_c.app_c.dialog_add.tschuangjianqunliao')}}</span>
        <a-tooltip background-color="#fff" position="bottom" class="create-chat-tip">
          <!-- <img class="icbc" src="@/assets/img/file/help.svg" alt="" /> -->
          <i  class="icbc iconfont icon-icon_help"/>

          <template #content>
            <div>
              <p class="tip-text">创建包含所有项目成员的群聊，在群聊中将自动推送项目动态、重要进展等，后续也可以进入详情页点击「发起群聊」创建或进入群聊</p>
              <img class="img-svg" src="@/assets/img/gif/chat.gif" alt="群聊" style="width: 100%;">
            </div>
          </template>
        </a-tooltip>
      </div>
    </div>
    <ul v-if="newList.length" class="top-team-ul">
      <li
        v-for="(item, index) in newList"
        :key="index"
        class="top-team-li"
      >
        <div class="top-team-li-l">
          <img v-if="item.avatar" :src="item.avatar" :alt="item.name">
          <span v-else>
            <span v-if="item.name">{{ item.name[0] }}</span>
            <span v-else-if="item.deptName">{{ item.deptName[0] }}</span>
          </span>
        </div>
        <div class="top-team-li-m">
          <span class="top-team-li-name">{{ item.name || item.deptName }}</span>
          <span v-if="item.email" class="top-team-li-email">{{ item.email }}</span>
        </div>
        <div v-if="item.memberType === 1" class="top-team-li-r">
          {{$t('plat_c.app_c.dialog_add.fuzeren')}}
        </div>
        <div v-else class="top-team-li-rOption">
          <a-dropdown @select="e => handleCommand(e, item.id, index)" position="br" class="per-select">
            <span class="el-dropdown-link">
              {{ duty[item.permission] }}
              <i class="iconfont icon-icon_d_arrow" />
            </span>
            <template #content class="top-team-dropdown-menu">
              <a-doption value="0" :disabled="item.type === 2">
                <div class="text-t">{{$t('plat_c.app_c.dialog_add.fuzeren')}}</div>
                <small class="text-s">{{$t('plat_c.app_c.dialog_add.fuzeren_miaoshu')}}</small>
              </a-doption>
              <a-doption value="2" :disabled="item.permission * 1 === 2">
                <div class="text-t" :class="{active: item.permission * 1 === 2}">{{$t('plat_c.app_c.dialog_add.kebianji')}}</div>
                <small class="text-s" :class="{active: item.permission * 1 === 2}">{{$t('plat_c.app_c.dialog_add.kebianji_miaoshu')}}</small>
                <i v-if="item.permission * 1 === 2" class="iconfont icon-close-med selected-icon" />
              </a-doption>
              <a-doption value="1" :disabled="item.permission * 1 === 1">
                <div class="text-t" :class="{active: item.permission * 1 === 1}">{{$t('plat_c.app_c.dialog_add.kechakan')}}</div>
                <small class="text-s" :class="{active: item.permission * 1 === 1}">{{$t('plat_c.app_c.dialog_add.kechakan_miaoshu')}}</small>
                <i v-if="item.permission * 1 === 1" class="iconfont icon-close-med selected-icon" />
              </a-doption>
              <a-doption value="3">
                <div style="color: #EC333A">{{$t('plat_c.app_c.general_btn.yichu')}}</div>
              </a-doption>
            </template>
          </a-dropdown>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TeamList",
  props: {
    selectTeamList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  watch:{
    selectTeamList:{
      deep: true,
      immediate: true,
      handler(v){
        this.newList= v
      }
    }
  },
  data() {
    return {
      duty: {
        2: this.$t('plat_c.app_c.dialog_add.kebianji'),
        1: this.$t('plat_c.app_c.dialog_add.kechakan'),
        0: this.$t('plat_c.app_c.dialog_add.fuzeren'),
      },
      newList:[],
      checkValue:false,
    }
  },
  methods: {
    chartChange(val){
      this.$emit('chartChange',val )
    },
    handleCommand(com, id, index) {
      this.$emit('updatePermission', com, id, index)
      // if (com === '1' || com === '2') {
      //   this.$emit('updatePermission', com, id)
      // } else if (com === '3') {
      //   this.$emit('deletePerson', id, index)
      // } else if (com === '0') {
      //   this.$emit('setOwner', 2, id, index)
      // }
    },
  },
}
</script>

<style lang="scss">
.create-chat-tip {
  .arco-trigger-content {
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #DEE0E3;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
    .tip-text {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      color: #1F2329;
    }
    .img-svg {
      border: 1px solid #DBE0E3;
      border-radius: 6px;
      margin-top: 8px;
    }
  }
}
.per-select {
  min-width: 280px;
  border-radius: 3px;
  &.arrow {
    border: 1px solid #E4E5EB;
    box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0px;
      display: block;
      box-sizing: border-box;
      width: 8px;
      height: 8px;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: 1;
      border: 1px solid #E4E5EB;
      border-right: 0;
      border-bottom: 0;
      background: #fff;
      border-top-left-radius: 2px;
    }
  }
  .arco-dropdown-option-disabled {
    .text-t, .text-s {
      color: #C0C4CC !important;
    }
  }
  .text-t {
    color: #333B44;
    font-size: 14px;
    &.active {
      color: #3272FE !important;
    }
  }
  .text-s {
    color: #959AA4;
    font-size: 12px;
    &.active {
      color: #959AA4 !important;
    }
  }
  .selected-icon {
    color: #3272FE;
    font-size: 16px;
    position: absolute;
    top: 20px;
    right: 12px;
  }
}
</style>
<style lang="scss" scoped>
  .top-team-add-content {
    width: 100%;
    background: #fff;
    // box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .top-team-title {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid #e4e5eb;
    padding: 0 16px 0 20px;

    .title-text {
      font-size: 14px;
      font-weight: 400;
      color: #313B45;
      display: flex;
    }
    .right-flex {
      display: flex;
      align-items: center;
    }

    .icbc {
      font-size: 14px;
      margin-left: 4px;
    }

    .add-person-icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 16px;
      color: #9699ac;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .top-team-ul {
      width: 100%;
      padding: 4px 0;
      max-height: 234px;
      overflow-y: auto;

      .top-team-li {
        padding: 0 14px 0 20px;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #f9fafc;
        }

        .top-team-li-l {
          flex-shrink: 0;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border-radius: 100%;
          background: #99adff;
          color: #fff;
          font-size: 14px;
          margin-right: 12px;

          img {
            width: 100%;
            display: block;
            object-fit: contain;
          }
        }

        .top-team-li-m {
          flex-grow: 1;

          .top-team-li-name {
            display: block;
            font-size: 14px;
            color: #333b44;
            font-weight: bold;
            line-height: 20px;
          }

          .top-team-li-email {
            display: block;
            width: 185px;
            line-height: 18px;
            font-size: 12px;
            color: #959aa2;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .top-team-li-r {
          flex-shrink: 0;
          padding-right: 25px;
          font-size: 12px;
          color: #666B71;
        }

        .top-team-li-rOption {
          width: 68px;
          height: 24px;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-dropdown-link {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            color: #666B71;
            .iconfont {
              color: #a7a8a8;
              margin-left: 2px;
              transition: all .2s;
              font-size: 12px;
              &.route {
                transform: rotate(180deg);
              }
            }
          }
          &:hover {
            background: rgba(0, 0, 0, 0.05);
            .el-dropdown-link {
              color: #3272FE;
            }
            .iconfont {
              color: #3272FE;
            }
          }
          &:active,
          &:visited {
            color: #3272FE;
            background-color: #dce9fa;
          }

          .el-dropdown-selfdefine {
            font-size: 12px;
            color: #666b71;

            .el-icon--right {
              margin-left: 0;
            }
          }
        }
      }
    }
  .top-team-icon-ul {
    display: flex;
    // max-width: 68px;
    // min-width: 28px;
    // height: 28px;
    margin-right: 16px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    .total-tip {
      line-height: 24px;
      font-weight: normal;
      display: inline-block;
      margin-right: 8px;
      color: #959AA2;
    }

    .top-team-icon-li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background: #99adff;
      overflow: hidden;
      border-radius: 50%;
      font-size: 12px;
      color: #fff;
      margin-right: 4px;
      // position: absolute;
      &.more-person-icon {
        font-size: 12px;
        background: #F0F4FB;
        color: #333B44;
      }

      img {
        width: 100%;
        display: block;
        object-fit: contain;
      }
    }
  }
</style>