<template>
  <Empty
    v-if="isEmpty"
    :bCode="bCode"
    :data-id="dataId"
    :assetTypeId="infoObj.assetTypeId"
    :mySelfPermisson="mySelfPermisson"
    @queryInfo="handleQueryInfo"
    @refreshTable="handleRefreshTable"
  />
  <DetailInfo
    v-else
    ref="detailInfo"
    :from="from"
    :bCode="bCode"
    :appCode="appCode"
    :assetTypeId="infoObj.assetTypeId"
    :data-id="dataId"
    :insight-id="insightId"
    :orgId="orgId"
    :details-info="infoObj"
    :projectName="projectName"
    @getChatInfo="handleGetChatInfo"
    @queryInfo="handleQueryInfo"
    @refreshTable="handleRefreshTable"
  />
</template>

<script lang="ts" setup name="PublicInfos">
import { computed, onMounted, PropType, reactive, ref } from 'vue'
import Empty from './empty.vue'
import DetailInfo from './detailInfo.vue'

const props = defineProps({
  from: {
    type: String,
    default: 'detailTab'
  },
  projectName: String as PropType<string>,
  bCode: String as PropType<string>,
  appCode: String as PropType<string>,
  dataId: {
    type: String as PropType<string>,
    // required: true,
    default: '',
  },
  insightId: String as PropType<string>,
  infoObj: {
    type: Object as PropType<Record<string, unknown>>,
    required: true,
    default: () => ({}),
  },
  mySelfPermisson: {
    type: Array as PropType<{ haveEdit: boolean }[]>,
    default: () => [],
  },
})
const emit = defineEmits(['queryInfo', 'refreshTable', 'getChatInfo'])

const isEmpty = computed(() => {
  if (props.from === 'holdInfo') return false
  if(['beforeProject','investedCompany', 'lpInvestor'].includes(props.bCode)){
    return !props.infoObj.insightId 
  }else if(['afterFof','beforeFof'].includes(props.bCode)){
    return !props.infoObj.orgId 
  }
})
const orgId = computed(()=> props.infoObj.orgId )

const handleQueryInfo = () => {
  emit('queryInfo')
}
const handleRefreshTable = () => {
  emit('refreshTable')
}
const handleGetChatInfo = () => {
  emit('getChatInfo')
}
const detailInfo = ref()
const getChatInfoFromTop = () => {
  detailInfo.value?.getChatInfo()
}
defineExpose({
  getChatInfoFromTop
})
</script>
