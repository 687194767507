import { defineAsyncComponent } from 'vue';

export const compList = {
  Area: defineAsyncComponent(()=> import('./area/index.vue')),
  Input: defineAsyncComponent(()=> import('./input/index.vue')),
  Select: defineAsyncComponent(()=> import('./select/index.vue')),
  Unknown: defineAsyncComponent(()=> import('./unknown.vue')),
  IndustryCascade: defineAsyncComponent(()=> import('./industryCascade/index.vue')),
  DateRange: defineAsyncComponent(()=> import('./date/index.vue')),
  Textarea: defineAsyncComponent(()=> import('./textarea/index.vue')),
  Number: defineAsyncComponent(()=> import('./number/index.vue')),
  Staff: defineAsyncComponent(()=> import('./staff/index.vue')),
  Switch:defineAsyncComponent(()=> import('./switch/index.vue')),
  CombinationDate:defineAsyncComponent(()=> import('./combinationDate/index.vue')),
  SearchMultiselect:defineAsyncComponent(()=> import('./searchMultiselect/index.vue')),
  TagSelect:defineAsyncComponent(()=> import('./tagSelect/checkbox.vue')),
  FileField: defineAsyncComponent(() => import('./fileField/fileField.vue')),
  UploadImg: defineAsyncComponent(() => import('./upload/upload.vue')),
  SearchInsightCompany: defineAsyncComponent(()=> import('./searchInsightCompany/index.vue')),
}

const TypeMap = {
  Area: ['area','linkageArea'],
  Input: ['text', 'phone','varchar'],
  Number: ['number','currency'],
  Select: ['select'],
  IndustryCascade:['industryCascade','linkageCategory'],
  DateRange:['date'],
  Textarea:['textarea'],
  Staff:['employee'],
  Switch:['switch'],
  CombinationDate:['combinationDate'],
  SearchMultiselect:['searchMultiselect'],
  TagSelect:['tagselect'],
  FileField: ['file'],
  UploadImg: ['image'],
  SearchInsightCompany: ['externalData'],
}

export function getCompNameByType(type){
  let comp = Object.keys(TypeMap).find((ii)=>{
    return TypeMap[ii].includes(type);
  });
  return comp || 'Unknown';
}