<template>
  <div class="projectMen">
    <AddMumber
      :showFooter = "false"
      :selectTeamList="selectTeamList"
      @choosePerId="choosePerId"
    />
    <div v-show="selectTeamList.length" class="liebiao">
      <TeamList :selectTeamList="selectTeamList" @updatePermission="updatePermission" @chartChange="chartChange" @deletePerson="deletePerson" @setOwner="setOwner"></TeamList>
    </div>
    <!-- <div class="liebiao">
      <TeamList :teamPersons="teamPersons" @updatePermission="updatePermission" @deletePerson="deletePerson" @setOwner="setOwner"></TeamList>
    </div> -->
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import AddMumber from '../common/batchAddMumber.vue'
import TeamList from '@/views/components/common/teamList.vue'
// import personMixin from '@/views/mixin/index.js'

export default {
  props:{
    selectTeamList:{
      type:Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      teamPersons:[]
    }
  },
  components: {
    AddMumber,
    TeamList
  },
  methods:{
    chartChange(val){
      this.$emit('chartChange',val )
    },
    choosePerId(val){
      // this.selectTeamList = val
      this.$emit("choosePerId",val)
    },
    updatePermission(com, id, index){
      this.$emit("updatePermission",com, id, index)
    }
  }
}
</script>
<style scoped lang="scss">
.projectMen{
    position: relative;
    .projectMen-top{
        display: flex;
        justify-content: space-between;
    }
    .liebiao{
      width: 100%;
      position: absolute;
      top: 39px;
      // padding: 12px 20px;
    }
}
:deep(.top-team-popover) {
  padding: 0;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #e4e5eb;

  .top-team-add-foot {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e4e5eb;
  }
}
</style>
