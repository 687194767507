<template>
  <div class="newInvest">
    <header>当年投资情况</header>
    <a-table
      :columns="columns"
      :bordered="{ cell: true }"
      :data="data"
      :scroll="{ x: '50%', y: '100%' }"
      :pagination="false"
    />
  </div>
</template>

<script setup>
import moment from 'moment'
import { computed, reactive } from 'vue'
import {formatCurrency} from "@/utils/currency.js"
const props = defineProps({
  dataInfo: {
    type: Object,
    default: () => [],
  },
  monthCount: {
    type: Object,
    default: () => [],
  },
})
const columns = [
  {
    title: '年度',
    dataIndex: 'year',
    width: 100,
  },
  {
    title: '交易金额',
    dataIndex: 'amount',
    width: 120,
    ellipsis: true,
    tooltip: true,
    render: ({ record }) => {
      if (record.amount) {
        return record.amount
      } else {
        return '-'
      }
    },
  },
  {
    title: '投资事件',
    dataIndex: 'case',
    width: 100,
  },
]
const data = computed(()=>{
  // return Object.values(props.dataInfo).map((ele,index)=>{
  //   return {
  //     id:index,
  //     year:ele.year+'年',
  //     amount:ele.currency + " " + formatCurrency(ele.amount,true),
  //     case:ele.case+'起'
  //   }
  // })
  const curMonth = moment().format('M')
  const tempData = props.dataInfo?.slice(0, curMonth) || []
  return Object.values(tempData).map((ele,index)=>{
    return {
      id:index,
      year:ele.name,
      amount:ele.currency || 'CNY' + " " + formatCurrency(ele.value,true),
      case:props.monthCount[index]?.value+'起'
    }
  })
})
</script>

<style lang="scss" scoped>
.newInvest {
  padding: 12px 24px;
  border: 1px solid #edeff5;
  font-size: 14px;
  margin-bottom: 12px;
  header {
    margin-bottom: 11px;
    font-weight: 500;
  }
  :deep(.arco-table-th){
    background: #fff;
  }
}
</style>
