<template>
  <div class="newInvest">
    <header>最新投资案例</header>
    <a-table
      :columns="columns"
      :bordered="{ cell: true }"
      :data="dataList"
      :scroll="{ x: '50%', y: '100%' }"
      :pagination="false"
    />
  </div>
</template>

<script setup>
import {formatCurrency} from "@/utils/currency.js"
const props = defineProps({
  dataInfo:{type:Object,default:()=>{ return [] }}
})
import monent from 'moment'
import { computed, onMounted, reactive,ref } from 'vue'
const columns = [
  {
    title: '被投公司简称',
    dataIndex: 'comName',
    width: 120,
    ellipsis: true,
    tooltip: true,
    // fixed: 'left',
  },
  {
    title: '交易时间',
    dataIndex: 'investDate',
    width: 120,
  },
  {
    title: '交易金额',
    dataIndex: 'money',
    width: 130,
    ellipsis: true,
    tooltip: true,
    // render: ({ record }) => {
    //   if (record.financeAmount) {
    //     return record.financeAmount
    //   } else {
    //     return '-'
    //   }
    // },
  },
  {
    title: '交易轮次',
    dataIndex: 'phase',
    width: 118,
    ellipsis: true,
    tooltip: true,
  },
]
const dataList = computed(()=>{
  return (props.dataInfo || []).map(ele=>{
    return {
      id:ele.id,
      comName:ele.com_name.name,
      phase:ele.phase,
      investDate:monent(ele.investDate).format("YYYY-MM-DD"),
      // financeAmount: ele.financeAmount?.value ? ele.financeAmount.currency + ' ' + formatCurrency(ele.financeAmount?.value,true) || ele.financeAmount?.original : '-'
      money: ele.money
    }
  })
})
onMounted(()=>{
})
</script>

<style lang="scss" scoped>
.newInvest {
  padding: 12px 24px;
  border: 1px solid #edeff5;
  font-size: 14px;
  margin-bottom: 12px;
  header {
    margin-bottom: 11px;
    font-weight: 500;
  }
  :deep(.arco-table-th){
    background: #fff;
  }
}
</style>
