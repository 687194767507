<template>
  <div class="OrganizationNews">
    <header>机构新闻</header>
    <div class="content">
      <div class="cont-item" v-for="(item, index) in lists" :key="item">
        <span class="title" @click="toUrl(item.url)" style="color: #3272fe">{{ item.title }}</span>
        <span>
          <i class="iconfont icon-icon_hour laozhong">
            {{ monent(item.publishedTime).format('YYYY-MM-DD') }}</i
          >
        </span>
        <span :class="['bottom', lists.length - 1 === index ? 'nob' : '']"></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import monent from 'moment'
const props = defineProps({
  dataInfo: {
    type: Object,
    default: () => {
      return []
    },
  },
})
const lists = computed(() => {
  return props.dataInfo
})
const toUrl = (url) => {
  window.open(url)
}
</script>

<style lang="scss" scoped>
.OrganizationNews {
  padding: 12px 24px;
  padding-bottom: 0;
  border: 1px solid #edeff5;
  font-size: 14px;
  header {
    margin-bottom: 11px;
    font-weight: 500;
  }
  .cont-item {
    display: flex;
    flex-direction: column;
    .title {
      cursor: pointer;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .laozhong {
      font-size: 12px;
      color: #959aa2;
    }
    .bottom {
      margin-top: 11px;
      margin-bottom: 12px;
      width: 100%;
      height: 1px;
      background: #e4e5ec;
      &.nob {
        height: 0;
        margin-bottom: 0;
      }
    }
  }
}
</style>
