<template>
  <div class="content">
    <div class="top">
      <a-input
        :style="{
          background: '#fff',
          border: '1px solid #E4E5EB',
          padding: '0 7px',
          borderRadius: '4px',
          flex: '1',
        }"
        placeholder="请输入投资机构名称"
        size="small"
        v-model="allValue.fundFullName"
        allow-clear
        @input="seachChange"
        @clear="clearHandler"
      />
    </div>
    <a-list
      v-if="listcontent.length > 0"
      hoverable="true"
      class="list"
      :loading="listLoading"
      :max-height="250"
      @reach-bottom="reachBottom"
    >
      <a-list-item
        style="border: 1px solid transparent"
        v-for="(item, index) in listcontent"
        @click="listItemClick(item, index)"
        :class="['list-item', clickFlag === index ? 'actived' : '']"
        :key="index"
      >
        <div class="list-item-left">
          <div class="showimg">
            <img :src="item.logo ? item.logo : imgs" alt="" />
          </div>
          <p>{{ item.name }}</p>
        </div>
        <div class="list-item-right">
          <p>{{ item.description }}</p>
        </div>
        <div v-if="clickFlag === index" id="triangle-topright"></div>
        <!-- <img v-if="clickFlag === index" src="@/assets/img/file/duigou.svg" alt=""> -->
        <i v-if="clickFlag === index" class="iconfont icon-close-med choos" />
      </a-list-item>
    </a-list>
    <template v-else>
      <div class="creatnomore">
        <img src="@/assets/img/createNomore.png" alt="" />
        <p style="margin-top: 8px">请输入您想查找的投资机构</p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from 'vue'
import { updateProject,orgInsightSearch } from '@/services/investment/createProject.js'
import {getDataTypeByBusinessCode} from "@/utils/enums.js"
import imgs from '@/assets/img/jinghzun.png'
const props = defineProps({
  bCode: {
    type: Object,
  },
  dataId: {
    type: Object,
  },
  orgInfo: Object,
})
const searchValue = ref('')
const listcontent = ref([])
const clickFlag = ref()
const clickshow = ref(false)
const listLoading = ref(false)
const allValue = reactive({
  fundFullName:'',
  orgId: '',
  id: props.dataId,
  logo: null,
  projectIntroduce: '',
  assetTypeId:getDataTypeByBusinessCode(props.bCode)
})
const clearHandler = () => {
  clickFlag.value = -1
  clickshow.value = false
  allValue.fundFullName = ''
  allValue.orgId = ''
  allValue.logo = null
  allValue.projectIntroduce = ''
}
const seachChange = async (val) => {
  listLoading.value = true
  clickFlag.value = -1
  clickshow.value = false
  allValue.fundFullName = val
  allValue.orgId = ''
  allValue.logo = null
  allValue.projectIntroduce = ''
  let res = await orgInsightSearch({ params: { keyword: allValue.fundFullName } })
  listLoading.value = false
  if (!res) return
  listcontent.value = [...res]
}
const writeBack = async (val) => {
  clickFlag.value = -1
  clickshow.value = false
  allValue.fundFullName = val
  allValue.orgId = ''
  allValue.logo = null
  allValue.projectIntroduce = ''
  let res = await orgInsightSearch({ params: { keyword: allValue.fundFullName } })
  if (!res) return
  listcontent.value = [...res]
  // 回写
  writeBack && listcontent.value.forEach((item, index) => {
    if (item.id === props.orgInfo.id) {
      clickFlag.value = index
      clickshow.value = true
      allValue.orgId = item.id
      allValue.logo = item.logo
      allValue.fundFullName = item.name
      allValue.projectIntroduce = item.description
    }
  })
}
const listItemClick = (item, index) => {
  if (clickFlag.value === index) {
    clickFlag.value = -1
    clickshow.value = false
    allValue.orgId=''
    allValue.logo=null,
    allValue.projectIntroduce=null
  } else {
    clickFlag.value = index
    clickshow.value = true
    allValue.orgId=item.id
    allValue.logo=item.logo,
    allValue.fundFullName =item.name
    allValue.projectIntroduce=item.description

    // searchValue.value = item.fund_name
  }
}
watch(
  () => props.orgInfo,
  (v) => {
    allValue.orgId = v?.id || ''
    allValue.fundFullName = v?.name || ''
    allValue.fundFullName && writeBack(v.name)
  },
  {
    immediate: true
  }
)
defineExpose({allValue})
</script>

<style lang="scss" scoped>
.content {
  // margin-top: 19px;
  // height: 327px;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    align-items: center;
    padding-bottom: 9px;
  }
  .list {
    height: 250px;
    // padding-top: 13px;
    display: flex;
    justify-content: space-between;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
    ::-webkit-scrollbar {
      width: 0 !important;
    }
    // ::-webkit-scrollbar-thumb {
    //   width: 6px;
    //   height: 116px;
    //   background: #ececec;
    //   border-radius: 3px;
    // }
    // ::-webkit-scrollbar-track-piece {
    //   background: #fff;
    // }

    :deep(.arco-list-bordered) {
      border: none;
    }
    :deep(.arco-list-content) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      box-sizing: border-box;
      //   padding: 0 24px;
      &::after {
        content: '';
        width: 192px;
      }
    }
    :deep(.arco-list-split .arco-list-item:not(:last-child)) {
      border: none;
    }
    .list-item {
      &.actived {
        border: 1px solid #3272FE !important ;
      }
      // &.last-item {
      //   &:last-child{
      //     justify-content: flex-start;
      //   }
      // }
      // &:first-child {
      //   margin: 0;
      // }
      position: relative;
      padding: 12px;
      box-sizing: border-box;
      width: 192px;
      // margin-left: 8px;
      background-color: #ffffff;
      border-radius: 8px;
      // display: flex;
      margin-bottom: 8px;
      border: 1px solid #dbe0e3 !important;
      cursor: pointer;
      #triangle-topright {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 21px solid #3272FE;
        border-left: 21px solid transparent;
      }
      .choos {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        font-size: 10px;
      }
      .list-item-left {
        margin-right: 8px;
        display: flex;
        align-items: center;
        .showimg {
          width: 100%;
          width: 32px;
          height: 32px;
          border-radius: 6px;
          overflow: hidden;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          flex: 1;
          font-size: 14px;
          margin-left: 8px;
          color: #1f2329;
          font-weight: 500;
          font-family: PingFangSC-Medium, PingFang SC;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 1; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
        }
      }
      .list-item-right {
        p:nth-child(1) {
          font-size: 12px;
          // height: 18px;
          // line-height: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4e5969;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 6; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
          margin: 8px 0 6px 0;
        }
      }
    }
  }
}
.creatnomore {
  display: flex;
  // align-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
  p {
    font-size: 12px;
    width: 300px;
    text-align: center;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c1c4cb;
  }
}
</style>
