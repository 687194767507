<template>
  <Card class="similarity" :is-loading="isLoading" :title="i18n_t('plat_c.allTabs.investmentPlan.jinpinenxi')">
    <div class="wrapper">
      <div class="item" v-if="computedList.length" v-for="item in computedList">
        <div class="logo">
          <img v-if="item.logo" class="img" :src="item.logo.split('?')[0]" alt="logo" />
          <span class="default" v-else>{{ item.name && item.name.slice(0, 1) }}</span>
        </div>
        <div class="info">
          <div class="title">
            <span>{{ item.name }}</span
            ><span class="financing">{{ item.financePhaseName }}</span>
          </div>
          <div class="desc">{{ item.brief }}</div>
        </div>
      </div>
      <EmptyContent :message="i18n_t('plat_c.allTabs.com.zanwushuju')" v-else />
    </div>
  </Card>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue'
import EmptyContent from '@/components/emptyContent.vue'
import Card from './card.vue'
import { useI18n } from 'vue-i18n'
const { t: i18n_t } = useI18n()

const props = defineProps({
  isLoading: Boolean as PropType<boolean>,
  list: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
})

const computedList = computed(() => {
  return props.list.map((i) => {
    return {
      logo: i.logo,
      // name: i.similarityName?.name,
      name: i.tycSimilarityName,
      brief: i.brief,
      financePhaseName: i.financePhaseName,
    }
  }).filter((i, idx) => {
    return idx < 3
  })
})
</script>

<style lang="scss" scoped>
.similarity {
  padding-bottom: 6px;
  .item {
    display: flex;
    align-items: center;
    min-height: 64px;
    border-bottom: 1px solid #edeff5;
    &:last-child {
      border: none;
    }
    .logo {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      .img {
        max-height: 100%;
        max-width: 100%;
      }
      .default {
        display: inline-block;
        width: 100%;
        height: 100%;
        font-size: 16px;
        line-height: 38px;
        color: #fff;
        text-align: center;
        background: #99adff;
      }
    }
    .info {
      padding-left: 8px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      flex: 1;
      .title {
        font-size: 14px;
        color: #282d33;
        display: flex;
        justify-content: space-between;
        .financing {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #959aa2;
        }
      }
      .desc {
        font-size: 12px;
        color: #656b72;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }
  .empty-desc {
    color: #E4E5EC;
    margin-bottom: 6px;
    display: inline-block;
  }
}
</style>
