<template>
    <div class="relation-warp">
        <div class="left">
            <img class="bg" src="@/assets/img/relation/tab-bj.png" alt="">
            <div class="content">
                <div class="title">
                    <img src="@/assets/img/relation/icon.svg" alt="">
                    <span>公司关系网</span>
                </div>
                <a-anchor line-less scroll-container=".content-main" :change-hash="false" class="temp-anchor-list"
                    @change="handleAnchorChange">
                    <a-anchor-link href="#Identity"><i class="iconfont icon-icon_portrait" />身份画像</a-anchor-link>
                    <a-anchor-link href="#RelationGraph"><i class="iconfont icon-icon_network" />关系网络</a-anchor-link>
                    <a-anchor-link v-show="state.investGraphShow" href="#InvestGraph"><i
                            class="iconfont icon-icon_structure" />投资脉络</a-anchor-link>
                    <a-anchor-link href="#PublicInfo"><i class="iconfont icon-icon_3" />公开信息</a-anchor-link>
                </a-anchor>
            </div>
        </div>
        <div class="right">
            <!-- <a-skeleton :animation="animation"> -->
            <div class="header-wrap">
                <!-- <img class="logo" :src="state?.publicInfo.companyInfo?.logo" alt=""> -->
                <div class="logo-cont">
                    <img v-if="state?.publicInfo.companyInfo?.logo" :src="state?.publicInfo.companyInfo?.logo"
                        alt="logo" class="logo-img">
                    <span v-else class="name-label">{{ props.info.name ?
                        props.info.name[0] : '' }}</span>
                </div>

                <!-- <a-skeleton v-else  :animation="animation">  <a-skeleton-shape /></a-skeleton> -->


                <div class="title-wrap">
                    <p v-if="props.info.name" class="project-name">
                        <AutoTooltip :content="props.info.name" />
                    </p>
                    <!-- <p v-else> </p> -->
                    <!-- <a-skeleton-line v-else :row="1" /> -->
                    <!-- <a-skeleton v-else :animation="animation" style="width: 200px" > <a-skeleton-line   :row="1" /> </a-skeleton> -->
                    <div v-if="state?.industry?.length" class="bottom">
                        <div class="industry-wrap">
                            <span v-for="(item, index) in state.industry" :key="item.key">
                                {{ item.label }}{{ index == state.industry.length - 1 ? '' : '-' }}
                            </span>
                        </div>

                    </div>
                </div>

                <div class="right">
                    <a-popover
                        v-if="!hideTjzBtn"
                        v-model:popup-visible="popModel"
                        trigger="click"
                        class="graph-quanyi-template-pop"
                        position="br"
                        @popup-visible-change="(e: any) => {
                            popModel = e
                        }" :disabled="assetList?.length == 1"
                    >
                        <a-button type="primary" :loading="permissionLoading" style="line-height: 16px;padding:0 12px"
                            @click="handleShowCreatePop(curAssetInfo, true)">
                            <template #icon>
                                <i class="iconfont icon-icon_propel" />
                            </template>
                            <template #default>推进至</template>
                        </a-button>
                        <template #content>
                            <div class="categories-name" v-for="(item, index) in assetList" :key="index"
                                @click="handleShowCreatePop(item)">
                                <AutoTooltip className="name-cont" :content="item.name" />
                            </div>
                        </template>
                    </a-popover>
                    <i class="iconfont icon-icon_close_med close-icon " @click.stop="emit('close')" />
                </div>
            </div>
            <!-- </a-skeleton> -->
            <div class="content-main ">
                <div id="Identity" class="card-box identity-wrap" style="margin-top: 0;">
                    <p class="title">身份画像 </p>
                    <div v-if="state.relatedList.length == 0">-</div>
                    <identityTags v-else :identityPortList="state.relatedList"  :dataId="props.dataId" :bCode="props.bCode" :needToDetail="true"
                        :needIcon="true" @close="emit('close')" />
                </div>
                <!-- <component from="holdInfo" 
                        :is="tabList[state.tabActiveIndex].com" 
                        :bCode="'investedCompany'"
                        :projectName="state.publicInfo?.companyInfo?.fullName" 
                        /> -->
                <div id="RelationGraph" class="card-box" style="height: 480px;">
                    <p class="title">关系网络<span @click="openDocument"><i
                                class="iconfont icon-icon_help" />如何扩展更多关系</span></p>
                    <!-- :relatedList="state.checkNodeData.relatedList" -->
                    <RelationGraph ref="relationGraph" :name="props.info.name" :isFullScreen="state.isFullScreen" :graphNodes="state.graphNodes"
                        :graphLines="state.graphLines" :rootId="state.rootId" :disableZoom="true"
                        :getDataFun="getRelationGraphData" :getCompanyListFun="getCompanyList"
                        :checkData="state.checkNodeData" @newData="appendData" @checkNodeData="checkNodeDataChange"
                        @toggleFullScreen="toggleFullScreen" @reset="hanldeReset"/>
                </div>
                <div v-show="state.investGraphShow" id="InvestGraph" class="card-box" style="height: 480px;">

                    <p class="title">投资脉络</p>
                    <InvestGraph v-if="props.info.name" :projectName="props.info.name" @isShowView="showChanage" />
                </div>
                <div id="PublicInfo" class="card-box">
                    <!-- TODO: bcode要动态 -->
                    <!-- {{state?.publicInfo}} -->
                    <PublicInfo v-if="state && state.publicInfo && props.info.name" from="holdInfo" :bCode="props.bCode"
                        :projectName="props.info.name" />
                </div>
            </div>
            <!-- 关系网全屏 -->
            <a-modal v-if="state.isFullScreen" v-model:visible="state.isFullScreen" fullscreen hide-cancel hide-title
                :closable="false" :footer="false" class="full-screen-graph-modal">
                <div v-if="state.isFullScreen" class="full-screen-graph">
                    <!-- :relatedList="state.checkNodeData.relatedList" -->
                    <RelationGraph :isFullScreen="state.isFullScreen" :name="props.info.name" :graphNodes="state.graphNodes"
                        :graphLines="state.graphLines" :rootId="state.rootId" :checkData="state.checkNodeData"
                        :getDataFun="getRelationGraphData" :getCompanyListFun="getCompanyList" @newData="appendData"
                        @checkNodeData="FullScreenCheckNodeData" @toggleFullScreen="toggleFullScreen" @reset="hanldeReset"/>
                </div>
            </a-modal>
        </div>
    </div>

    <createDialog v-if="showCreatePop" ref="createDialogRef" origFrom="relationGraph" :bCode="assetBCode"
        :assetName="assetName" :appCode="'beforeProject'" :parentView="{ id: assetTypeId }" :project="null"
        :customSearchValue="info?.name" @closeDialog="closeDialog" @showDetails="createOver" />
</template>

<script lang="ts" setup>
import { computed, reactive, ref, onMounted, nextTick, watch } from 'vue';
import { cloneDeep } from 'lodash'
import { useRequest } from 'vue-request'
import { useRoute, useRouter } from 'vue-router'
import { getDetailLink } from '@/utils/appTool.js'
const route = useRoute()
const router = useRouter()
/**
 * APIs
 */
import { assetTypeAllList } from '@/services/investment/createProject.js'
import { checkBookPermission } from '@/services/investment/index.js'
import { queryByCompanyList, queryCompanyIndustry } from '@/services/investment/index.js'
import { getPublicInfoByCompanyFullName } from '@/views/beforeInv/edit/publicInfo/services.ts'
import { queryRelationship } from "@/services/holdInfo/index.js"
/**
 * Components
 */
import { Message } from '@arco-design/web-vue';
import CreateDialog from '@/views/beforeInv/createDialog.vue'
import AutoTooltip from '@/components/AutoTooltip.vue'
import PublicInfo from '@/views/beforeInv/edit/publicInfo/index.vue'
import RelationGraph from './relationGraph.vue'
import InvestGraph from './investGraph.vue'
import identityTags from '@/components/form/fields/searchInsightCompany/identityTags.vue'

/**
 * Resources
 */

/**
 * Types
 */
type State = {
    // companyFullName: string,
    drawerRelationVisible: boolean
    checkNodeData: { name: string, relatedList: any[] }
    logo: string,
    publicInfo: {
        companyInfo: {
            fullName: string
            logo: string
        }
    } | any
    relatedList: any[]
    industry: {
        key: string,
        label: string
    }[]
    isFullScreen: boolean
    graphNodes: {
        id: string,
        text: string,
    }[]
    graphLines: {
        from: string,
        to: string,
        fontColor: string,
        text: string,
    }[]
    rootId: string,
    investGraphShow: boolean
}

/**
 * Constants
 */

/**
 * Props
 */
const props = defineProps({
    info: {
        type: Object,
        default: {},
    },
    bCode: {
        type: String,
        default: '',
    },
    dataId: {
        type: String,
        default: '',
    }
})

/**
 * Hooks
 */
const emit = defineEmits(['close','reset'])

/**
 * States
 */
const hideTjzBtn = ref(true)
const relationGraph = ref(null)
const createDialogRef = ref(null)
const popModel = ref(false)
const showCreatePop = ref(false)
const permissionLoading = ref(false)
const curAssetInfo = ref(null)
const assetBCode = ref('')
const assetName = ref('')
const assetTypeId = ref('')
const assetList = ref([])
const animation = ref(true)
const state: State = reactive({
    drawerRelationVisible: true,
    logo: '',
    publicInfo: {
        // companyInfo:{
        //     fullName:'',
        //     logo:''
        // }
    },
    relatedList: [],
    industry: [],
    isFullScreen: false,
    graphNodes: [],
    graphLines: [],
    rootId: '',
    investGraphShow: true,
    checkNodeData: {
        name: '',
        relatedList: []
    }

})

const textFilter = computed(() => {
  return (str:string) =>{
    if(str.length > 15){
        return str.slice(0,15)+'...'
    }
    return str
  }
})

const handleDefaultActive = (isActive = true) => {
    nextTick(() => {
        const dom = document.querySelector('.arco-anchor-link-item')
        isActive ? dom?.classList?.add('active') : dom?.classList?.remove('active')
    })
}
const closeDialog = () => {
    showCreatePop.value = false
}
const relatedListInit = async () => {
    let CompanyListRes = await getCompanyList({ data: { companyList: [props.info.name] } })
    state.relatedList = CompanyListRes[0]?.identityPortList || []
    state.checkNodeData.relatedList = CompanyListRes[0]?.identityPortList || []
    hideTjzBtn.value = state.relatedList?.some(item => ['fund', 'lpInvestor'].includes(item.type))

}
const relationGraphInit = async () => {
    let res = await getRelationGraphData(props.info.name)
    if(res){
        let rootId = res.name
        let nodes: any = [{ id: rootId, text: textFilter.value(res.name) }];
        let lines: any = [];
        (res?.child || []).map((item: any) => {
            nodes.push({ id: item.name, text: item.name });
            (item?.line || []).map((ff: any) => {
                if (ff.direction == 2) {
                    lines.push({ from: item.name, to: rootId, text: textFilter.value(ff.name), fontColor: '#4E5969' })
                } else {
                    lines.push({ from: rootId, to: item.name, text: textFilter.value(ff.name), fontColor: '#4E5969' })
                }
            })
    
        })
        // nodes=[
        //   { id: '肖峰', text: '肖峰',},
        //   { id: '济南惊蛰合伙企业（有限合伙）', text: '济南惊蛰合伙企业（有限合伙）',},
        //   { id: '阅微人民币一期', text: '阅微人民币一期',},
    
        //     ]
        // lines= [
        //   { from: '肖峰', to: '济南惊蛰合伙企业（有限合伙）', text: '投资' ,fontColor: '#4E5969'},
        //   { from: '肖峰', to: '阅微人民币一期', text: '高管' ,fontColor: '#4E5969'},
       
        //     ]
        //     rootId="肖峰"
        state.graphNodes = nodes
        state.graphLines = lines
        state.rootId = rootId
        state.checkNodeData.name = rootId
    }
}

const handleShowCreatePop = (item: any, isTrigger = false) => {
    if (assetList.value?.length > 1 && isTrigger) return
    popModel.value = false
    permissionLoading.value = true
    assetTypeId.value = item?.id
    assetBCode.value = item?.businessCode
    assetName.value = item?.name
    checkBookPermission({
        data: {
            assetTypeId: item?.id,
            businessCode: item?.businessCode,
        },
    }).then(res => {
        if (res && res[item?.id]?.create == 1) {
            showCreatePop.value = true
            nextTick(() => {
                // console.log('====', createDialogRef.value)
                createDialogRef.value?.seachChange(props.info?.name)
            })
        } else {
            Message.warning('您没有创建项目的权限，请联系管理员')
        }
    }).finally(() => {
        permissionLoading.value = false
    })
}
/**
 * Requests
 */


useRequest(assetTypeAllList, {

    onSuccess: async (res) => {
        if (res) {
            let tempList = res.filter((item: any) => item.isOpen && !item.isDeleted && item.assetTypeCode === 'investedCompany') // 只要股权类型
            tempList?.forEach((item: any) => {
                item.businessCode = 'beforeProject' // 走创建投前股权逻辑
            })
            if (tempList.length > 0) {
                assetList.value = tempList
                curAssetInfo.value = tempList[0]
            }

        }
    },
})

const { runAsync: getRelationGraphData } = useRequest(queryRelationship, {
    manual: true,
    // defaultParams:[props.info.name],

})
useRequest(getPublicInfoByCompanyFullName, {
    defaultParams: [{
        companyFullName: props.info.name
    }],
    onSuccess: async (res) => {
        if (res) {
            state.publicInfo = res //res.fullName || value.fundName || value.name
            state.logo = res.companyInfo.logo


        }
    },
})
const { runAsync: getCompanyList } = useRequest(queryByCompanyList, {
    manual: true,
    //     data:{ companyList: [state.companyInfo.fullName]} ,
    // onSuccess: (res) => {
    //     state.relatedList= res[0]?.identityPortList||[]
    //     console.log(res)
    // },
})
useRequest(queryCompanyIndustry, {
    defaultParams:[props.info.name],
    onSuccess: (res) => {
        state.industry = res || []
    },
})


/**
 * Events
 */
const hanldeReset = (name) => {
    emit('reset',name)
}
const toggleFullScreen = () => {
    state.isFullScreen = !state.isFullScreen

}
const showChanage = (flag: boolean) => {
    state.investGraphShow = flag
}
const checkNodeDataChange = (data: { name: string, relatedList: [] }) => {
    state.checkNodeData = data
}
const FullScreenCheckNodeData = (data) => {
    checkNodeDataChange(data);
    relationGraph.value?.refresh()

}
const appendData = (data: any) => {
    state.graphLines = cloneDeep(state.graphLines).concat(data.lines)
    state.graphNodes = cloneDeep(state.graphNodes).concat(data.nodes)
}
const openDocument = () => {
    window.open(' https://yuewetech.feishu.cn/wiki/R1e5w77C9i9Wgrk37jtcjmwUnPf', '_blank')
}
const handleAnchorChange = () => {
    handleDefaultActive(false)
}
const createOver = (obj, bCode) => {
    if (['before'].includes(bCode)) {
        router.replace({
            query: {
                aid: route.query?.aid,
                businessCode: bCode || obj.businessCode,
                dataid: obj.id,
                tab: obj.tabid || '',
            }
        })
    } else {
        getDetailLink('项目管理', bCode || obj.businessCode,  obj.id).then((res) => {
            window.location.href=res
        })
    }
    
}

/**
 * Effects
 */
watch(
    () => state.drawerRelationVisible,
    (v) => {
        if (v) {
            // nextTick(() => showGraph())
        }
    },
    {
        immediate: true,
    }
)

onMounted(async () => {
    handleDefaultActive(true)
    relationGraphInit()
    relatedListInit()
    // setTimeout(() =>(
    //     getAssetTypeAllList()
    // ),0)


});
</script>

<style lang="scss">
.graph-quanyi-template-pop {
    .arco-popover-content {
        margin-top: 0;
    }

    .arco-popover-popup-arrow {
        display: none;
    }

    .arco-popover-popup-content {
        padding: 4px;
    }

    .categories-name {
        width: 160px;
        padding: 5px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .selected-icon {
            font-size: 18px;
        }

        &.actived {
            color: #3272fe;
        }

        &:hover {
            background: rgba(31, 35, 41, 0.08);
        }

        .name-cont {
            overflow: hidden;
            display: -webkit-box;
            /**对象作为伸缩盒子模型展示**/
            -webkit-box-orient: vertical;
            /**设置或检索伸缩盒子对象的子元素的排列方式**/
            -webkit-line-clamp: 1;
            /**显示的行数**/
            text-overflow: ellipsis;
            word-break: break-all;
        }

        .font-style {
            flex-shrink: 0;
        }
    }
}
</style>

<style lang="scss">
.relation-drawer {
    .arco-drawer {
        border-radius: 16px 16px 0px 0px;
        height: calc(100vh - 40px) !important;
        bottom: 0;
    }

    .arco-drawer-body {
        padding: 0;
        overflow: hidden;
        background: linear-gradient(270deg, #EAEEFF 0%, #F5F9FE 100%);
    }

    .relation-warp {
        height: 100%;
        display: flex;

        >.left {
            width: 170px;
            background: #F4F8FE;
            position: relative;
            flex-shrink: 0;

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }

            .content {
                position: relative;
                z-index: 1;

                .title {
                    display: flex;
                    align-items: center;
                    padding: 22px 0 20px 24px;
                    font-family: PingFang SC;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                    letter-spacing: 0px;
                    font-variation-settings: "opsz" auto;
                    /* 文字色/1-强调 */
                    color: #1F2329;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 6px
                    }
                }

                .temp-anchor-list {
                    width: 100%;
                    padding:0 8px 12px 8px;

                    .arco-anchor-list {
                        // margin-top: 20px;

                        .arco-anchor-link-item {
                            width: 100%;
                            height: 40px;
                            padding: 0 16px;
                            margin-bottom: 0;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;
                            font-family: PingFang SC;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                            border-radius: 6px;
                            transition: .2s;

                            &:hover {
                                background: rgba(55, 67, 73, 0.06);
                                
                            }

                            &.arco-anchor-link-active {
                                background: #E1EBFF;

                                .arco-anchor-link {
                                    color: #3272FE;
                                    .iconfont {
                                        color: #3272FE;
                                    };
                                }
                            }

                            &.active {
                                background: #E1EBFF;

                                .arco-anchor-link {
                                    color: #3272FE;
                                    .iconfont {
                                        color: #3272FE;
                                    };
                                }
                            }

                            .iconfont {
                                margin-right: 8px;
                                color: #646A73;
                            }

                            .arco-anchor-link {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 100%;
                                padding: 0;
                                color: #1F2329;
                                background: transparent;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

        >.right {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            .header-wrap {
                height: 64px;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: linear-gradient(274deg, #EAEEFF 3%, #F5F9FE 99%);

                .logo-cont {
                    width: 32px;
                    height: 32px;
                    flex-shrink: 0;
                    border-radius: 8px;
                    box-sizing: border-box;
                    overflow: hidden;
                    position: relative;
                    font-size: 14px;
                    &::before {
                        z-index: 1;
                        content: "";
                        position: absolute;
                        border-radius: 8px;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }

                    .logo-img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }

                    .name-label {
                        
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        font-family: PingFang SC;
                        font-size: 14px;
                        line-height: 28px;
                        color: #FFFFFF;
                        background: #96ACFF;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                    }
                }

                .title-wrap {
                    display: flex;
                    flex: 1;
                    padding-left: 8px;
                    align-items: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    .project-name {
                        // width: calc(100% - 20px);
                        font-family: 苹方-简;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px;
                        color: #1F2329;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        display:inline-grid
                    }

                    .bottom {
                        display: flex;
                        align-items: center;
                        margin-left: 4px;
                        padding-right: 20px;
                        .industry-wrap {
                            display: flex;
                            border-radius: 4px;
                            opacity: 1;
                            /* 标签/11 */
                            background: #EFF0F1;
                            padding: 0px 6px;
                            height: 20px;
                            flex-shrink: 0;

                            span {
                                color: #4E5969;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 20px;
                            }
                        }


                    }
                }

                .right {
                    display: flex;
                    align-items: center;

                    .arco-btn {
                        margin-right: 20px;
                    }

                    .close-icon {
                        width: 28px;
                        height: 28px;
                        border-radius: 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: 0.2s;

                        &:hover {
                            background: rgba(55, 67, 73, 0.06);
                        }
                    }
                }
            }



            .content-main {
                flex: 1;
                width: 100%;
                padding: 0 10px;
                // min-height: 100%;
                overflow: auto;


                &::-webkit-scrollbar-track {
                    background: transparent;
                }

                .card-box {
                    margin-top: 10px;
                    // 480
                    background: #fff;
                    border-radius: 8px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 2px -4px rgba(31, 35, 41, 0.04), 0px 2px 3px 0px rgba(31, 35, 41, 0.03);
                    position: relative;

                    >.title {
                        position: absolute;
                        z-index: 1;
                        top: 16px;
                        left: 20px;
                        font-family: PingFang SC;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        /* 文字色/1-强调 */
                        color: #1F2329;
                        display: flex;
                        z-index: 100;

                        span {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            line-height: 20px;
                            /* 文字色/3-次要信息 */
                            color: #86909C;

                            .iconfont {
                                margin: 0 4px 0 6px;
                            }

                            cursor: pointer;

                            &:hover {
                                color: #3272FE;
                            }
                        }
                    }
                }

                // 身份画像
                .identity-wrap {
                    padding: 42px 20px 20px;
                    .list-item{
                        margin-bottom: 0;
                        margin-top: 6px;
                        margin-right: 6px;
                    }
                    .identity-tag-lists-wrapper {
                        margin-top: 0;
                        .item-wrapper{
                            padding: 4px 8px !important;
                            height: 28px !important;
                            .iconfont{
                                font-size: 14px;
                            }
                            .toLink{
                                font-size: 10px;
                            }
                            span{
                                font-size: 14px;
                            }
                        }
                    }
                    .tags-ul {
                        width: calc(100% - 22px);
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        .tag-item {
                            flex-grow: 0;
                            flex-shrink: 0;
                            height: 20px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            padding: 0px 6px;
                            margin-right: 4px;
                            box-sizing: border-box;
                            font-family: PingFang SC;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 20px;
                            color: #3272FE;
                            background: #E6EEFE;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            cursor: pointer;

                            &.sub {
                                background: #FEF6DF;
                                color: #B07F15;
                            }

                            .label {
                                display: block;
                                max-width: 150px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }

                            .icon-icon_d_arrow {
                                font-size: 8px;
                                transform: rotate(-90deg);
                                margin-left: 2px;
                            }
                        }
                    }
                }

                .detail-info {
                    padding: 0 20px 20px !important;
                    overflow-y: auto !important;

                    .info-header {
                        // display: none;
                        line-height: 20px;
                        height: auto !important;
                        padding: 16px 0 12px
                    }

                    .info-content {
                        padding: 0;
                    }
                }
            }


        }
    }
}
</style>