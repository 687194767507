import {ajax} from '@/utils/request';

// 创建群聊前检查是否有数据权限
export async function chatDataRights(dataId, businessCode) {
  const result = await ajax({
    method: 'get',
    url: `/chat/data/rights/${dataId}/${businessCode}`,
  },);
  return result;
}
// 创建项目群聊
export async function chatCreate(extra) {
  const result = await ajax({
    method: 'get',
    url: `/chat/create`,
    ...extra
  },);
  return result;
}
// 获取项目群信息(用于跳转)
export async function chatInfo(extra) {
  const result = await ajax({
    method: 'get',
    url: `/chat/info`,
    ...extra
  },);
  return result;
}
// 增加群聊人员
export async function chatMembersAdd(extra) {
  const result = await ajax({
    method: 'get',
    url: `/chat/members/add`,
    ...extra
  },);
  return result;
}
// 批量增加群聊人员
export async function chatMembersBatchAdd(extra) {
  const result = await ajax({
    method: 'post',
    url: '/chat/members/batch/add',
    ...extra
  },);
  return result;
}
// 删除群聊人员
export async function chatMembersDelete(extra) {
  const result = await ajax({
    method: 'get',
    url: `/chat/members/delete`,
    ...extra
  },);
  return result;
}
// 转交负责人
export async function chatManagerChange(extra) {
  const result = await ajax({
    method: 'get',
    url: `/chat/manager/change`,
    ...extra
  },);
  return result;
}
