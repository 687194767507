<template>
  <Card class="history" :title="i18n_t('plat_c.allTabs.publicInfo.rongzilishi')" :is-loading="isLoading">
    <div class="history-header">
      <!-- <div class="summary">
        <label class="label">{{i18n_t('plat_c.allTabs.publicInfo.leiji_J_E')}}</label>
        <div class="value">
          <template v-if="companyInfo.financeAmount">
            {{ companyInfo.financeAmount.currency
            }}<template v-if="companyInfo.financeAmount?.original">
              {{ companyInfo.financeAmount?.original }}
            </template>
            <template v-else>
              <span class="num">
                {{
                  formatCurrency(companyInfo.financeAmount.value, true, true, true)[0] || '-'
                }} </span
              >{{ formatCurrency(companyInfo.financeAmount.value, true, true, true)[1] || '' }}
            </template>
          </template>
          <template v-else> - </template>
          {{ companyInfo.tycFinanceAmount || '-' }}
        </div>
      </div> -->
      <div class="summary">
        <label class="label">{{i18n_t('plat_c.allTabs.publicInfo.zuixinguzhi')}}</label>
        <div class="value">
          <!-- <template v-if="companyInfo.marketValue">
            {{ companyInfo.marketValue.currency }}
            <template v-if="companyInfo.marketValue?.original">
              {{ companyInfo.marketValue?.original }}
            </template>
            <template v-else>
              <span class="num">
                {{
                  formatCurrency(companyInfo.marketValue.value, true, true, true)[0] || '-'
                }} </span
              >{{ formatCurrency(companyInfo.marketValue.value, true, true, true)[1] || '' }}
            </template>
          </template>
          <template v-else> - </template> -->
          {{ companyInfo.tycMarketValue || '-' }}
        </div>
      </div>
    </div>

    <div class="history-body">
      <div class="history-item" v-if="list.length" v-for="history in list">
        <div class="history-item__info">
          <span class="date border-right">{{ history.financeDate }}</span>
          <span class="round border-right">{{ history.financePhaseName }}</span>
          <span class="amount">
            <!-- <template v-if="history.financeAmount">
              {{ history.financeAmount.currency }}
              <template v-if="history.financeAmount.original">
                {{ history.financeAmount.original }}
              </template>
              <template v-else>
                <span class="num">
                  {{ formatCurrency(history.financeAmount.value, true) || '-' }}
                </span>
              </template>
            </template>
            <template v-else>-</template> -->
            {{ history.tycFinanceAmount }}
          </span>
        </div>

        <div class="history-item__invest">
          <a-space wrap :size="[4, 4]" v-if="history.tycInvestor?.length">
            <a-tag class="tag" v-for="(inv, index) in history.tycInvestor.split('，')" :key="index">{{ inv }}</a-tag>
          </a-space>
          <template v-else>-</template>
        </div>
      </div>

      <EmptyContent :message="i18n_t('plat_c.allTabs.com.zanwushuju')" v-else />
    </div>
  </Card>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
const { t: i18n_t } = useI18n()
import { computed, PropType } from 'vue'
import EmptyContent from '@/components/emptyContent.vue'
import Card from './card.vue'
import { Transaction, AmountOfMoney } from '../types'
// @ts-ignore
import { formatCurrency } from '@/utils/currency.js'

const props = defineProps({
  list: Array as PropType<Transaction[]>,
  companyInfo: {
    type: Object as PropType<
      Record<string, unknown> & { financeAmount?: AmountOfMoney; marketValue?: AmountOfMoney; tycMarketValue: String; tycFinanceAmount: String; }
    >,
    default: () => ({}),
  },
  isLoading: Boolean as PropType<boolean>,
})
</script>

<style lang="scss" scoped>
.history {
  .history-header {
    display: flex;
    .summary {
      margin-right: 24px;
      .label {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666b71;
        margin-bottom: 2px;
      }
      .value {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3272FE;
        .num {
          padding: 0 2px;
          font-size: 16px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
        }
      }
    }
  }
  &-body {
    padding: 12px 0;
    .history-item {
      padding-left: 10px;
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
      }
      &:before {
        height: 6px;
        width: 6px;
        left: 0;
        top: 8px;
        border-radius: 50%;
        background-color: #3272FE;
      }
      &:after {
        width: 1px;
        left: 3px;
        transform: translateX(-50%);
        top: 18px;
        height: calc(100% - 14px);
        background-color: #e4e5ec;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &__info {
        .amount,
        .date,
        .round {
          padding: 0 9px 0 8px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #282d33;
        }
        .border-right {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            right: 0;
            height: 12px;
            width: 1px;
            top: 50%;
            transform: translateY(-50%);
            background: #e4e5ea;
          }
        }
      }
      &__invest {
        padding-top: 4px;
        padding-bottom: 12px;
        padding-left: 8px;
        .tag {
          background: #f2f4f6;
          padding: 0 6px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333b44;
        }
      }
    }

    .empty-desc {
      color: #e4e5ec;
      margin-bottom: 6px;
      display: inline-block;
    }
  }
}
</style>
