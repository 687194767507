<template>
  <div class="yw-graph">
    <p class="graph-title" v-if="graphTitle">{{ graphTitle }}</p>
    <div class="full-screen-icon" @click="toggleFullScreen"><i :class="['iconfont', isFullScreen? 'icon-icon_chart_a' : 'icon-icon_chart_b']" /></div>
    <div ref="myPage" style="height: 100%">
      <RelationGraph ref="graphRef" :options="graphOptions" @node-click="onNodeClick" @line-click="onLineClick">
        <template #node="{node}">
          <a-tooltip :show-arrow="false" :popup-offset="6" content-class="graph-tooltip-content">
            <template #content>
              <div class="graph-tool-content">
                <p class="title">
                  {{ rootId == node.id ? `总持股` : `${node.text}持股` }} {{ tooltipInfo[node?.data?.originId]?.windowTotalRatio * 100 ? (tooltipInfo[node?.data?.originId]?.windowTotalRatio * 100)?.toFixed(floatNum) : (0).toFixed(floatNum) }}%
                </p>
                <!-- 直投持股 + 间接持股 -->
                <template v-if="tooltipInfo[node?.data?.originId]?.directHoldRatio?.length && tooltipInfo[node?.data?.originId]?.inDirectHoldRatio?.length">
                  <p class="label">
                    <span class="zhijie" v-if="tooltipInfo[node?.data?.originId]?.directHoldRatio?.length">直投持股</span>
                    <span v-if="tooltipInfo[node?.data?.originId]?.inDirectHoldRatio?.length" class="jianjie"><span v-if="tooltipInfo[node?.data?.originId]?.directHoldRatio?.length"> + </span>间接持股</span>
                  </p>
                  <p class="value">
                    <template v-if="tooltipInfo[node?.data?.originId]?.directHoldRatio?.length">
                      <span class="zhijie" v-for="(num, idx) in  tooltipInfo[node?.data?.originId]?.directHoldRatio" :key="idx">
                        <span v-if="rootId == node.id && idx > 0"> + </span>
                        <span v-else-if="idx > 0"> + </span>
                        <span>{{ (num * 100).toFixed(floatNum) }}%</span>
                      </span>
                    </template>
                    <template v-if="tooltipInfo[node?.data?.originId]?.inDirectHoldRatio?.length">
                      <span class="jianjie" v-for="(num, idx) in  tooltipInfo[node?.data?.originId]?.inDirectHoldRatio" :key="idx"><span v-if="idx > 0 || tooltipInfo[node?.data?.originId]?.directHoldRatio?.length"> + </span>{{ (num * 100).toFixed(floatNum) }}%</span>
                    </template>
                  </p>
                </template>
                <!-- 只有直投持股 -->
                <template v-else-if="tooltipInfo[node?.data?.originId]?.directHoldRatio?.length > 1 && !tooltipInfo[node?.data?.originId]?.inDirectHoldRatio?.length">
                  <p class="label">
                    <span class="zhijie">直投持股</span>
                  </p>
                  <p class="value">
                    <span class="zhijie" v-for="(num, idx) in  tooltipInfo[node?.data?.originId]?.directHoldRatio" :key="idx">
                      <span v-if="rootId == node.id && idx > 0"> + </span>
                      <span v-else-if="idx > 0"> + </span>
                      <span>{{ (num * 100).toFixed(floatNum) }}%</span>
                    </span>
                  </p>
                </template>
                <!-- 只有间接持股 -->
                <template v-else-if="!tooltipInfo[node?.data?.originId]?.directHoldRatio?.length && tooltipInfo[node?.data?.originId]?.inDirectHoldRatio?.length > 1">
                  <p class="label">
                    <span class="jianjie">间接持股</span>
                  </p>
                  <p class="value">
                    <span class="jianjie" v-for="(num, idx) in  tooltipInfo[node?.data?.originId]?.inDirectHoldRatio" :key="idx"><span v-if="idx > 0 || tooltipInfo[node?.data?.originId]?.directHoldRatio?.length"> + </span>{{ (num * 100).toFixed(floatNum) }}%</span>
                  </p>
                </template>
              </div>
            </template>
            <!-- <div @mouseover="showNodeTips(node, $event)" @mouseout="hideNodeTips(node, $event)" :data-text="node.data.percent" class="node-item"> -->
            <div v-if="rootId == node.id" class="node-item is-root">
              <div class="project-name">{{ node.text }}</div>
              <p class="ctcg">总持股 {{ (tooltipInfo[node?.data?.originId]?.windowTotalRatio * 100)?.toFixed(floatNum) }}%</p>
            </div>
            <div
              v-else
              :data-text="`${node.data.lineHoldRatio}%`"
              :class="['node-item', node.data.cssAttr, {out: node.data.fundType == 2, zero: node.data.lineHoldRatio == 0}]"
              @mouseover="showNodeTips(node, $event)"
              @mouseout="hideNodeTips(node, $event)"
            >
              <div class="node-name">{{ node.text }}</div>
              <div class="node-tag">
                <span :class="['tag', {jjcg: node.data.fundType != 1}]">{{ node.data.fundType == 1 ? '自管基金' : '外部基金' }}</span>
                <span class="tag jjcg" v-if="node.data.parentId != 0">间接持股 {{ node.data.holdRatio }}%</span>
              </div>
            </div>
          </a-tooltip>
        </template>
      </RelationGraph>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import RelationGraph from 'relation-graph-vue3';
import { RelationGraphComponent } from 'relation-graph-vue3';

const props = defineProps({
  isFullScreen: Boolean,
  graphTitle: String,
  rootId: String,
  graphNodes: Array,
  graphLines: Array,
  tooltipInfo: Object,
  floatNum: Number,
  disableZoom: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['toggleFullScreen'])

const graphInstance: any = ref('')
const linesData: any = ref([])
const myPage = ref();
const graphRef: any = ref<RelationGraphComponent>();
const isShowNodeTipsPanel = ref(false);
const nodeMenuPanelPosition = ref({ x: 0, y: 0 });
const currentNode = ref({});
const graphOptions = {
  defaultFocusRootNode: false,
  disableDragNode: true, // 是否禁用图谱中节点的拖动
  allowSwitchLineShape: false,
  disableNodeClickEffect: true, // 是否禁用节点默认的点击效果（选中、闪烁）
  disableLineClickEffect: true, // 是否禁用线条默认的点击效果（选中、闪烁）
  allowShowRefreshButton: false, // 刷新按钮
  allowShowDownloadButton: false, // 显示【下载图片】按钮
  defaultNodeShape: 1, // 1 矩形 2 椭圆
  defaultLineShape: 4,
  defaultLineMarker: {
    "markerWidth": 20,
    "markerHeight": 20,
    "refX": 3,
    "refY": 3,
    "data": "M 0 0, V 6, L 4 3, Z"
  },
  defaultNodeWidth: 220,
  defaultNodeHeight: 84,
  defaultNodeColor: '#fff', // 节点颜色
  defaultNodeBorderWidth: 0, // 节点 border宽度
  // defaultNodeBorderColor: '#BDD1FF', // 节点 border 颜色
  defaultLineColor: '#BFDBFF', // 连线颜色
  defaultJunctionPoint: 'tb',
  defaultExpandHolderPosition: 'hide', // 默认的节点展开/关闭按钮位置（left/top/right/bottom/hide）
  disableZoom: props.disableZoom, // 是否禁用图谱的缩放功能
  layout:  {
    layoutName:  'tree',
    from:  'top', 
    min_per_width:  300,
    min_per_height:  120,
  }
};

const  __graph_json_data: any = ref({
  // "rootId": "a",
  "rootId": props.rootId,
  "nodes": [
    // {
    //   "id": "a",
    //   "text": "a",
    //   "data": {
    //     "pic": "https://dss2.baidu.com/6ONYsjip0QIZ8tyhnq/it/u=2308340537,462224207&fm=58&app=83&f=JPEG?w=250&h=250&s=EC708F46DA96B89CB69D5DDA0300D014",
    //     "name": "侯亮平",
    //     "myicon": "el-icon-star-on"
    //   }
    // },
    // {
    //   "id": "b",
    //   "text": "b",
    //   "data": {
    //     "pic": "https://dss0.baidu.com/6ONWsjip0QIZ8tyhnq/it/u=2677153550,2207805387&fm=58&app=83&f=JPEG?w=250&h=250&s=249039DDC2D153D411A851360300C062",
    //     "name": "李达康",
    //     "myicon": "el-icon-setting"
    //   }
    // },
    // {
    //   "id": "c",
    //   "text": "c",
    //   "data": {
    //     "pic": "https://dss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=1725297532,1915921796&fm=58&app=83&f=JPEG?w=250&h=250&s=FE8EA444A60759554DAC1DBB03000092",
    //     "name": "祁同伟",
    //     "myicon": "el-icon-setting"
    //   }
    // },
    // {
    //   "id": "d",
    //   "text": "d",
    //   "data": {
    //     "pic": "https://dss2.baidu.com/6ONYsjip0QIZ8tyhnq/it/u=2025797948,1615296290&fm=58&app=83&f=JPEG?w=250&h=250&s=B5B04C331F32739C4604F9F503007021",
    //     "name": "陈岩石",
    //     "myicon": "el-icon-star-on"
    //   }
    // },
    // {
    //   "id": "e",
    //   "text": "e",
    //   "data": {
    //     "pic": "https://dss1.baidu.com/6ONXsjip0QIZ8tyhnq/it/u=344720653,260255884&fm=58&app=83&f=JPEG?w=250&h=250&s=57B8AB676AE862941D94ED170300E060",
    //     "name": "陆亦可",
    //     "myicon": "el-icon-setting"
    //   }
    // },
    // {
    //   "id": "f",
    //   "text": "f",
    //   "data": {
    //     "pic": "https://dss0.baidu.com/6ONWsjip0QIZ8tyhnq/it/u=3098576865,849900134&fm=58&app=83&f=JPEG?w=250&h=250&s=EDE01A63A65917DC104509920300C0C1",
    //     "name": "高育良",
    //     "myicon": "el-icon-setting"
    //   }
    // },
    // {
    //   "id": "g",
    //   "text": "g",
    //   "data": {
    //     "pic": "https://dss0.baidu.com/6ONWsjip0QIZ8tyhnq/it/u=3098576865,849900134&fm=58&app=83&f=JPEG?w=250&h=250&s=EDE01A63A65917DC104509920300C0C1",
    //     "name": "高育良",
    //     "myicon": "el-icon-setting"
    //   }
    // },
    // {
    //   "id": "h",
    //   "text": "h",
    //   "data": {
    //     "pic": "https://dss0.baidu.com/6ONWsjip0QIZ8tyhnq/it/u=3098576865,849900134&fm=58&app=83&f=JPEG?w=250&h=250&s=EDE01A63A65917DC104509920300C0C1",
    //     "name": "高育良",
    //     "myicon": "el-icon-setting"
    //   }
    // },
  ],
  lines: [],
  // "lines": [
    
  //   {
  //     "from": "f",
  //     "to": "a",
  //     lineShape: 3,
  //   },
  //   {
  //     "from": "e",
  //     "to": "f",
  //     lineShape: 3,
  //   },
  //   {
  //     "from": "b",
  //     "to": "a",
  //     lineShape: 3,
  //   },
    
  //   {
  //     "from": "d",
  //     "to": "b",
  //     lineShape: 3,
  //   },
  //   {
  //     "from": "h",
  //     "to": "a",
  //     text: 'aasf',
  //     lineShape: 3,
  //   },
  //   {
  //     "from": "g",
  //     "to": "a",
  //     lineShape: 3,
  //   },
  //   {
  //     "from": "c",
  //     "to": "a",
  //     lineShape: 3,
  //   },
  // ]
})
const showGraph = async() => {
  __graph_json_data.value.lines = props.graphLines
  __graph_json_data.value.nodes = props.graphNodes
  graphInstance.value = graphRef.value.getInstance();
  await graphInstance.value.setJsonData(__graph_json_data.value);
  await graphInstance.value.moveToCenter();
  await graphInstance.value.zoomToFit();
  props.isFullScreen && await graphInstance.value.refresh();
};

const onNodeClick = (nodeObject, $event) => {
    console.log('onNodeClick:', nodeObject);
};

const onLineClick = (lineObject, linkObject, $event) => {
    console.log('onLineClick:', lineObject);
};

const showNodeTips = (nodeObject, $event) => {
  const curId = nodeObject.data.originId
  const sameArray = __graph_json_data.value.nodes.filter((item: any) => item.originId == curId)
  sameArray?.length > 1 && __graph_json_data.value.nodes?.forEach((item: any) => {
    item.cssAttr = 'opacity'
    if (item.originId == curId) {
      item.cssAttr = 'nomal'
    }
  });
  // currentNode.value = nodeObject;
  // const _base_position = myPage.value.getBoundingClientRect();
  // console.log('nodeObject:', nodeObject);
  // console.log('showNodeMenus:', $event.clientX, $event.clientY, _base_position);
  // isShowNodeTipsPanel.value = true;
  // nodeMenuPanelPosition.value.x = $event.clientX - _base_position.x + 10;
  // nodeMenuPanelPosition.value.y = $event.clientY - _base_position.y + 10;
};

const hideNodeTips = (nodeObject, $event) => {
  __graph_json_data.value.nodes?.forEach((item: any) => {
    delete item.cssAttr
  });
    // isShowNodeTipsPanel.value = false;
};

const toggleFullScreen = () => {
  emit('toggleFullScreen')
}

onMounted(() => {
  showGraph();
});
</script>

<style lang="scss">
.graph-tool-content {
  width: 100%;
  &.full {
    width: auto;
    min-width: 200px;
    border-radius: 6px;
    padding: 12px 16px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #DEE0E3;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
  }
  .title {
    width: 100%;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #1F2329;
  }
  .label, .value {
    margin-top: 6px;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    color: #1F2329;
    .zhijie {
      color: #3272FE !important;
    }
    .jianjie {
      color: #ED8A00 !important;
    }
  }
  .value {
    margin-top: 2px;
  }
}
.graph-tooltip-content {
  min-width: 200px;
  border-radius: 6px;
  padding: 12px 16px;
  background-color: #FFFFFF !important;
  box-sizing: border-box;
  border: 1px solid #DEE0E3;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
}
.yw-graph {
  width: 100%;
  height: 100%;
  position: relative;
  .rel-node {
    border-radius: 12px;
    // &:has(> div.is-root) {
    //   height: 74px !important;
    // }
  }
  .relation-graph .rel-map {
    background: url('@/views/components/graph/bg.svg');
  }
  .graph-title {
    position: absolute;
    top: 16px;
    left: 16px;
    font-family: 苹方-简;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: #1F2329;
    z-index: 1;
  }
  .full-screen-icon {
    position: absolute;
    top: 16px;
    right: 15px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    padding: 3px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #DEE0E3;
    cursor: pointer;
    .iconfont {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #646A73;
      border-radius: 4px;
      transition: .2s;
      &:hover {
        background: #dcdcdc80;
      }
    }
  }
  .rel-toolbar {
    width: 44px !important;
    background: #fff !important;
    border: 1px solid #DEE0E3 !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    &.rel-toolbar-h-right {
      top: 72px;
    }
    .c-mb-button {
      border-radius: 4px;
      .rg-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        font-size: 18px;
        color: #646A73;
      }
    }
    div[title='全屏/退出全屏'] {
      display: none;
    }
  }
  .node-item {
    min-width: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #BDD1FF;
    background: #F5F8FF;
    position: relative;
    cursor: pointer;
    transition: .2s;
    &::after {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: -32px;
      height: 20px;
      content: attr(data-text);
      border-radius: 6px;
      padding: 2px 6px;
      box-sizing: border-box;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #024B41;
      background: #fff;
      background: #DAF7F1;
    }
    &.is-root {
      // width: 250px;
      // margin-left: -22px;
      border: 2px solid #fff;
      background: linear-gradient(106deg, #5C85FF 2%, #4059FE 95%);
      box-shadow: -7.6px -7.6px 19px 0px rgba(255, 255, 255, 0.4),7.6px 7.6px 19px 0px rgba(55, 99, 170, 0.1);
      &::after {
        display: none;
      }
    }
    &.out {
      background: #FFFCF2;
      border: 1px solid #EADCAE;
      .node-tag {
        .tag {
          background: #FAEDC2;
        }
      }
    }
    &.zero {
      background: #F7F8FA;
      border: 1px solid #DBE0E3;
    }
    &.opacity {
      opacity: 0.4;
    }
    &.nomal {
      box-shadow: 0px 6px 18px 4px rgba(31, 35, 41, 0.04),0px 6px 10px 0px rgba(31, 35, 41, 0.02),0px 2px 4px -4px rgba(31, 35, 41, 0.02);
    }
    .project-name {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #FFFFFF;
    }
    .ctcg {
      margin-top: 6px;
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      color: #FFFFFF;
    }
    .node-name {
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: PingFang SC;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
      color: #1F2329;
    }
    .node-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      .tag {
        flex-shrink: 0;
        border-radius: 10px;
        padding: 1px 8px;
        background: #D7E2FF;
        margin-right: 4px;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #0D296E;
        &:last-child {
          margin-right: 0;
        }
        &.jjcg {
          color: #352900;
          background: #FAEDC2;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>

</style>
