import { computed, ref } from 'vue'

const useScroll = () => {
  const scrollTop = ref(0)
  const scrollHeight = ref(0)
  const clientHeight = ref(0)

  const isScrollTop = computed(() => {
    return scrollTop.value <= 5
  })
  const isScrollBottom = computed(() => {
    return clientHeight.value + scrollTop.value >= scrollHeight.value - 5
  })

  const onScroll = (e: UIEvent) => {
    const target = e.target as HTMLElement

    scrollTop.value = target.scrollTop
    scrollHeight.value = target.scrollHeight
  }

  return {
    isScrollBottom,
    isScrollTop,
    onScroll,
  }
}

export default useScroll
