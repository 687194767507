<template>
  <div class="identity-tag-lists-wrapper">
    <div v-for="list in identityPortList" :key="list.id" :class="['list-item ', needToDetail? 'toDetail':'']" @click="handleToDetails(list)">
      <div v-if="list.type == 'fund'" style="background-color: #EFE6FE; color: #3D157E" class="item-wrapper">
        <i v-if="needIcon" class="iconfont icon-a-bgsys_06"/>
        {{ list.name }}
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
      <div v-else-if="list.type == 'lpInvestor'" style="background-color: #EFE6FE; color:#3D157E" class="item-wrapper">
        <i v-if="needIcon" class="iconfont icon-icon_mb1"/>{{ list.name }}
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
      <div v-else-if="list.type == 'beforeProject'" style="background-color: #FAEDC2; color: #5A4500" class="item-wrapper">
        <i v-if="needIcon" class="iconfont icon-xiangmu"/>
        <span>投前项目</span>
        <span v-if="!['', null, undefined].includes(list.name)" class="dynamic-text">-{{list.name }}</span>
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
      <div v-else-if="list.type == 'investedCompany'" style="background-color: #FAEDC2; color: #5A4500" class="item-wrapper">
        <i v-if="needIcon" class="iconfont icon-xiangmu"/>
        <span>已投项目</span>
        <span v-if="!['', null, undefined].includes(list.name)" class="dynamic-text">-{{list.name }}</span>
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
      <div v-else-if="list.type == 'afterFof'" style="background-color: #FAEDC2; color: #5A4500" class="item-wrapper">
        <i v-if="needIcon" class="iconfont icon-xiangmu"/>
        <autoTooltip className="dynamic-text left" :content="`通过${ list.name }间接持有`" />

        <!-- <span class="dynamic-text left"></span> -->
        <!-- <span class="dynamic-text projectName"></span>
        <span class="fixed-text right"></span> -->
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
      <div v-else-if="list.type == 'beforeFof'" style="background-color: #FAEDC2; color: #5A4500" class="item-wrapper">
        <i v-if="needIcon" class="iconfont icon-xiangmu"/>
        <autoTooltip className="dynamic-text " :content="`${list.name}的投资标的`" />
        <!-- <span class="text" ></span> -->
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
      <div v-else-if="list.type == 'object'" style="background-color: #D7E2FF; color: #0D296E" class="item-wrapper object-wrapper">
        <i v-if="needIcon" class="iconfont icon-icon_network"/>
        <autoTooltip className="dynamic-text text" :content="`${list.name}的${list.objectName}`" />
        <!-- <span class="fixed-text right text">的</span>
        <span class="fixed-text objectName text"></span> -->
        <i v-if="needToDetail" class="iconfont icon-arrow-left toLink"/>
      </div>
    </div>
  </div>
</template>
<script>
import { getDetailLink } from '@/utils/appTool.js'
import autoTooltip from '@/components/AutoTooltip.vue'

export default {
  components: {
    autoTooltip
  },
  props: {
    identityPortList: {
      type: Array,
      required: true,
      default: []
    },
    needToDetail: {
      type: Boolean,
      required: false,
      default: false
    },
    needIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataId: {
      type: String,
      required: false,
      default: ''
    },
    bCode: {
      type: String,
      required: false,
      default: ''
    }
  },
  /*
  * type的枚举值
  * fund: 基金，lpInvestor：投资者；beforeProject：投前； investedCompany： 投后
  * afterFof 通过 xx子基金 间接持有
  * beforeFof xx子基金 的投资标的
  * object 【xx项目 的 竞品】   【其中xx项目 对应返回的“name” 、竞品 对应返回的 “objectName”】
  */
  methods: {
    getAppName(bCode) {
      let appName = ''
      switch(bCode) {
        case 'fund':
          appName = '基金管理'
          break;
        case 'lpInvestor':
          appName = '投资者管理'
          break;
        case 'beforeProject':
        case 'beforeFof':
          appName = '项目管理'
          break;
        case 'investedCompany':
        case 'afterFof':
          appName = '已投公司'
          break;
       default: 
        appName = '项目管理'
        break; 
      }
      return appName
    },
    handleToDetails(list) {
      if(!this.needToDetail) return;
      const type = list.type
      // 项目管理/已投公司/基金管理/数据收集/投资者管理
      let appName = '项目管理'
      let bCode = list?.businessCode ?? type
      let id = list.id

      if(type == 'object' && bCode) {
        appName = this.getAppName(bCode)
      } else {
        appName = this.getAppName(type)
      }
      if(![null, '', undefined].includes(this.dataId) && this.dataId == id && bCode == this.bCode) {
        this.$emit('close')
        return
      }
      getDetailLink(appName, bCode, id).then((res) => {
        if(['beforeFof', 'afterFof'].includes(type)) {
          window.open(`${res}&tab=investObject`)
        }else {
          window.open(res)
        }
      })
    },
  }

}
</script>

<style lang="scss" scoped>
.identity-tag-lists-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
  .list-item.toDetail{
    cursor: pointer;
  }
  .list-item {
    margin-right: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    .item-wrapper {
      display: flex;
      border-radius: 4px;
      padding: 0 6px;
      align-items: center;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      overflow: hidden;
      .iconfont {
        flex-shrink: 0;
        font-size: 14px;
        margin-right: 4px;
      }
      .toLink {
        font-size: 10px;
        margin-left: 4px;
        margin-right: 0;
        transform: rotate(180deg);
      }
      :deep() {
        .dynamic-text {
          display: inline-block;
          // flex-shrink: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:not(.object-wrapper) {
          
          .projectName {
            flex-shrink: 1;
          }
          .fixed-text {
            flex-shrink: 0;
          }
        }
        .fixed-text {
          &.left {
            margin-right: 2px;
          }
          &.right {
            margin-left: 2px;
          }
        }
      }
      .object-wrapper {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        
        .right {
          flex-shrink: 0;
        }
      }
      .text {
        // flex-shrink: 1;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
    }
   
  }

}
</style>
